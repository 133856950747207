import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './Expenses.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { availableLanguages } from '../../../../../i18n';
import NewExpenseCard from './components/NewExpenseCard';
import { registerWebPlugin } from '@capacitor/core';
import { useHistory } from 'react-router-dom';

type OptionType = {
    value: string;
    label: string;
};

const ExpensesApp = () => {
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState('');

    const { t, i18n } = useTranslation(['general', 'benit-accounting']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_accounting_get_expenses, benit_accounting_get_expense_document } = useActions();

    const { expenses, getExpensesPending } = useTypedSelector((state) => state.benitAccountingReducer);

    useEffect(() => {
        benit_accounting_get_expenses();
    }, []);

    useEffect(() => {
        benit_accounting_get_expenses(searchTerm);
    }, [searchTerm]);

    const currency = (number: number) => {
        return new Intl.NumberFormat([t('general:locale_key'), 'en-US'], {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    return (
        <div>
            {getExpensesPending ? (
                <div className={styles.loader}></div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-md-4 offset-md-4">{<NewExpenseCard />}</div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-4 offset-md-4 p-1">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    placeholder={t('benit-accounting:search_invoice')}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className="row pb-4">
                        {expenses ? (
                            expenses.map((expense) => {
                                return (
                                    <div className="col-xl-3 mt-2">
                                        <div className="card card-primary card-outline h-100 ">
                                            <div className="card-body box-profile">
                                                <div key={expense.expenseId}>
                                                    <div>
                                                        {' '}
                                                        <h3 className="profile-username text-center">{expense.data.expenseId ?? ''}</h3>
                                                        <p className="text-muted text-center">{expense.data.description ?? ''}</p>
                                                        <p className="text-muted text-center">
                                                            {expense.data.expenseDate
                                                                ? new Intl.DateTimeFormat(i18n.t('general:locale_key', { lng: t('general:locale_key') }), {
                                                                      year: 'numeric',
                                                                      month: '2-digit',
                                                                      day: '2-digit',
                                                                  }).format(new Date(expense.data.expenseDate).getTime())
                                                                : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-outline-primary btn-block" onClick={(e) => history.push(`/benit/accounting/expenses/${expense.expenseId}`)}>
                                                    <b>{t('general:edit')}</b>
                                                </button>

                                                <button className="btn btn-outline-info btn-block" onClick={(e) => benit_accounting_get_expense_document(expense.data, true)}>
                                                    <b>{t('benit-accounting:download_pdf')}</b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="offset-md-3 col-md-6 mt-2 p-1 alert alert-info">
                                <h5>
                                    <i className="icon fas fa-info"></i>{' '}
                                    {searchTerm.length > 0 ? t('benit-organization-configuration:no_users_found') : t('benit-organization-configuration:no_users_yet')}
                                    {searchTerm}
                                </h5>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ExpensesApp;
