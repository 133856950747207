import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';

interface BenitCentralConfigurationState {
    usersOrg:
        | [
              {
                  permissions: [
                      {
                          service: string;
                          identifier: string;
                          name: { [x: string]: string };
                          description: { [x: string]: string };
                          id: string;
                      }
                  ];
                  user: {
                      email: string;
                      firstName: string;
                      lastName: string;
                      id: string;
                  };
                  organization: {
                      name: string;
                      industry: { [x: string]: string };
                      identifier: string;
                      id: string;
                  };
                  id: string;
                  startApplication: {
                      id: string;
                      service: number;
                      identifier: string;
                      name: { [x: string]: string };
                      description: { [x: string]: string };
                      type: string;
                      icon: string;
                      path: string;
                  } | null;
                  userLanguages?: string[] | null;
                  userDefaultLanguage?: string | null;
              }
          ]
        | null;
    usersOrgLoading: boolean;
    usersOrgDidInvalidate: boolean;
    newUserEmailExists: boolean;
    newUserAddedSuccessfully: boolean;
    newUserCreationPending: boolean;
    permissionIdentifiers: string[] | null;
    newUserApplications: [{ id: string; service: number; identifier: string; name: { [x: string]: string }; description: { [x: string]: string }; type: string; icon: string; path: string }] | null;
    editUserApplications: [{ id: string; service: number; identifier: string; name: { [x: string]: string }; description: { [x: string]: string }; type: string; icon: string; path: string }] | null;
    userUpdatedSuccessfully: boolean;
    userProfileUpdatedSuccessfully: boolean;
}

const initialState = {
    usersOrg: null,
    usersOrgLoading: false,
    usersOrgDidInvalidate: false,
    newUserEmailExists: false,
    newUserAddedSuccessfully: false,
    newUserCreationPending: false,
    permissionIdentifiers: null,
    newUserApplications: null,
    editUserApplications: null,
    userUpdatedSuccessfully: false,
    userProfileUpdatedSuccessfully: false,
};

const reducer = (state: BenitCentralConfigurationState = initialState, action: Action): BenitCentralConfigurationState => {
    switch (action.type) {
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_PENDING:
            return {
                ...state,
                newUserAddedSuccessfully: false,
                newUserCreationPending: true,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_SUCCESS:
            return {
                ...state,
                usersOrgDidInvalidate: true,
                newUserAddedSuccessfully: true,
                newUserEmailExists: false,
                newUserCreationPending: false,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_ERROR:
            return {
                ...state,
                newUserCreationPending: false,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_ORG_UPDATE_PENDING:
            return {
                ...state,
                userUpdatedSuccessfully: false,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_ORG_UPDATE_SUCCESS:
            return {
                ...state,
                usersOrgDidInvalidate: true,
                userUpdatedSuccessfully: true,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_USERS_ORGANIZATION_GET_PENDING:
            return {
                ...state,
                usersOrgLoading: true,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_USERS_ORGANIZATION_GET_SUCCESS:
            return {
                ...state,
                usersOrgLoading: false,
                usersOrg: action.payload.length > 0 ? action.payload : null,
                usersOrgDidInvalidate: false,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_CHECK_USER_EMAIL_SUCCESS:
            return {
                ...state,
                newUserEmailExists: action.payload.userExists,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_PERMISSIONS_GET_PENDING:
            return {
                ...state,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_PERMISSIONS_GET_SUCCESS:
            return {
                ...state,
                permissionIdentifiers: action.payload.permissionIdentifiers,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_APPLICATIONS_GET_SUCCESS:
            return {
                ...state,
                newUserApplications: action.payload.newOrEdit === 'new' ? action.payload.applications : state.newUserApplications,
                editUserApplications: action.payload.newOrEdit === 'edit' ? action.payload.applications : state.editUserApplications,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_DELETE_USER_SUCCESS:
            return {
                ...state,
                usersOrgDidInvalidate: true,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_PROFILE_UPDATE_PENDING:
            return {
                ...state,
                userProfileUpdatedSuccessfully: false,
            };
        case ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                userProfileUpdatedSuccessfully: true,
            };
        default:
            return state;
    }
};

export default reducer;
