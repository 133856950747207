import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import styles from './ExcelDownloadApp.module.css';

const ExcelDownloadApp = () => {
    const [selectedEigentuemer, setSelectedEigentuemer] = useState('0');

    const { t } = useTranslation(['general', 'benit-central-configuration', 'ri-property-management']);

    const { ri_property_management_excel_download, ri_property_management_excel_download_popup_shown, ri_property_management_get_current_user_eigentuemer } = useActions();

    const { excelDownloadSuccess, excelDownloadPending, currentUserEigentuemer, selectedCurrentUserEigentuemer } = useTypedSelector((state) => state.riPropertyManagementReducer);

    useEffect(() => {
        ri_property_management_get_current_user_eigentuemer();
    }, []);

    useEffect(() => {
        if (currentUserEigentuemer && currentUserEigentuemer.length === 1) {
            setSelectedEigentuemer(currentUserEigentuemer[0].eid);
        }
    }, [currentUserEigentuemer]);

    const changeEigentuemer = (eid: string) => {
        setSelectedEigentuemer(eid);
    };

    useEffect(() => {
        if (excelDownloadSuccess) {
            fireToast('success', `${t('ri-property-management:download_success')}`);
            ri_property_management_excel_download_popup_shown();
        }
    }, [excelDownloadSuccess]);

    return (
        <div>
            {currentUserEigentuemer && currentUserEigentuemer.length > 1 ? (
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="form-group">
                            <select className="form-control" onChange={(e) => changeEigentuemer(e.target.value)}>
                                {selectedEigentuemer === '0' ? <option value="0">{t('ri-property-management:select_owner')}</option> : null}
                                {currentUserEigentuemer
                                    ? currentUserEigentuemer.map((e) => {
                                          if (selectedEigentuemer === '0') {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          } else if (selectedEigentuemer === e.eid) {
                                              return (
                                                  <option value={e.eid} selected>
                                                      {e.nachname}
                                                  </option>
                                              );
                                          } else if (selectedEigentuemer !== e.eid) {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          }
                                      })
                                    : null}
                            </select>
                        </div>
                    </div>
                </div>
            ) : null}
            {parseInt(selectedEigentuemer) !== 0 ? (
                <div className="row col-md-6 offset-md-3 mt-2">
                    <button className={`btn btn-primary btn-block`} onClick={(e) => ri_property_management_excel_download(selectedEigentuemer)}>
                        {excelDownloadPending ? <div className={styles.loader}></div> : <b>{t('ri-property-management:excel_download')}</b>}
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default ExcelDownloadApp;
