export enum ActionType {
    LOGIN_USER_PENDING = 'login_user_pending',
    LOGIN_USER_SUCCESS = 'login_user_success',
    LOGIN_USER_ERROR = 'login_user_error',
    LOGOUT_USER_SUCCESS = 'logout_user_success',
    LOGOUT_USER_ERROR = 'logout_user_error',
    CURRENT_USER_SUCCESS = 'current_user_success',
    CURRENT_USER_ERROR = 'current_user_error',
    CURRENT_USER_PENDING = 'current_user_pending',
    CURRENT_USER_ORGS_SUCCESS = 'current_user_orgs_success',
    CURRENT_ORG_CHOSEN_SUCCESS = 'current_org_chosen_success',
    PASSWORD_SET_SUCCESS = 'password_set_success',
    PASSWORD_SET_ERROR = 'password_set_error',
    PASSWORD_RESET_SUCCESS = 'password_reset_success',
    PASSWORD_URL_VALIDATE_ERROR = 'password_url_validate_error',
    LOGIN_REDIRECT = 'login_redirect',
}
