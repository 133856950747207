import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './Invoice.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { availableLanguages } from '../../../../../i18n';
import NewInvoiceCard from './components/NewInvoiceCard';
import { registerWebPlugin } from '@capacitor/core';
import { useHistory } from 'react-router-dom';

type OptionType = {
    value: string;
    label: string;
};

const InvoiceApp = () => {
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState('');

    const { t, i18n } = useTranslation(['general', 'benit-accounting']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_accounting_get_invoices, benit_accounting_get_invoice_document, benit_accounting_create_invoice_by_copy, benit_accounting_get_next_invoice_id } = useActions();

    const { invoices, getInvoicesPending, nextInvoiceId } = useTypedSelector((state) => state.benitAccountingReducer);

    useEffect(() => {
        benit_accounting_get_invoices();
        benit_accounting_get_next_invoice_id();
    }, []);

    useEffect(() => {
        benit_accounting_get_invoices(searchTerm);
    }, [searchTerm]);

    const currency = (number: number) => {
        return new Intl.NumberFormat([t('general:locale_key'), 'en-US'], {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    const copyInvoice = (toBeCopiedFromInvoiceId: string) => {
        showConfirmPopup({
            title: t('benit-accounting:copy_invoice_from', {
                toBeCopiedFromInvoiceId,
            }),
            text: t('benit-accounting:copy_invoice_data_transfer', {
                toBeCopiedFromInvoiceId,
            }),
            confirmButtonColor: '#28a745',
            confirmButtonText: t('general:copy'),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: (invoiceId: string) => {
                benit_accounting_create_invoice_by_copy(toBeCopiedFromInvoiceId, invoiceId);
            },
            input: 'text',
            inputLabel: t('benit-accounting:invoice_number'),
            inputPlaceholder: t('benit-accounting:new_invoice_number'),
            inputValue: nextInvoiceId,
        });
    };

    return (
        <div>
            {getInvoicesPending ? (
                <div className={styles.loader}></div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-md-4 offset-md-4">{<NewInvoiceCard />}</div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-4 offset-md-4 p-1">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    placeholder={t('benit-accounting:search_invoice')}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className="row pb-4">
                        {invoices ? (
                            invoices.map((invoice) => {
                                return (
                                    <div className="col-xl-3 mt-2">
                                        <div className="card card-primary card-outline h-100 ">
                                            <div className="card-body box-profile">
                                                <div key={invoice.invoiceId}>
                                                    <div>
                                                        {' '}
                                                        <h3 className="profile-username text-center">{invoice.data.invoiceId ?? ''}</h3>
                                                        <p className="text-muted text-center">
                                                            {invoice.data.description ?? ''} - {invoice.data.customerName ?? ''}
                                                        </p>
                                                        {invoice.data.positions ? (
                                                            <div className="p-0">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ borderTop: '0px solid rgb(222,226,230)' }}>{t('benit-accounting:position')}</th>
                                                                            <th className="text-right" style={{ borderTop: '0px solid rgb(222,226,230)' }}>
                                                                                {t('benit-accounting:value')}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {invoice.data.positions.map((position: any) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {position.position ? <div className="text-bold">{position.position}</div> : ''} {position.description ?? ''}
                                                                                        </td>
                                                                                        <td className="text-right">{position.netSum && position.netSum !== '' ? currency(position.netSum) : ''}</td>
                                                                                    </tr>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                    <tfoot style={{ borderTop: '2px solid rgb(222,226,230)', borderBottom: '0px solid rgb(222,226,230)' }}>
                                                                        {invoice.data.totalNetSum && invoice.data.totalSum && invoice.data.totalNetSum === invoice.data.totalSum ? (
                                                                            <>
                                                                                {invoice.data.totalNetSum && invoice.data.totalNetSum !== '' ? (
                                                                                    <tr>
                                                                                        <td className="text-bold">{t('benit-accounting:total_value')}</td>
                                                                                        <td className="text-right text-bold">{currency(invoice.data.totalNetSum)}</td>
                                                                                    </tr>
                                                                                ) : null}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {invoice.data.totalNetSum && invoice.data.totalNetSum !== '' ? (
                                                                                    <tr>
                                                                                        <td>{t('benit-accounting:net_sum')}</td>
                                                                                        <td className="text-right">{currency(invoice.data.totalNetSum)}</td>
                                                                                    </tr>
                                                                                ) : null}
                                                                                {invoice.data.totalVatCost && invoice.data.totalVatCost !== '' ? (
                                                                                    <tr>
                                                                                        <td>{t('benit-accounting:vat')}</td>
                                                                                        <td className="text-right">{currency(invoice.data.totalVatCost)}</td>
                                                                                    </tr>
                                                                                ) : null}
                                                                                {invoice.data.totalSum && invoice.data.totalSum !== '' ? (
                                                                                    <tr>
                                                                                        <td className="text-bold">{t('benit-accounting:gross_sum')}</td>
                                                                                        <td className="text-right text-bold">{currency(invoice.data.totalSum)}</td>
                                                                                    </tr>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-outline-primary btn-block" onClick={(e) => history.push(`/benit/accounting/invoices/${invoice.invoiceId}`)}>
                                                    <b>{t('general:edit')}</b>
                                                </button>

                                                <button className="btn btn-outline-info btn-block" onClick={(e) => benit_accounting_get_invoice_document(invoice.data, true)}>
                                                    <b>{t('benit-accounting:download_pdf')}</b>
                                                </button>

                                                <button className="btn btn-outline-secondary btn-block" onClick={(e) => copyInvoice(invoice.invoiceId)}>
                                                    <b>{t('general:copy')}</b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="offset-md-3 col-md-6 mt-2 p-1 alert alert-info">
                                <h5>
                                    <i className="icon fas fa-info"></i>{' '}
                                    {searchTerm.length > 0 ? t('benit-organization-configuration:no_users_found') : t('benit-organization-configuration:no_users_yet')}
                                    {searchTerm}
                                </h5>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default InvoiceApp;
