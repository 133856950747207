import { ActionType } from '../action-types';
import { Action } from '../actions';

interface UserDataState {
    loading: boolean;
    errors: {}[] | null;
    email: string;
    firstName: string;
    lastName: string;
    signedIn: boolean;
    organizations: [{ id: string; name: string; industry: { [x: string]: string } }] | null;
    chosenOrganization: {
        id: string;
        name: string;
        industry: { [x: string]: string };
    } | null;
    permissionIdentifiers: string[] | null;
    applications:
        | [
              {
                  id: string;
                  service: string;
                  identifier: string;
                  name: { [x: string]: string };
                  description: { [x: string]: string };
                  type: string;
                  icon: string;
                  path: string;
              }
          ]
        | null;
    startApplication:
        | {
              id: string;
              service: string;
              identifier: string;
              name: { [x: string]: string };
              description: { [x: string]: string };
              type: string;
              icon: string;
              path: string;
          }
        | null
        | string;
    userLanguages: string[] | null;
    userDefaultLanguage: string | null;
    passwordSetSuccessfully: boolean;
    passwordResetSuccessfully: boolean;
    loginRedirect: boolean;
    logonExpirationTime: number | null;
}

const initialState = {
    loading: false,
    errors: null,
    email: '',
    firstName: '',
    lastName: '',
    signedIn: false,
    organizations: null,
    chosenOrganization: null,
    permissionIdentifiers: null,
    applications: null,
    startApplication: null,
    userLanguages: null,
    userDefaultLanguage: null,
    passwordSetSuccessfully: false,
    passwordResetSuccessfully: false,
    loginRedirect: false,
    logonExpirationTime: null,
};

const reducer = (state: UserDataState = initialState, action: Action): UserDataState => {
    switch (action.type) {
        case ActionType.LOGIN_USER_PENDING:
            return {
                ...state,
                errors: null,
                email: '',
                firstName: '',
                lastName: '',
                signedIn: false,
            };
        case ActionType.LOGIN_USER_SUCCESS:
            const { email: LoginEmail, firstName: LoginFirstName, lastName: LoginLastName } = action.payload.existingUser;
            return {
                ...state,
                loading: false,
                errors: null,
                email: LoginEmail,
                firstName: LoginFirstName,
                lastName: LoginLastName,
                signedIn: true,
                logonExpirationTime: action.payload.expirationTime,
            };
        case ActionType.LOGIN_USER_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                email: '',
                firstName: '',
                lastName: '',
                signedIn: false,
            };
        case ActionType.LOGOUT_USER_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                email: state.email,
                firstName: state.firstName,
                lastName: state.lastName,
                signedIn: state.signedIn,
            };
        case ActionType.LOGOUT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                errors: null,
                email: '',
                firstName: '',
                lastName: '',
                signedIn: false,
                chosenOrganization: null,
                permissionIdentifiers: null,
                organizations: null,
            };
        case ActionType.CURRENT_USER_SUCCESS:
            let CurrentUserEmail = '';
            let CurrentUserFirstName = '';
            let CurrentUserLastName = '';
            let CurrentUserExists = false;
            let loadingStateCurrentUser = false;
            let CurrentUserExp = null;
            if (action.payload.currentUser) {
                let { email: CurrentUserPayloadEmail, firstName: CurrentUserPayloadFirstName, lastName: CurrentUserPayloadLastName, exp: CurrentUserPayloadExp } = action.payload.currentUser;
                CurrentUserEmail = CurrentUserPayloadEmail;
                CurrentUserFirstName = CurrentUserPayloadFirstName;
                CurrentUserLastName = CurrentUserPayloadLastName;
                CurrentUserExists = true;
                CurrentUserExp = CurrentUserPayloadExp;
                loadingStateCurrentUser = true;
            }
            return {
                ...state,
                loading: loadingStateCurrentUser,
                errors: null,
                email: CurrentUserEmail,
                firstName: CurrentUserFirstName,
                lastName: CurrentUserLastName,
                signedIn: CurrentUserExists,
                logonExpirationTime: CurrentUserExp,
            };
        case ActionType.CURRENT_USER_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                email: '',
                firstName: '',
                lastName: '',
                signedIn: false,
            };
        case ActionType.CURRENT_USER_PENDING:
            return {
                ...state,
                loading: true,
                errors: null,
                email: '',
                firstName: '',
                lastName: '',
                signedIn: false,
            };
        case ActionType.CURRENT_USER_ORGS_SUCCESS:
            return {
                ...state,
                organizations: action.payload.organizations,
            };
        case ActionType.CURRENT_ORG_CHOSEN_SUCCESS:
            if (state.chosenOrganization) {
                window.location.reload();
            }
            return {
                ...state,
                loading: false,
                chosenOrganization: action.payload.organization,
                permissionIdentifiers: action.payload.permissionIdentifiers,
                applications: action.payload.applications,
                startApplication: action.payload.startApplication ? action.payload.startApplication : 'none',
                userLanguages: action.payload.userLanguages ? action.payload.userLanguages : null,
                userDefaultLanguage: action.payload.userDefaultLanguage ? action.payload.userDefaultLanguage : null,
            };
        case ActionType.PASSWORD_SET_SUCCESS:
            return {
                ...state,
                passwordSetSuccessfully: true,
            };

        case ActionType.PASSWORD_SET_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case ActionType.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetSuccessfully: true,
            };
        case ActionType.PASSWORD_URL_VALIDATE_ERROR:
            return {
                ...state,
                errors: action.payload,
            };
        case ActionType.LOGIN_REDIRECT:
            return {
                ...state,
                loginRedirect: true,
            };
        default:
            return state;
    }
};

export default reducer;
