import Avatar from 'react-avatar';
import Select from 'react-select';
import moment from 'moment-timezone';
import Switch from 'react-switch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import showConfirmPopup from '../../../../benit/central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { Link, useHistory } from 'react-router-dom';
import styles from './RIRealEstateObjectsApp.module.css';

const RIRealEstateObjectsApp = () => {
    const history = useHistory();

    const [newObject, setNewObject] = useState<{ [x: string]: any }>({
        title: '',
        objectId: '',
        realEstateType: 'realestates.houseBuy',
        realEstateType_text: 'Hausverkauf',
        copyObjectFrom: 'new',
    });
    const [objectIdUpdatingScoutRecreation, setObjectIdUpdatingScoutRecreation] = useState<null | string>(null);
    const [objectIdPublishingType, setObjectIdPublishingType] = useState<null | string>(null);
    const [objectIdOnTopScoutPlacement, setObjectIdOnTopScoutPlacement] = useState<null | string>(null);
    const [publishingType, setPublishingType] = useState<null | string>(null);

    const [showArchivedObjects, setShowArchivedObjects] = useState<boolean>(false);

    const { t } = useTranslation(['general', 'ri-property-management']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { ri_real_estate_get_objekte, ri_real_estate_create_objekt, ri_real_estate_change_objekt } = useActions();

    const { objekte, objektCreated, objektUpdated, objektUpdating } = useTypedSelector((state) => state.riRealEstateReducer);

    useEffect(() => {
        ri_real_estate_get_objekte();
    }, []);

    useEffect(() => {
        if (objektUpdated) {
            fireToast('success', `Objekt erfolgreich aktualisiert`);
            ri_real_estate_get_objekte();
            setObjectIdUpdatingScoutRecreation(null);
            setObjectIdPublishingType(null);
            setPublishingType(null);
            setObjectIdOnTopScoutPlacement(null);
        }
    }, [objektUpdated]);

    useEffect(() => {
        if (objektCreated) {
            history.push(`/ri/real-estate/objects/${newObject.objectId}`);
        }
    }, [objektCreated]);

    const currency = (number: number) => {
        return new Intl.NumberFormat('de-de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    const addObject = () => {
        ri_real_estate_create_objekt(newObject.objectId, {
            externalId: newObject.objectId,
            realEstateType: newObject.realEstateType,
            realEstateType_text: newObject.realEstateType_text,
            copyObjectFrom: newObject.copyObjectFrom,
            title: newObject.title,
            showAdress: false,
        });
    };

    const createNewScoutObject = (dataInput: any, publishedHomepage: any) => {
        showConfirmPopup({
            title: `Neues Scout Objekt für ${dataInput.externalId} wirklich erstellen? (Das alte Scout Objekt wird dabei gelöscht)`,
            text: 'Das alte Scout Objekt wird damit irreversibel gelöscht.',
            confirmButtonText: 'Neu erstellen',
            cancelButtonText: 'Abbrechen',
            confirmButtonColor: '#28a745',
            confirmedFunction: () => {
                let data = { ...dataInput };
                data.saveScout = false;
                let dataTwo = { ...dataInput };
                dataTwo.saveScout = true;

                setObjectIdUpdatingScoutRecreation(data.externalId);

                ri_real_estate_change_objekt(data.externalId, data, publishedHomepage, dataTwo);
            },
        });
    };

    const changeOnTopPlacementScout = (dataInput: any, publishedHomepage: any, state: boolean) => {
        showConfirmPopup({
            title: `Top-Platzierung für ${dataInput.externalId} wirklich ${state ? 'hinzufügen' : 'zurücknehmen'}?`,
            text: `Das Scout Objekt wird damit ${state ? 'mit einer Top-Platzierung versehen' : 'die Top-Platzierung verlieren'}.`,
            confirmButtonText: `Top-Platzierung ${state ? 'hinzufügen' : 'zurücknehmen'}`,
            cancelButtonText: 'Abbrechen',
            confirmButtonColor: state ? '#28a745' : '#dc3545',
            confirmedFunction: () => {
                let data = { ...dataInput, onTopPlacementScout: state };
                setObjectIdOnTopScoutPlacement(data.externalId);

                ri_real_estate_change_objekt(data.externalId, data, publishedHomepage);
            },
        });
    };

    const updateObjectPublishStatus = (dataInput: any, publishedHomepage: any, label: string, type: string, state?: boolean) => {
        showConfirmPopup({
            title: `Objekt ${dataInput.externalId} für ${label} wirklich ${state ? 'veröffentlichen' : 'nicht mehr veröffentlichen'}?`,
            text: state
                ? `Das Objekt ${dataInput.externalId} wird damit auf ${label} für Kunden sichtbar sein`
                : `Das Objekt ${dataInput.externalId} wird damit nicht mehr auf ${label} für Kunden sichtbar sein`,
            confirmButtonText: state ? 'Veröffentlichen' : 'Veröffentlichung zurücknehmen',
            cancelButtonText: 'Abbrechen',
            confirmButtonColor: state ? '#28a745' : '#dc3545',
            confirmedFunction: () => {
                let data = { ...dataInput, [type]: state };
                setObjectIdPublishingType(data.externalId);
                setPublishingType(type);
                ri_real_estate_change_objekt(data.externalId, type === 'publishedHomepage' ? dataInput : data, publishedHomepage);
            },
        });
    };

    return (
        <>
            {!objekte ? <LoadingAnimation /> : null}
            <div className={`${!objekte ? 'd-none' : null}`}>
                <div className="row pb-4">
                    <div className="col-md-4 offset-md-4">
                        <div className="card card-success mb-1 collapsed-card">
                            <div className="btn btn-lg btn-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                Neues Objekt anlegen
                            </div>
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <input
                                            type="text"
                                            className={`form-control control-border`}
                                            placeholder="Objekt-ID"
                                            value={newObject.objectId}
                                            onChange={(e) =>
                                                setNewObject({
                                                    ...newObject,
                                                    objectId: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <input
                                            type="text"
                                            className={`form-control control-border`}
                                            placeholder="Titel (max. 100 Zeichen)"
                                            value={newObject.title}
                                            onChange={(e) =>
                                                setNewObject({
                                                    ...newObject,
                                                    title: e.target.value,
                                                })
                                            }
                                            maxLength={100}
                                        />
                                    </div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <select
                                            className="form-control"
                                            onChange={(e) => {
                                                setNewObject({
                                                    ...newObject,
                                                    realEstateType: e.target.value,
                                                    realEstateType_text: e.target.selectedOptions[0].text,
                                                });
                                            }}
                                            value={newObject.realEstateType}
                                        >
                                            <option value="realestates.houseBuy">Hausverkauf</option>
                                            <option value="realestates.houseRent">Hausvermietung</option>
                                            <option value="realestates.apartmentBuy">Wohnungsverkauf</option>
                                            <option value="realestates.apartmentRent">Wohnungsvermietung</option>
                                            <option value="realestates.livingBuySite">Grundstückverkauf</option>
                                            <option value="realestates.office">Büro / Praxis</option>
                                            <option value="realestates.investment">Anlageimmobilie</option>
                                            <option value="realestates.garageBuy">Garagenverkauf</option>
                                            <option value="realestates.garageRent">Garagenvermietung</option>
                                            <option value="realestates.store">Einzelhandel</option>
                                            <option value="realestates.tradeSite">Gewerbegrundstück</option>
                                        </select>
                                    </div>
                                    {/* <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <select
                                            className="form-control"
                                            onChange={(e) => {
                                                setNewObject({
                                                    ...newObject,
                                                    copyObjectFrom: e.target.value,
                                                });
                                            }}
                                            value={newObject.copyObjectFrom}
                                        >
                                            <option value="new">Neues Objekt ohne Vorlage</option>
                                            {objekte
                                                ? objekte.map((objekt: any) => {
                                                      return <option value={objekt.data.externalId}>Daten von Objekt {objekt.data.externalId} übernehmen </option>;
                                                  })
                                                : null}
                                        </select>
                                    </div> */}
                                    <button className="btn btn-primary btn-block" onClick={addObject}>
                                        <b>Objekt anlegen</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group mt-2">
                            <Switch onChange={(e) => setShowArchivedObjects(e)} className="align-middle" checked={showArchivedObjects} uncheckedIcon={false} />
                            <label className="align-middle mt-1 ml-2">Archivierte Objekte anzeigen</label>
                        </div>
                    </div>
                </div>
                <div className="row pb-4">
                    {objekte
                        ? objekte.map((objekt: any) => {
                              return showArchivedObjects ||
                                  !(
                                      objekt.data.realEstateStatus &&
                                      (objekt.data.realEstateStatus === 'SOLD' || objekt.data.realEstateStatus === 'RENTED' || objekt.data.realEstateStatus === 'ARCHIVED')
                                  ) ? (
                                  <div className="col-md-4 mt-2">
                                      <span className="position-relative">
                                          <div
                                              className={`card ${objekt.data.realEstateStatus && objekt.data.realEstateStatus === 'RESERVED' ? 'card-secondary' : null} ${
                                                  objekt.data.realEstateStatus &&
                                                  (objekt.data.realEstateStatus === 'SOLD' || objekt.data.realEstateStatus === 'RENTED' || objekt.data.realEstateStatus === 'ARCHIVED')
                                                      ? 'card-danger'
                                                      : null
                                              } ${!objekt.data.realEstateStatus || objekt.data.realEstateStatus === '' ? 'card-primary' : null} card-outline h-100`}
                                          >
                                              <div className="card-body pb-0">
                                                  <div key={objekt.objektId}>
                                                      <div>
                                                          {' '}
                                                          <h3 className="profile-username text-center mt-2">{objekt.data.externalId} </h3>
                                                          <h3 className="profile-username text-center text-bold mt-2">{objekt.data.title} </h3>
                                                          {objekt.data.immoscoutObjectId ? (
                                                              <>
                                                                  <h3 className="profile-username text-center">
                                                                      <a
                                                                          href={`https://www.immobilienscout24.de/scoutmanager/exposepreview/${objekt.data.immoscoutObjectId}/browser.html`}
                                                                          target="_blank"
                                                                      >
                                                                          ({objekt.data.immoscoutObjectId})
                                                                      </a>
                                                                  </h3>
                                                              </>
                                                          ) : null}
                                                          {objekt.data.immoscoutObjectId ? (
                                                              <div className="mt-3 font-weight-bold ">
                                                                  <div className="input-group d-flex justify-content-center">
                                                                      {objectIdOnTopScoutPlacement === objekt.data.externalId ? (
                                                                          <div className="mt-auto text-center bg-secondary w-100">
                                                                              <div className={styles.loader} />
                                                                          </div>
                                                                      ) : (
                                                                          <>
                                                                              <Switch
                                                                                  onChange={(e) => changeOnTopPlacementScout(objekt.data, objekt.publishedHomepage, e)}
                                                                                  checked={objekt.data && objekt.data.onTopPlacementScout ? objekt.data.onTopPlacementScout : false}
                                                                                  disabled={objekt.data.saveScout && (objekt.data.publishedScout || objekt.data.publishedOldHomepage) ? false : true}
                                                                                  uncheckedIcon={false}
                                                                              />
                                                                              <label className="align-middle mt-1 ml-2">Top-Platzierung</label>
                                                                          </>
                                                                      )}
                                                                  </div>
                                                              </div>
                                                          ) : null}
                                                          <div className="text-center mt-3">
                                                              <button
                                                                  className={`btn btn-sm btn-info`}
                                                                  onClick={(e) => createNewScoutObject(objekt.data, objekt.publishedHomepage ? objekt.publishedHomepage : false)}
                                                              >
                                                                  {objektUpdating && objectIdUpdatingScoutRecreation === objekt.data.externalId ? (
                                                                      <div className={styles.loader} />
                                                                  ) : (
                                                                      'Neues Scout-Objekt anlegen'
                                                                  )}
                                                              </button>
                                                          </div>
                                                          {objekt.data.pictures ? (
                                                              <div className="text-center mb-3 mt-3">
                                                                  {Object.entries(objekt.data.pictures).map((picture: any, index: number) =>
                                                                      index === 0 ? (
                                                                          <img
                                                                              className="mr-2"
                                                                              style={{ height: '150px' }}
                                                                              src={`/api/ri/real-estate/objekt/files/${picture[0]}`}
                                                                              alt="message user image"
                                                                          />
                                                                      ) : null
                                                                  )}
                                                              </div>
                                                          ) : null}
                                                          <h5 className="text-center mt-4 text-secondary text-bold">
                                                              {objekt.data.realEstateType === 'realestates.houseBuy' ? currency(objekt.data.value) : null}
                                                              {objekt.data.realEstateType === 'realestates.houseRent' ? `${currency(objekt.data.baseRent)} / Monat (kalt)` : null}
                                                              {objekt.data.realEstateType === 'realestates.apartmentBuy' ? currency(objekt.data.value) : null}
                                                              {objekt.data.realEstateType === 'realestates.apartmentRent' ? `${currency(objekt.data.baseRent)} / Monat (kalt)` : null}
                                                              {objekt.data.realEstateType === 'realestates.livingBuySite' ? currency(objekt.data.value) : null}
                                                              {objekt.data.realEstateType === 'realestates.office' ? `${currency(objekt.data.value)} / Monat (kalt)` : null}
                                                              {objekt.data.realEstateType === 'realestates.investment' ? currency(objekt.data.value) : null}
                                                              {objekt.data.realEstateType === 'realestates.garageBuy' ? currency(objekt.data.value) : null}
                                                              {objekt.data.realEstateType === 'realestates.garageRent' ? `${currency(objekt.data.value)} / Monat` : null}
                                                              {objekt.data.realEstateType === 'realestates.store' ? `${currency(objekt.data.value)} / Monat (kalt)` : null}
                                                              {objekt.data.realEstateType === 'realestates.tradeSite' ? currency(objekt.data.value) : null}
                                                          </h5>
                                                          <div className="row mt-4">
                                                              <div className="col-md-5 col-6 offset-md-2 offset-1">
                                                                  <h5 className="mt-1">
                                                                      <div className="icheck-success d-inline">
                                                                          {objektUpdating && objectIdPublishingType === objekt.data.externalId && publishingType === 'publishedHomepage' ? (
                                                                              <div className="mt-auto text-center bg-secondary" id={`checkbox_${objekt.data.externalId}_Homepage`}>
                                                                                  <div className={styles.loader} />
                                                                              </div>
                                                                          ) : (
                                                                              <>
                                                                                  <input
                                                                                      type="checkbox"
                                                                                      id={`checkbox_${objekt.data.externalId}_Homepage`}
                                                                                      checked={objekt.publishedHomepage ? objekt.publishedHomepage : false}
                                                                                      onChange={(e) =>
                                                                                          updateObjectPublishStatus(
                                                                                              objekt.data,
                                                                                              objekt.publishedHomepage ? false : true,
                                                                                              'Homepage',
                                                                                              'publishedHomepage',
                                                                                              objekt.publishedHomepage ? false : true
                                                                                          )
                                                                                      }
                                                                                  />
                                                                                  <label htmlFor={`checkbox_${objekt.data.externalId}_Homepage`}>Homepage</label>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  </h5>
                                                                  <h5 className="mt-1">
                                                                      <div className="icheck-success d-inline">
                                                                          {objektUpdating && objectIdPublishingType === objekt.data.externalId && publishingType === 'publishedScout' ? (
                                                                              <div className="mt-auto text-center bg-secondary" id={`checkbox_${objekt.data.externalId}_Scout`}>
                                                                                  <div className={styles.loader} />
                                                                              </div>
                                                                          ) : (
                                                                              <>
                                                                                  <input
                                                                                      type="checkbox"
                                                                                      id={`checkbox_${objekt.data.externalId}_Scout`}
                                                                                      checked={objekt.data.publishedScout ? objekt.data.publishedScout : false}
                                                                                      onChange={(e) =>
                                                                                          updateObjectPublishStatus(
                                                                                              objekt.data,
                                                                                              objekt.publishedHomepage ? objekt.publishedHomepage : false,
                                                                                              'Scout',
                                                                                              'publishedScout',
                                                                                              objekt.data.publishedScout ? false : true
                                                                                          )
                                                                                      }
                                                                                  />
                                                                                  <label htmlFor={`checkbox_${objekt.data.externalId}_Scout`}>Scout</label>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  </h5>
                                                                  {/* <h5 className="mt-1">
                                                                      <div className="icheck-success d-inline">
                                                                          {objektUpdating && objectIdPublishingType === objekt.data.externalId && publishingType === 'publishedOldHomepage' ? (
                                                                              <div className="mt-auto text-center bg-secondary">
                                                                                  <div className={styles.loader} />
                                                                              </div>
                                                                          ) : (
                                                                              <>
                                                                                  <input
                                                                                      type="checkbox"
                                                                                      id={`checkbox_${objekt.data.externalId}_OldHomepage`}
                                                                                      checked={objekt.data.publishedOldHomepage ? objekt.data.publishedOldHomepage : false}
                                                                                      onChange={(e) =>
                                                                                          updateObjectPublishStatus(
                                                                                              objekt.data,
                                                                                              objekt.publishedHomepage ? objekt.publishedHomepage : false,
                                                                                              'Alte Webseite',
                                                                                              'publishedOldHomepage',
                                                                                              objekt.data.publishedOldHomepage ? false : true
                                                                                          )
                                                                                      }
                                                                                  />
                                                                                  <label htmlFor={`checkbox_${objekt.data.externalId}_OldHomepage`}>Alte Homepage</label>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  </h5> */}
                                                              </div>
                                                              <div className="col-md-5 col-5">
                                                                  <h5 className="mt-1">
                                                                      <div className="icheck-success d-inline">
                                                                          {objektUpdating && objectIdPublishingType === objekt.data.externalId && publishingType === 'publishedEbay' ? (
                                                                              <div className="mt-auto text-center bg-secondary">
                                                                                  <div className={styles.loader} />
                                                                              </div>
                                                                          ) : (
                                                                              <>
                                                                                  <input
                                                                                      type="checkbox"
                                                                                      id={`checkbox_${objekt.data.externalId}_Ebay`}
                                                                                      checked={objekt.data.publishedEbay ? objekt.data.publishedEbay : false}
                                                                                      onChange={(e) =>
                                                                                          updateObjectPublishStatus(
                                                                                              objekt.data,
                                                                                              objekt.publishedHomepage ? objekt.publishedHomepage : false,
                                                                                              'eBay',
                                                                                              'publishedEbay',
                                                                                              objekt.data.publishedEbay ? false : true
                                                                                          )
                                                                                      }
                                                                                  />
                                                                                  <label htmlFor={`checkbox_${objekt.data.externalId}_Ebay`}>Kleinanzeigen</label>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  </h5>
                                                                  <h5 className="mt-1">
                                                                      <div className="icheck-success d-inline">
                                                                          {objektUpdating && objectIdPublishingType === objekt.data.externalId && publishingType === 'publishedImmowelt' ? (
                                                                              <div className="mt-auto text-center bg-secondary">
                                                                                  <div className={styles.loader} />
                                                                              </div>
                                                                          ) : (
                                                                              <>
                                                                                  <input
                                                                                      type="checkbox"
                                                                                      id={`checkbox_${objekt.data.externalId}_Immowelt`}
                                                                                      checked={objekt.data.publishedImmowelt ? objekt.data.publishedImmowelt : false}
                                                                                      onChange={(e) =>
                                                                                          updateObjectPublishStatus(
                                                                                              objekt.data,
                                                                                              objekt.publishedHomepage ? objekt.publishedHomepage : false,
                                                                                              'immowelt',
                                                                                              'publishedImmowelt',
                                                                                              objekt.data.publishedImmowelt ? false : true
                                                                                          )
                                                                                      }
                                                                                  />
                                                                                  <label htmlFor={`checkbox_${objekt.data.externalId}_Immowelt`}>immowelt</label>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  </h5>
                                                                  <h5 className="mt-1">
                                                                      <div className="icheck-success d-inline">
                                                                          {objektUpdating && objectIdPublishingType === objekt.data.externalId && publishingType === 'publishedIVD' ? (
                                                                              <div className="mt-auto text-center bg-secondary">
                                                                                  <div className={styles.loader} />
                                                                              </div>
                                                                          ) : (
                                                                              <>
                                                                                  <input
                                                                                      type="checkbox"
                                                                                      id={`checkbox_${objekt.data.externalId}_IVD`}
                                                                                      checked={objekt.data.publishedIVD ? objekt.data.publishedIVD : false}
                                                                                      onChange={(e) =>
                                                                                          updateObjectPublishStatus(
                                                                                              objekt.data,
                                                                                              objekt.publishedHomepage ? objekt.publishedHomepage : false,
                                                                                              'IVD',
                                                                                              'publishedIVD',
                                                                                              objekt.data.publishedIVD ? false : true
                                                                                          )
                                                                                      }
                                                                                  />
                                                                                  <label htmlFor={`checkbox_${objekt.data.externalId}_IVD`}>IVD</label>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  </h5>
                                                              </div>
                                                          </div>
                                                          <div className="row">
                                                              <div className="col-md-10 col-12 offset-md-1">
                                                                  <div className="info-box shadow-sm mt-4 mb-0">
                                                                      {/* <span className="info-box-icon bg-secondary">
                                                                  <i className="far fa-flag" />
                                                              </span> */}
                                                                      <div className="info-box-content">
                                                                          <div className="row">
                                                                              <div className="col-sm-4 border-right">
                                                                                  <div className="description-block">
                                                                                      <span className="description-text">Exposé</span>
                                                                                      <h5 className="description-header">
                                                                                          {objekt.data.stakeholderExposeCount ? objekt.data.stakeholderExposeCount : 0}
                                                                                      </h5>
                                                                                  </div>
                                                                              </div>
                                                                              <div className="col-sm-4 border-right">
                                                                                  <div className="description-block">
                                                                                      <span className="description-text">Termin</span>
                                                                                      <h5 className="description-header">
                                                                                          {objekt.data.stakeholderAppointmentCount ? objekt.data.stakeholderAppointmentCount : 0}
                                                                                      </h5>
                                                                                  </div>
                                                                              </div>
                                                                              <div className="col-sm-4">
                                                                                  <div className="description-block">
                                                                                      <span className="description-text">Interesse</span>
                                                                                      <h5 className="description-header">
                                                                                          {objekt.data.stakeholderInterestCount ? objekt.data.stakeholderInterestCount : 0}
                                                                                      </h5>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                              <div className="card-footer">
                                                  <button
                                                      className={`btn ${objekt.data.realEstateStatus && objekt.data.realEstateStatus === 'RESERVED' ? 'btn-secondary' : null} ${
                                                          objekt.data.realEstateStatus &&
                                                          (objekt.data.realEstateStatus === 'SOLD' || objekt.data.realEstateStatus === 'RENTED' || objekt.data.realEstateStatus === 'ARCHIVED')
                                                              ? 'btn-danger'
                                                              : null
                                                      } ${!objekt.data.realEstateStatus || objekt.data.realEstateStatus === '' ? 'btn-primary' : null} btn-block`}
                                                      onClick={(e) => history.push(`/ri/real-estate/objects/${objekt.objectId}`)}
                                                  >
                                                      <b>Zum Objekt</b>
                                                  </button>
                                              </div>

                                              {objekt.data.realEstateStatus && objekt.data.realEstateStatus === 'RESERVED' ? (
                                                  <div className="ribbon-wrapper ribbon-xl">
                                                      <div className="ribbon bg-secondary">reserviert</div>
                                                  </div>
                                              ) : null}
                                              {objekt.data.realEstateStatus &&
                                              (objekt.data.realEstateStatus === 'SOLD' || objekt.data.realEstateStatus === 'RENTED' || objekt.data.realEstateStatus === 'ARCHIVED') ? (
                                                  <div className="ribbon-wrapper ribbon-xl">
                                                      <div className="ribbon bg-danger">
                                                          {objekt.data.realEstateStatus === 'SOLD' ? 'verkauft' : objekt.data.realEstateStatus === 'RENTED' ? 'vermietet' : 'archiviert'}
                                                      </div>
                                                  </div>
                                              ) : null}
                                          </div>
                                      </span>
                                  </div>
                              ) : null;
                          })
                        : null}
                </div>
                {/* End Row */}
            </div>
        </>
    );
};

export default RIRealEstateObjectsApp;
