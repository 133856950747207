import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useActions } from '../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import fireToast from '../../../central-ui/methods/fireToast';

const ProfileApp = () => {
    const history = useHistory();
    const { t } = useTranslation(['general', 'benit-central-configuration', 'benit-organization-configuration']);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');

    const { userLanguages, firstName, lastName, applications, organizations, logonExpirationTime, startApplication, userDefaultLanguage, loginRedirect } = useTypedSelector(
        (state) => state.benitAuthReducer
    );

    const { userProfileUpdatedSuccessfully } = useTypedSelector((state) => state.benitOrganizationConfigurationReducer);

    const { benit_organization_configuration_update_user_profile } = useActions();

    useEffect(() => {
        setUserFirstName(firstName);
        setUserLastName(lastName);
    }, [firstName, lastName]);

    useEffect(() => {
        if (userProfileUpdatedSuccessfully) {
            fireToast('success', `${userFirstName} ${userLastName} ${t('general:updated')}`);
        }
    }, [userProfileUpdatedSuccessfully]);

    const saveChanges = () => {
        benit_organization_configuration_update_user_profile(userFirstName, userLastName);
        history.goBack();
    };

    const cancelChanges = () => {
        history.goBack();
    };

    return (
        <div className="col-md-4 offset-md-4">
            <div>
                <div className="mt-2 input-group mb-3">
                    <input
                        type="text"
                        className="form-control control-border"
                        placeholder={t('benit-organization-configuration:user_first_name')}
                        value={userFirstName}
                        onChange={(e) => setUserFirstName(e.target.value)}
                    />
                </div>

                <div className="mt-2 input-group mb-3">
                    <input
                        type="text"
                        className="form-control control-border"
                        placeholder={t('benit-organization-configuration:user_last_name')}
                        value={userLastName}
                        onChange={(e) => setUserLastName(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <span className="col-md-6 mt-2">
                    <button className="btn btn-secondary btn-block" onClick={cancelChanges}>
                        {t('general:cancel')}
                    </button>
                </span>
                <span className="col-md-6 mt-2">
                    <button className="btn btn-primary btn-block" onClick={saveChanges}>
                        {t('general:save')}
                    </button>
                </span>
            </div>
        </div>
    );
};

export default ProfileApp;
