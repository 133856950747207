import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';

interface BenitCentralConfigurationState {
    languageKey: string | null;
    languagesLoaded: boolean;
    organizations: [{ id: string; name: string; industry: { [x: string]: string } }] | null;
    organizationsLoading: boolean;
    organizationsDidInvalidate: boolean;
    newOrganizationAddedSuccessfully: boolean;
    updatedOrganizationSuccessfully: boolean;
    servicesDidInvalidate: boolean;
    services: any | null;
    servicesLoading: boolean;
    newUserEmailExists: boolean;
}

const initialState = {
    languageKey: null,
    organizations: null,
    organizationsLoading: false,
    organizationsDidInvalidate: false,
    newOrganizationAddedSuccessfully: false,
    updatedOrganizationSuccessfully: false,
    servicesDidInvalidate: false,
    services: null,
    servicesLoading: false,
    newUserEmailExists: false,
    languagesLoaded: false,
};

const reducer = (state: BenitCentralConfigurationState = initialState, action: Action): BenitCentralConfigurationState => {
    switch (action.type) {
        case ActionType.BENIT_CENTRAL_CONFIGURATION_LANGUAGE_CHANGE:
            return {
                ...state,
                languageKey: action.payload.languageKey,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_LANGUAGE_CHANGED:
            return {
                ...state,
                languagesLoaded: true,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_GET_PENDING:
            return {
                ...state,
                organizationsLoading: true,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_GET_SUCCESS:
            return {
                ...state,
                organizationsLoading: false,
                organizations: action.payload.length > 0 ? action.payload : null,
                organizationsDidInvalidate: false,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_CREATE_PENDING:
            return {
                ...state,
                newOrganizationAddedSuccessfully: false,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_CREATE_SUCCESS:
            return {
                ...state,
                organizationsDidInvalidate: true,
                newOrganizationAddedSuccessfully: true,
                newUserEmailExists: false,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_UPDATE_PENDING:
            return {
                ...state,
                updatedOrganizationSuccessfully: false,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_UPDATE_SUCCESS:
            return {
                ...state,
                organizationsDidInvalidate: true,
                updatedOrganizationSuccessfully: true,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_DELETE_SUCCESS:
            return {
                ...state,
                organizationsDidInvalidate: true,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_CHECK_USER_EMAIL_SUCCESS:
            return {
                ...state,
                newUserEmailExists: action.payload.userExists,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_SERVICES_GET_PENDING:
            return {
                ...state,
                servicesLoading: true,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_SERVICES_GET_SUCCESS:
            return {
                ...state,
                servicesLoading: false,
                services: action.payload.length > 0 ? action.payload : null,
                servicesDidInvalidate: false,
            };
        case ActionType.BENIT_CENTRAL_CONFIGURATION_SERVICES_DELETE_SUCCESS:
            return {
                ...state,
                servicesDidInvalidate: true,
            };
        default:
            return state;
    }
};

export default reducer;
