import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const benit_accounting_upload_expense_document = (files: any, expenseId: string | null, isNewExpense: boolean) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        files.map((file: any) => {
            // console.log(picture);
            // picture.name = `${objectId}-${picture.name}`;
            const uploadFile = new File([file], `${file.name}`, {
                type: file.type,
                lastModified: file.lastModified,
            });
            const { doRequest } = useRequest({
                url: `/api/benit/accounting/expense`,
                method: 'post',
                body: {
                    expenseId,
                },
                file: uploadFile,
                onSuccess: (data) => {
                    if (isNewExpense) {
                        dispatch({
                            type: ActionType.BENIT_ACCOUNTING_CREATE_EXPENSE_SUCCESS,
                            payload: data,
                        });
                    }
                    if (!isNewExpense) {
                        dispatch({
                            type: ActionType.BENIT_ACCOUNTING_EXPENSE_DOCUMENT_UPLOAD_SUCCESS,
                            payload: data,
                        });
                    }

                    dispatch(actionCreators.benit_central_ui_delete_all_errors());
                },
                onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
            });

            doRequest();
            return null;
        });
    };
};

export const benit_accounting_get_expenses = (searchTerm = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_GET_EXPENSES_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/expense?searchTerm=${searchTerm}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_GET_EXPENSES_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_get_expense = (expenseId: String) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_GET_EXPENSE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/expense?expenseId=${expenseId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_GET_EXPENSE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_update_expense = (expense: { [x: string]: any } | null) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_UPDATE_EXPENSE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/expense`,
            method: 'put',
            body: { expense },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_UPDATE_EXPENSE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_delete_expense = (expenseId: String) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_DELETE_EXPENSE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/expense/${expenseId}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_DELETE_EXPENSE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_get_expense_document = (expense: { [x: string]: any }, downloadPdf = false) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/accounting/expense-document/${expense.expenseId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                const buff = Buffer.from(data, 'hex');
                const file = new Blob([buff], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(file);

                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_NEW_EXPENSE_PDF_AVAILABLE,
                    payload: { fileURL },
                });

                if (downloadPdf) {
                    var element = document.createElement('a');
                    element.setAttribute('href', fileURL);
                    element.setAttribute('download', `${expense.expenseId}.pdf`);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();
                    document.body.removeChild(element);
                }
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest({ responseType: 'blob' });
    };
};

export const benit_accounting_reset_expense_creation_state = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_RESET_EXPENSE_CREATION_STATE,
        });
    };
};

export const benit_accounting_reset_expense_update_state = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_RESET_EXPENSE_UPDATE_STATE,
        });
    };
};

export const benit_accounting_reset_expense_deletion_state = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_RESET_EXPENSE_DELETION_STATE,
        });
    };
};
