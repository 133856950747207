export enum ActionType {
    RI_PROPERTY_MANAGEMENT_EIGENTUEMER_GET_SUCCESS = 'ri_property_management_eigentuemer_get_success',
    RI_PROPERTY_MANAGEMENT_USER_EIGENTUEMER_GET_SUCCESS = 'ri_property_management_user_eigentuemer_get_success',
    RI_PROPERTY_MANAGEMENT_USER_EIGENTUEMER_RESET = 'ri_property_management_user_eigentuemer_reset',
    RI_PROPERTY_MANAGEMENT_CHECK_USER_EMAIL_SUCCESS = 'ri_property_management_check_user_email_success',
    RI_PROPERTY_MANAGEMENT_ADD_USER_TO_OWNER_SUCCESS = 'ri_property_management_add_user_to_owner_success',
    RI_PROPERTY_MANAGEMENT_ADD_USER_TO_OWNER_PENDING = 'ri_property_management_add_user_to_owner_pending',
    RI_PROPERTY_MANAGEMENT_UPDATE_USER_TO_OWNER_SUCCESS = 'ri_property_management_update_user_to_owner_success',
    RI_PROPERTY_MANAGEMENT_UPDATE_USER_TO_OWNER_PENDING = 'ri_property_management_update_user_to_owner_pending',
    RI_PROPERTY_MANAGEMENT_DELETE_USER_FROM_OWNER_PENDING = 'ri_property_management_delete_user_from_owner_pending',
    RI_PROPERTY_MANAGEMENT_DELETE_USER_FROM_OWNER_SUCCESS = 'ri_property_management_delete_user_from_owner_success',
    RI_PROPERTY_MANAGEMENT_TRANSACTION_GET_PENDING = 'ri_property_management_transaction_get_pending',
    RI_PROPERTY_MANAGEMENT_TRANSACTION_GET_SUCCESS = 'ri_property_management_transaction_get_success',
    RI_PROPERTY_MANAGEMENT_CLEAR_TRANSACTION = 'ri_property_management_clear_transaction',
    RI_PROPERTY_MANAGEMENT_BELEGE_GET_PENDING = 'ri_property_management_belege_get_pending',
    RI_PROPERTY_MANAGEMENT_BELEGE_GET_SUCCESS = 'ri_property_management_belege_get_success',
    RI_PROPERTY_MANAGEMENT_CLEAR_BELEGE = 'ri_property_management_clear_belege',
    RI_PROPERTY_MANAGEMENT_BELEGE_STATUS_PENDING = 'ri_property_management_belege_status_pending',
    RI_PROPERTY_MANAGEMENT_BELEGE_STATUS_SUCCESS = 'ri_property_management_belege_status_success',
    RI_PROPERTY_MANAGEMENT_CURRENT_USER_EIGENTUEMER_GET_SUCCESS = 'ri_property_management_current_user_eigentuemer_get_success',
    RI_PROPERTY_MANAGEMENT_REFRESH_DATA_PENDING = 'ri_property_management_refresh_data_pending',
    RI_PROPERTY_MANAGEMENT_REFRESH_DATA_SUCCESS = 'ri_property_management_refresh_data_success',
    RI_PROPERTY_MANAGEMENT_EXCEL_DOWNLOAD_PENDING = 'ri_property_management_excel_download_pending',
    RI_PROPERTY_MANAGEMENT_EXCEL_DOWNLOAD_SUCCESS = 'ri_property_management_excel_download_success',
    RI_PROPERTY_MANAGEMENT_EXCEL_DOWNLOAD_POPUP_SHOWN = 'ri_property_management_excel_download_popup_shown',
    RI_PROPERTY_MANAGEMENT_JAHRESAUSWERTUNG_GET_PENDING = 'ri_property_management_jahresauswertung_get_pending',
    RI_PROPERTY_MANAGEMENT_JAHRESAUSWERTUNG_GET_SUCCESS = 'ri_property_management_jahresauswertung_get_success',
    RI_PROPERTY_MANAGEMENT_CONFIGURATION_GET_SUCCESS = 'ri_property_management_configuration_get_success',
    RI_PROPERTY_MANAGEMENT_UPDATE_CONFIGURATION_PENDING = 'ri_property_management_update_configuration_pending',
    RI_PROPERTY_MANAGEMENT_UPDATE_CONFIGURATION_SUCCESS = 'ri_property_management_update_configuration_success',
    RI_PROPERTY_MANAGEMENT_WEG_BESCHLUESSE_GET_SUCCESS = 'ri_property_management_weg_beschluesse_get_success',
    RI_PROPERTY_MANAGEMENT_WEG_NEW_WEG_BESCHLUSS_SUCCESS = 'ri_property_management_new_weg_beschluss_success',
    RI_PROPERTY_MANAGEMENT_WEG_UPDATE_WEG_BESCHLUSS_SUCCESS = 'ri_property_management_update_weg_beschluss_success',
    RI_PROPERTY_MANAGEMENT_WEG_DELETE_WEG_BESCHLUSS_SUCCESS = 'ri_property_management_delete_weg_beschluss_success',
    RI_PROPERTY_MANAGEMENT_WEG_VERSAMMLUNGEN_GET_SUCCESS = 'ri_property_management_weg_versammlungen_get_success',
    RI_PROPERTY_MANAGEMENT_WEG_NEW_WEG_VERSAMMLUNGEN_SUCCESS = 'ri_property_management_new_weg_versammlungen_success',
    RI_PROPERTY_MANAGEMENT_WEG_UPDATE_WEG_VERSAMMLUNGEN_SUCCESS = 'ri_property_management_update_weg_versammlungen_success',
    RI_PROPERTY_MANAGEMENT_WEG_DELETE_WEG_VERSAMMLUNGEN_SUCCESS = 'ri_property_management_delete_weg_versammlungen_success',
    RI_PROPERTY_MANAGEMENT_WEG_CHAT_GET_SUCCESS = 'ri_property_management_weg_chat_get_success',
    RI_PROPERTY_MANAGEMENT_WEG_CHAT_NEW_MESSAGE = 'ri_property_management_weg_chat_new_message',
    RI_PROPERTY_MANAGEMENT_WEG_CHAT_SOCKET = 'ri_property_management_weg_chat_socket',
    RI_PROPERTY_MANAGEMENT_WEG_CHAT_DELETE_MESSAGES = 'ri_property_management_weg_chat_delete_messages',
    RI_PROPERTY_MANAGEMENT_WEG_CHAT_USER_EIGENTUEMER_GET_SUCCESS = 'ri_property_management_weg_chat_user_eigentuemer_get_success',
    RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_PENDING = 'ri_property_management_get_analytics_pending',
    RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_SUCCESS = 'ri_property_management_get_analytics_success',
    RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_FAIL = 'ri_property_management_get_analytics_fail',
}
