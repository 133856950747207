import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { useDropzone } from 'react-dropzone';
import styles from './Invoice.module.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

interface IProps {
    invoiceId: string;
}

interface IDocumentLoadSuccessPayload {
    numPages: any;
}

const InvoiceDetailApp = ({ invoiceId }: IProps) => {
    //pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + `/js/pdfworker/${pdfjs.version}/pdf.worker.min.js`;
    const history = useHistory();

    const { t } = useTranslation(['general', 'benit-accounting']);

    const saveInvoiceButton = useRef<any>(null);
    const downloadInvoiceButton = useRef<any>(null);

    const [showCustomerRecords, setShowCustomerRecords] = useState(false);
    const [customerFilterValue, setCustomerFilterValue] = useState('');
    const [filteredCustomerRecords, setFilteredCustomerRecords] = useState<any>(null);

    const [numPages, setNumPages] = useState(null);
    const [formerNumPages, setFormerNumPages] = useState(null);
    const [invoiceLoaded, setInvoiceLoaded] = useState(false);
    const [formerInvoiceLoaded, setFormerInvoiceLoaded] = useState(false);
    const [formerInvoiceInternalUrl, setFormerInvoiceInternalUrl] = useState<string | null>(null);

    const [invoiceTmp, setInvoiceTmp] = useState<{ [x: string]: any } | null>({});

    const {
        benit_accounting_get_invoice,
        benit_accounting_update_invoice,
        benit_accounting_update_invoice_id,
        benit_accounting_reset_invoice_update_state,
        benit_accounting_delete_invoice,
        benit_accounting_reset_invoice_deletion_state,
        benit_accounting_get_invoice_document,
        benit_accounting_get_customer_records,
        benit_accounting_reset_invoice_update_id_state,
    } = useActions();

    const { invoice, getInvoicePending, updateInvoicePending, updateInvoiceSuccess, deleteInvoiceSuccess, deleteInvoicePending, invoiceInternalUrl, customerRecords, updateInvoiceIdInvoiceId } =
        useTypedSelector((state) => state.benitAccountingReducer);

    configure({
        ignoreTags: ['input', 'select', 'textarea'],
        ignoreEventsCondition: () => false,
    });

    const keyMap = {
        SAVE: ['command+s', 'ctrl+s'],
        DOWNLOADINVOICE: ['command+p', 'ctrl+p'],
    };

    const saveObject = useCallback((e) => {
        e.preventDefault();
        if (saveInvoiceButton && saveInvoiceButton.current) {
            saveInvoiceButton.current.click()!;
        }
    }, []);

    const downloadInvoice = useCallback((e) => {
        e.preventDefault();
        if (downloadInvoiceButton && downloadInvoiceButton.current) {
            downloadInvoiceButton.current.click()!;
        }
    }, []);

    const handlers = {
        SAVE: (e: any) => saveObject(e),
        DOWNLOADINVOICE: (e: any) => downloadInvoice(e),
    };

    useEffect(() => {
        benit_accounting_get_invoice(invoiceId);
        benit_accounting_get_customer_records();
    }, [invoiceId]);

    useEffect(() => {
        if (invoice) {
            setInvoiceTmp(invoice.data);
        }
    }, [invoice]);

    useEffect(() => {
        if (invoiceTmp) {
            benit_accounting_get_invoice_document(invoiceTmp);
        }
    }, [invoiceTmp]);

    useEffect(() => {
        if (deleteInvoiceSuccess) {
            fireToast('success', t('benit-accounting:invoice_deleted'));
            history.push(`/benit/accounting/invoices/`);
            benit_accounting_reset_invoice_deletion_state();
        }
    }, [deleteInvoiceSuccess]);

    useEffect(() => {
        if (updateInvoiceSuccess) {
            fireToast('success', t('benit-accounting:invoice_saved'));
            history.push(`/benit/accounting/invoices/`);
            benit_accounting_reset_invoice_update_state();
        }
    }, [updateInvoiceSuccess]);

    useEffect(() => {
        if (updateInvoiceIdInvoiceId) {
            fireToast(
                'success',
                t('benit-accounting:invoice_number_changed', {
                    invoiceId: updateInvoiceIdInvoiceId,
                })
            );
            history.push(`/benit/accounting/invoices/${updateInvoiceIdInvoiceId}`);
            benit_accounting_reset_invoice_update_id_state();
        }
    }, [updateInvoiceIdInvoiceId]);

    useEffect(() => {
        if (invoiceInternalUrl) {
            setInvoiceLoaded(false);
        }
    }, [invoiceInternalUrl]);

    useEffect(() => {
        if (formerInvoiceInternalUrl) {
            setFormerInvoiceLoaded(false);
        }
    }, [formerInvoiceInternalUrl]);

    useEffect(() => {
        if (customerRecords) {
            setFilteredCustomerRecords(customerRecords);
        }
    }, [customerRecords]);

    const filterCustomerRecords = (filterString: string) => {
        setCustomerFilterValue(filterString);
        if (filterString === '') {
            setFilteredCustomerRecords(customerRecords);
        } else {
            setFilteredCustomerRecords(
                customerRecords.filter((customerRecord: any) => {
                    return customerRecord._id.customerName.toLowerCase().includes(filterString.toLowerCase());
                })
            );
        }
    };
    const selectCustomerRecord = (customerRecord: any) => {
        setShowCustomerRecords(false);
        setInvoiceTmp({ ...invoiceTmp, ...customerRecord._id });
        setFilteredCustomerRecords(customerRecords);
        setCustomerFilterValue('');
    };

    const onDocumentLoadSuccess = ({ numPages }: IDocumentLoadSuccessPayload) => {
        setNumPages(numPages);

        setInvoiceLoaded(true);
        setFormerInvoiceInternalUrl(invoiceInternalUrl ?? '');
    };

    const onFormerDocumentLoadSuccess = ({ numPages }: IDocumentLoadSuccessPayload) => {
        setFormerNumPages(numPages);
        setFormerInvoiceLoaded(true);
    };

    const unitOfMeasures = ['general', 'piece', 'kilogramm', 'gramm', 'year', 'month', 'day', 'hour', 'minute'];

    // const unitOfMeasures = [
    //     { uomId: 'general', uomName: t('benit-accounting:uom_general_name'), uomSymbol: t('benit-accounting:uom_general_symbol') },
    //     { uomId: 'piece', uomName: t('benit-accounting:uom_piece_name'), uomSymbol: t('benit-accounting:uom_piece_symbol') },
    //     { uomId: 'kilogramm', uomName: t('benit-accounting:uom_kilogramm_name'), uomSymbol: t('benit-accounting:uom_kilogramm_symbol') },
    //     { uomId: 'gramm', uomName: t('benit-accounting:uom_gramm_name'), uomSymbol: t('benit-accounting:uom_gramm_symbol') },
    //     {
    //         uomId: 'year',
    //         uomName: t('benit-accounting:uom_year_name'),
    //         uomNamePlural: t('benit-accounting:uom_year_name_plural'),
    //         uomSymbol: t('benit-accounting:uom_year_symbol'),
    //         uomSymbolPlural: t('benit-accounting:uom_year_symbol_plural'),
    //     },
    //     {
    //         uomId: 'month',
    //         uomName: t('benit-accounting:uom_month_name'),
    //         uomNamePlural: t('benit-accounting:uom_month_name_plural'),
    //         uomSymbol: t('benit-accounting:uom_month_symbol'),
    //         uomSymbolPlural: t('benit-accounting:uom_month_symbol_plural'),
    //     },
    //     {
    //         uomId: 'day',
    //         uomName: t('benit-accounting:uom_day_name'),
    //         uomNamePlural: t('benit-accounting:uom_day_name_plural'),
    //         uomSymbol: t('benit-accounting:uom_day_symbol'),
    //         uomSymbolPlural: t('benit-accounting:uom_day_symbol_plural'),
    //     },
    //     {
    //         uomId: 'hour',
    //         uomName: t('benit-accounting:uom_hour_name'),
    //         uomNamePlural: t('benit-accounting:uom_hour_name_plural'),
    //         uomSymbol: t('benit-accounting:uom_hour_symbol'),
    //         uomSymbolPlural: t('benit-accounting:uom_hour_symbol_plural'),
    //     },
    //     {
    //         uomId: 'minute',
    //         uomName: t('benit-accounting:uom_minute_name'),
    //         uomNamePlural: t('benit-accounting:uom_minute_name_plural'),
    //         uomSymbol: t('benit-accounting:uom_minute_symbol'),
    //         uomSymbolPlural: t('benit-accounting:uom_minute_symbol_plural'),
    //     },
    // ];

    const inputField = (
        label: string,
        field: string,
        type: string = 'text',
        additionalClasses?: string | null,
        appendText?: string | null,
        maxLength?: number | null,
        prependText?: string | null,
        disabled?: boolean | null
    ) => {
        return (
            <div className={`form-group`}>
                <label>{label}</label>
                <div className="input-group">
                    {prependText ? (
                        <div className="input-group-prepend">
                            <span className="input-group-text">{prependText}</span>
                        </div>
                    ) : null}
                    <input
                        type={type}
                        className={`form-control ${additionalClasses ? additionalClasses : ''} ${
                            label.includes('*') && invoiceTmp && (!invoiceTmp[field] || invoiceTmp[field].length === 0) ? 'border-danger' : ''
                        }`}
                        placeholder=""
                        value={invoiceTmp && invoiceTmp[field] ? (invoiceTmp[field] === '€' ? invoiceTmp[field].replace('.', ',') : invoiceTmp[field]) : ''}
                        onChange={(e) => {
                            let value: string | number = e.target.value;
                            if (type === 'number') {
                                value = e.target.value.replace(',', '.');
                            }
                            setInvoiceTmp({
                                ...invoiceTmp,
                                [field]: type === 'number' && value !== '0' ? parseFloat(value) : value,
                            });
                        }}
                        pattern={type === 'number' ? '[0-9]+([\\.,][0-9]+)?' : ''}
                        maxLength={maxLength ? maxLength : undefined}
                        disabled={disabled ? disabled : false}
                    />
                    {appendText ? (
                        <div className="input-group-append">
                            <span className="input-group-text">{appendText}</span>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    const inputTextarea = (label: string, field: string, rows: number = 3, additionalClasses?: string) => {
        return (
            <div className={`form-group`}>
                <label>{label}</label>
                <textarea
                    className={`form-control ${additionalClasses ? additionalClasses : ''} ${
                        label.includes('*') && invoiceTmp && (!invoiceTmp[field] || invoiceTmp[field].length === 0) ? 'border-danger' : ''
                    }`}
                    placeholder=""
                    value={invoiceTmp && invoiceTmp[field] ? (invoiceTmp[field] === '€' || invoiceTmp[field] === 'kWh/(m²*a)' ? invoiceTmp[field].replace('.', ',') : invoiceTmp[field]) : ''}
                    onChange={(e) => {
                        let value: string | number = e.target.value;
                        setInvoiceTmp({
                            ...invoiceTmp,
                            [field]: value,
                        });
                    }}
                    rows={rows}
                />
            </div>
        );
    };

    const deleteInvoice = (invoiceId: string) => {
        showConfirmPopup({
            title: t('benit-accounting:delete_invoice_question', {
                invoiceId,
            }),
            text: t('general:no_revert'),
            confirmButtonText: t('general:delete'),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: () => {
                benit_accounting_delete_invoice(invoiceId);
            },
        });
    };

    const changeInvoiceId = (formerInvoiceId: string) => {
        showConfirmPopup({
            title: t('benit-accounting:change_invoice_number'),
            text: t('benit-accounting:change_invoice_number_text', {
                invoiceId: formerInvoiceId,
            }),
            confirmButtonColor: '#007bff',
            confirmButtonText: t('general:delete'),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: (invoiceId: string) => {
                benit_accounting_update_invoice_id(formerInvoiceId, invoiceId);
            },
            input: 'text',
            inputLabel: t('benit-accounting:invoice_number'),
            inputPlaceholder: t('benit-accounting:new_invoice_number'),
        });
    };

    const recalculate = (positions: any, index: number) => {
        positions[index]['netSum'] = !isNaN((positions[index]['uom'] && t(`benit-accounting:uom_${positions[index]['uom']}_symbol`) ? positions[index]['count'] : 1) * positions[index]['price'])
            ? Number(((positions[index]['uom'] && t(`benit-accounting:uom_${positions[index]['uom']}_symbol`) ? positions[index]['count'] : 1) * positions[index]['price']).toFixed(2))
            : 0;
        positions[index]['vatCost'] = !isNaN(positions[index]['netSum'] * positions[index]['vat']) ? Number((positions[index]['netSum'] * positions[index]['vat']).toFixed(2)) : 0;
        positions[index]['sum'] = !isNaN(positions[index]['netSum'] + positions[index]['vatCost']) ? Number((positions[index]['netSum'] + positions[index]['vatCost']).toFixed(2)) : 0;
        let totalNetSum = Number(
            Number(
                positions.reduce((netSum: number, position: any) => {
                    return netSum + position.netSum;
                }, 0)
            ).toFixed(2)
        );
        let totalVatCost = Number(
            Number(
                positions.reduce((vatCost: number, position: any) => {
                    return vatCost + position.vatCost;
                }, 0)
            ).toFixed(2)
        );
        let totalSum = Number(
            Number(
                positions.reduce((sum: number, position: any) => {
                    return sum + position.sum;
                }, 0)
            ).toFixed(2)
        );

        setInvoiceTmp({
            ...invoiceTmp,
            positions,
            totalNetSum,
            totalVatCost,
            totalSum,
        });
    };

    const documentViewer = (url: string | null, loadSuccessCallback: any, numPagesInput: any) => {
        return url ? (
            <SizeMe>
                {({ size }) => (
                    <Document
                        file={url}
                        onLoadSuccess={loadSuccessCallback}
                        loading={
                            <div>
                                <div className={styles.loader}></div>
                            </div>
                        }
                    >
                        {Array.from(new Array(numPagesInput), (el, index) => (
                            <>
                                <Page width={size.width ?? 0} key={`page_${index + 1}`} pageNumber={index + 1} />
                                {numPagesInput && index < numPagesInput - 1 ? <hr /> : null}
                            </>
                        ))}
                        {/* <Page width={size.width} pageNumber={pageNumber} /> */}
                        {/* <p>
                                Page {pageNumber} of {numPagesInput}
                            </p> */}
                    </Document>
                )}
            </SizeMe>
        ) : null;
    };

    const supportedPDFlanguages = [
        { language: 'en', icon: 'us' },
        { language: 'de', icon: 'de' },
        { language: 'es', icon: 'es' },
        { language: 'fr', icon: 'fr' },
        { language: 'ru', icon: 'ua' },
        { language: 'cn', icon: 'cn' },
    ];

    return (
        <div className="row">
            {getInvoicePending ? (
                <div className={styles.loader}></div>
            ) : (
                <>
                    <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                    <section className="col-lg-8 col-xl-8 connectedSortable">
                        <div className="card card-primary card-outline scroll">
                            <div className="card-header">
                                <h3 className="card-title">
                                    {t('benit-accounting:invoice')} - {invoiceTmp && invoiceTmp.invoiceId ? invoiceTmp.invoiceId : ''}
                                </h3>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="row" style={{}}>
                                        <div className="col-lg-12">{inputField(t('benit-accounting:description'), 'description', 'text')}</div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>{t('benit-accounting:invoice_number')}</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control text-right" value={invoiceTmp && invoiceTmp.invoiceId ? invoiceTmp.invoiceId : ''} disabled />
                                                <div className="input-group-append">
                                                    <button
                                                        type="button"
                                                        className={`btn btn-default`}
                                                        onClick={(e) => changeInvoiceId(invoiceTmp && invoiceTmp.invoiceId ? invoiceTmp.invoiceId : '')}
                                                    >
                                                        <i className="fa fa-pen"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">{inputField(t('benit-accounting:invoice_date'), 'invoiceDate', 'date')}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{t('benit-accounting:customer_number')}</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={invoiceTmp && invoiceTmp.customerId ? invoiceTmp.customerId : ''}
                                                        onChange={(e) => {
                                                            setInvoiceTmp({
                                                                ...invoiceTmp,
                                                                customerId: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            type="button"
                                                            className={`btn ${showCustomerRecords ? 'btn-primary' : 'btn-default'}`}
                                                            onClick={(e) => setShowCustomerRecords(!showCustomerRecords)}
                                                        >
                                                            <i className="fa fa-building"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {showCustomerRecords && customerRecords ? (
                                                <div className="border rounded mb-2">
                                                    <div className="input-group pl-2 pr-2 pt-2">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                filterCustomerRecords(e.target.value);
                                                            }}
                                                            value={customerFilterValue}
                                                            placeholder={t('benit-accounting:search_for_customer')}
                                                        />
                                                    </div>
                                                    {filteredCustomerRecords && filteredCustomerRecords.length > 0 ? (
                                                        <table className="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ borderTop: '0px solid rgb(222,226,230)' }}>{t('benit-accounting:customer')}</th>
                                                                    <th style={{ borderTop: '0px solid rgb(222,226,230)' }}>{t('benit-accounting:adress')}</th>
                                                                    {/* <th style={{ borderTop: '0px solid rgb(222,226,230)' }} className="text-right">
                                                                        {t('benit-accounting:revenue')}
                                                                    </th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredCustomerRecords.map((customerRecord: any) => {
                                                                    return (
                                                                        <>
                                                                            <tr style={{ cursor: 'pointer' }} onClick={(e) => selectCustomerRecord(customerRecord)}>
                                                                                <td>
                                                                                    {customerRecord._id.customerName ?? ''} {customerRecord._id.customerId ? `(${customerRecord._id.customerId})` : ''}
                                                                                </td>
                                                                                <td>
                                                                                    {customerRecord._id.customerStreet ? `${customerRecord._id.customerStreet}, ` : ''}
                                                                                    {customerRecord._id.customerPostCode ? `${customerRecord._id.customerCity} ` : ''}
                                                                                    {customerRecord._id.customerCity ?? ''}
                                                                                </td>
                                                                                {/* <td className="text-right">{customerRecord.totalNetSum ? currency(customerRecord.totalNetSum) : ''}</td> */}
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div className="text-center mt-2 mb-2 text-bold">{t('benit-accounting:no_customers_found')}</div>
                                                    )}
                                                </div>
                                            ) : null}
                                            {inputField(t('benit-accounting:salutation'), 'customerSalutation', 'text')}
                                            {inputField(t('benit-accounting:name'), 'customerName', 'text')}
                                            {inputField(t('benit-accounting:street'), 'customerStreet', 'text')}
                                            {inputField(t('benit-accounting:postcode'), 'customerPostcode', 'text')}
                                            {inputField(t('benit-accounting:city'), 'customerCity', 'text')}
                                        </div>
                                        <div className="col-lg-6">
                                            {inputField(t('benit-accounting:due_date'), 'invoiceDueDate', 'date')}
                                            {inputTextarea(t('benit-accounting:additions_top_text'), 'invoiceAdditionsTop', 12)}
                                        </div>
                                    </div>
                                    {invoiceTmp && invoiceTmp.positions
                                        ? invoiceTmp.positions.map((position: any, index: number) => {
                                              return (
                                                  <div className="row border border-light rounded bg-light pt-2 mb-4">
                                                      <div className="form-group col-lg-12">
                                                          <label>{t('benit-accounting:position')}</label>
                                                          <div className="input-group">
                                                              <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  value={position && position['position'] ? position['position'] : ''}
                                                                  onChange={(e) => {
                                                                      let positions: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                      positions[index]['position'] = e.target.value;
                                                                      setInvoiceTmp({
                                                                          ...invoiceTmp,
                                                                          positions,
                                                                      });
                                                                  }}
                                                              />
                                                          </div>
                                                      </div>
                                                      <div className="form-group col-lg-12">
                                                          <label>{t('benit-accounting:description')}</label>
                                                          <textarea
                                                              className="form-control"
                                                              rows={2}
                                                              placeholder=""
                                                              value={position && position['description'] ? position['description'] : ''}
                                                              onChange={(e) => {
                                                                  let positions: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                  positions[index]['description'] = e.target.value;
                                                                  setInvoiceTmp({
                                                                      ...invoiceTmp,
                                                                      positions,
                                                                  });
                                                              }}
                                                          />
                                                      </div>
                                                      <div className={'form-group col-lg-3'}>
                                                          <label>{t('benit-accounting:amount')}</label>
                                                          <div className="input-group">
                                                              <div className="input-group-prepend">
                                                                  <div className="input-group-prepend">
                                                                      <button
                                                                          type="button"
                                                                          className="btn btn-secondary dropdown-toggle"
                                                                          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                          data-toggle="dropdown"
                                                                          aria-expanded="false"
                                                                      >
                                                                          {position.uom
                                                                              ? t(`benit-accounting:uom_${position.uom}_name_plural`) ??
                                                                                t(`benit-accounting:uom_${position.uom}_name`) ??
                                                                                t('benit-accounting:uom_general_name')
                                                                              : t('benit-accounting:uom_general_name')}
                                                                      </button>
                                                                      <ul className="dropdown-menu" style={{}}>
                                                                          {unitOfMeasures.map((uomId: string) => {
                                                                              return (
                                                                                  <li className="dropdown-item">
                                                                                      <div
                                                                                          style={{ cursor: 'Pointer' }}
                                                                                          onClick={(e) => {
                                                                                              let positionsInput: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                                              positionsInput[index]['uom'] = uomId;
                                                                                              positionsInput[index]['count'] = '';
                                                                                              recalculate(positionsInput, index);
                                                                                          }}
                                                                                      >
                                                                                          {t(`benit-accounting:uom_${uomId}_name_plural`) ?? t(`benit-accounting:uom_${uomId}_name`)}
                                                                                      </div>
                                                                                  </li>
                                                                              );
                                                                          })}
                                                                          {/* {unitOfMeasures.map((uom: any) => {
                                                                              return (
                                                                                  <li className="dropdown-item">
                                                                                      <div
                                                                                          style={{ cursor: 'Pointer' }}
                                                                                          onClick={(e) => {
                                                                                              let positionsInput: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                                              positionsInput[index]['uom'] = uom;
                                                                                              positionsInput[index]['count'] = '';
                                                                                              recalculate(positionsInput, index);
                                                                                          }}
                                                                                      >
                                                                                          {uom.uomNamePlural ?? uom.uomName}
                                                                                      </div>
                                                                                  </li>
                                                                              );
                                                                          })} */}
                                                                      </ul>
                                                                  </div>
                                                              </div>
                                                              <input
                                                                  type="text"
                                                                  className="form-control text-right"
                                                                  value={position && position['count'] ? position['count'].toString().replace('.', ',') : ''}
                                                                  onChange={(e) => {
                                                                      let positionsInput: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                      let countString = e.target.value;
                                                                      positionsInput[index]['count'] =
                                                                          countString.indexOf(',') === countString.length - 1 ? countString : parseFloat(e.target.value.replace(',', '.'));
                                                                      recalculate(positionsInput, index);
                                                                  }}
                                                                  disabled={
                                                                      !(position.uom && (t(`benit-accounting:uom_${position.uom}_symbol_plural`) || t(`benit-accounting:uom_${position.uom}_symbol`)))
                                                                  }
                                                              />
                                                              {position.uom && (t(`benit-accounting:uom_${position.uom}_symbol_plural`) || t(`benit-accounting:uom_${position.uom}_symbol`)) ? (
                                                                  <div className="input-group-append">
                                                                      <span className="input-group-text">
                                                                          {position.uom && t(`benit-accounting:uom_${position.uom}_symbol_plural`) && position['count'] > 1
                                                                              ? t(`benit-accounting:uom_${position.uom}_symbol_plural`)
                                                                              : t(`benit-accounting:uom_${position.uom}_symbol`) ?? null}
                                                                      </span>
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="form-group col-lg-3">
                                                          <label>{t('benit-accounting:price')}</label>
                                                          <div className="input-group">
                                                              <input
                                                                  type="text"
                                                                  className="form-control text-right"
                                                                  value={position && position['price'] ? position['price'].toString().replace('.', ',') : ''}
                                                                  onChange={(e) => {
                                                                      let positionsInput: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                      let priceString = e.target.value;
                                                                      positionsInput[index]['price'] =
                                                                          priceString.indexOf(',') === priceString.length - 1 ? priceString : parseFloat(e.target.value.replace(',', '.'));
                                                                      recalculate(positionsInput, index);
                                                                  }}
                                                              />
                                                              <div className="input-group-append">
                                                                  <span className="input-group-text">€</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="form-group col-lg-2">
                                                          <label>{t('benit-accounting:net_sum')}</label>
                                                          <div className="input-group">
                                                              <input
                                                                  type="text"
                                                                  className="form-control text-right"
                                                                  value={position['netSum'] ? position['netSum'].toFixed(2).replace('.', ',') : ''}
                                                                  disabled
                                                              />
                                                              <div className="input-group-append">
                                                                  <span className="input-group-text">€</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="form-group col-lg-1">
                                                          <div className="row">
                                                              <div className="form-group col-lg-12">
                                                                  <label>{t('benit-accounting:vat_short')}</label>
                                                                  <select
                                                                      className={`form-control`}
                                                                      onChange={(e) => {
                                                                          let positionsInput: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                          positionsInput[index]['vat'] = e.target.value;
                                                                          recalculate(positionsInput, index);
                                                                      }}
                                                                      value={position && position['vat'] ? position['vat'] : ''}
                                                                  >
                                                                      <option value={0.19}>{'19%'}</option>
                                                                      <option value={0.07}>{'7%'}</option>
                                                                      <option value={0.0}>{'0%'}</option>
                                                                  </select>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="form-group col-lg-3">
                                                          <label>{t('benit-accounting:gross_sum')}</label>
                                                          <div className="input-group">
                                                              <input
                                                                  type="text"
                                                                  className="form-control text-right"
                                                                  value={position['sum'] ? position['sum'].toFixed(2).replace('.', ',') : ''}
                                                                  disabled
                                                              />
                                                              <div className="input-group-append">
                                                                  <span className="input-group-text">€</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="form-group col-lg-12 text-center">
                                                          <button
                                                              type="button"
                                                              className="btn btn-outline-danger"
                                                              style={{ margin: '0 auto' }}
                                                              onClick={(e) => {
                                                                  let positions: [{ [x: string]: any }] = invoiceTmp.positions;
                                                                  positions.splice(index, 1);
                                                                  setInvoiceTmp({
                                                                      ...invoiceTmp,
                                                                      positions,
                                                                  });
                                                              }}
                                                          >
                                                              <i className="far fa-trash-alt mr-1" />
                                                              {t('benit-accounting:delete_position')}
                                                          </button>
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null}

                                    <div className="row">
                                        <div className="container-fluid col-xl-12">
                                            <div className="form-group text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-success"
                                                    onClick={(e) => {
                                                        let positions: [{ [x: string]: any }] = invoiceTmp && invoiceTmp.positions && invoiceTmp.positions.length > 0 ? invoiceTmp.positions : [];
                                                        positions.push({ description: '', count: null, price: null, sum: null, vat: 0.19 });
                                                        setInvoiceTmp({
                                                            ...invoiceTmp,
                                                            positions,
                                                        });
                                                    }}
                                                >
                                                    <i className="fas fa-plus mr-1" />
                                                    {t('benit-accounting:new_position')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6">{inputTextarea(t('benit-accounting:additions_bottom_text'), 'invoiceAdditionsBottom', 8)}</div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{t('benit-accounting:total_net')}</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control  text-right"
                                                        value={invoiceTmp && invoiceTmp['totalNetSum'] ? invoiceTmp['totalNetSum'].toFixed(2).replace('.', ',') : ''}
                                                        disabled
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">€</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t('benit-accounting:total_vat')}</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control  text-right"
                                                        value={invoiceTmp && invoiceTmp['totalVatCost'] ? invoiceTmp['totalVatCost'].toFixed(2).replace('.', ',') : ''}
                                                        disabled
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">€</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t('benit-accounting:total_gross')}</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control  text-right"
                                                        value={invoiceTmp && invoiceTmp['totalSum'] ? invoiceTmp['totalSum'].toFixed(2).replace('.', ',') : ''}
                                                        disabled
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">€</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className={`card card-secondary mb-1 collapsed-card`}>
                                                <div className="btn btn-sm btn-outline-secondary btn-block align-middle" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                                    {t('benit-accounting:standard_fields')}
                                                </div>
                                                <div className="card-body box-profile">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            {inputField(t('benit-accounting:invoice_provider'), 'companyName', 'text')}
                                                            {inputField(t('benit-accounting:company_owner'), 'companyOwner', 'text')}
                                                            {inputField(t('benit-accounting:street'), 'street', 'text')}
                                                            {inputField(t('benit-accounting:postcode'), 'postcode', 'text')}
                                                            {inputField(t('benit-accounting:city'), 'city', 'text')}
                                                            {inputField(t('benit-accounting:telephone'), 'telephone', 'text')}
                                                            {inputField(t('benit-accounting:e_mail'), 'eMail', 'text')}
                                                        </div>
                                                        <div className="col-lg-6">
                                                            {inputField(t('benit-accounting:tax_number'), 'taxNumber', 'text')}
                                                            {inputField(t('benit-accounting:vat_number'), 'vatNumber', 'text')}
                                                            {inputField(t('benit-accounting:hrb_number'), 'hrbNumber', 'text')}
                                                            {inputField(t('benit-accounting:bank'), 'bank', 'text')}
                                                            {inputField(t('benit-accounting:iban'), 'IBAN', 'text')}
                                                            {inputField(t('benit-accounting:bic'), 'BIC', 'text')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="mt-2">
                                    <button
                                        id="submit_button"
                                        className="btn btn-outline-danger float-left"
                                        style={{ marginBottom: '5px', marginRight: '5px' }}
                                        onClick={(e) => (invoiceTmp && invoiceTmp.invoiceId ? deleteInvoice(invoiceTmp.invoiceId) : null)}
                                    >
                                        {deleteInvoicePending ? <div className={styles.loader} /> : t('general:delete')}
                                    </button>
                                    <button
                                        id="trigger_rechnungSpeichern"
                                        ref={saveInvoiceButton}
                                        type="button"
                                        className="btn btn-outline-primary float-right mb-2"
                                        style={{ marginRight: 5 }}
                                        onClick={(e) => benit_accounting_update_invoice(invoiceTmp)}
                                    >
                                        {updateInvoicePending ? <div className={styles.loader} /> : t('benit-accounting:save_invoice')}
                                    </button>
                                    <button onClick={(e) => history.push(`/benit/accounting/invoices/`)} className="mr-2 mb-2 btn btn-outline-secondary float-right">
                                        {t('general:cancel')}
                                    </button>
                                    <button
                                        type="button"
                                        ref={downloadInvoiceButton}
                                        className="btn btn-outline-info float-right mb-2"
                                        style={{ marginRight: 5 }}
                                        onClick={(e) => (invoiceTmp ? benit_accounting_get_invoice_document(invoiceTmp, true) : null)}
                                    >
                                        {t('benit-accounting:download_pdf')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="col-lg-4 col-xl-4 connectedSortable">
                        <div className="card card-secondary card-outline scroll">
                            <div className="card-header">
                                <h3 className="card-title">{t('benit-accounting:preview')}</h3>
                                <div className="card-tools">
                                    <div className="dropdown">
                                        <a className="nav-link m-0 p-0 pr-3" data-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }}>
                                            <i
                                                className={`flag-icon flag-icon-${
                                                    invoiceTmp && invoiceTmp.language ? (invoiceTmp.language === 'en' ? 'us' : invoiceTmp.language === 'ru' ? 'ua' : invoiceTmp.language) : 'us'
                                                }`}
                                            ></i>
                                        </a>
                                        <div
                                            className="dropdown-menu dropdown-menu-right p-0"
                                            style={{
                                                left: 'inherit',
                                                right: '0px',
                                            }}
                                        >
                                            {supportedPDFlanguages
                                                ? supportedPDFlanguages.map((ln) => {
                                                      return (
                                                          <button
                                                              onClick={() => {
                                                                  setInvoiceTmp({
                                                                      ...invoiceTmp,
                                                                      language: ln.language,
                                                                  });
                                                              }}
                                                              className={`dropdown-item ${invoiceTmp && invoiceTmp.language && invoiceTmp.language === ln.language ? 'active' : ''}`}
                                                          >
                                                              <i className={`flag-icon flag-icon-${ln.icon} mr-2`}></i> {t(`general:${ln.language}`)}
                                                          </button>
                                                      );
                                                  })
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={`${!invoiceLoaded ? 'd-none' : ''}`}>{documentViewer(invoiceInternalUrl, onDocumentLoadSuccess, numPages)}</div>
                                <div className={`${invoiceLoaded ? 'd-none' : ''}`}>{documentViewer(formerInvoiceInternalUrl, onFormerDocumentLoadSuccess, formerNumPages)}</div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

export default InvoiceDetailApp;
