import axios from 'axios';
import FileDownload from 'js-file-download';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const ri_property_management_get_belege = (
    eigentuemerEid: string,
    from: string | null = '',
    to: string | null = '',
    moid: string | null = '',
    mid: string | null = '',
    kaid: string | null = '',
    archivid: string | null = ''
) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/ri/property-management/belege/${eigentuemerEid}?from=${from}&to=${to}&moid=${moid}&mid=${mid}&kaid=${kaid}&archivid=${archivid}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_clear_belege = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_CLEAR_BELEGE,
        });
    };
};

export const ri_property_management_set_beleg_status = (archivid: string, status: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_STATUS_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/ri/property-management/belege',
            method: 'post',
            body: { archivid, status },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_STATUS_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};
