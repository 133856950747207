import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import jwtRefreshMiddleware from './jwt-refresh-middleware';

export const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(thunk, jwtRefreshMiddleware()))
);
