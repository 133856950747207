import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './UserEigentuemerApp.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import showConfirmPopup from '../../../../benit/central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import Switch from 'react-switch';

const UserEigentuemerApp = () => {
    const [newUserType, setNewUserType] = useState('eigentuemer');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserSendReleaseEmail, setNewUserSendReleaseEmail] = useState(true);
    const [newUserFirstName, setNewUserFirstName] = useState('');
    const [newUserLastName, setNewUserLastName] = useState('');

    const [searchTerm, setSearchTerm] = useState('');

    const { t } = useTranslation(['general', 'benit-central-configuration', 'ri-property-management']);
    const { mlfv } = useMultiLanguageFieldValue();

    const {
        ri_property_management_get_eigentuemer,
        ri_property_management_get_user_eigentuemer,
        ri_property_management_reset_user_eigentuemer,
        ri_property_management_check_user_email,
        ri_property_management_add_user_to_owner,
        ri_property_management_update_user_to_owner,
        ri_property_management_delete_user_from_owner,
    } = useActions();

    const { eigentuemer, userEigentuemer, selectedEigentuemer, newUserEmailExists, newUserAddedSuccessfully, usersOwnerDidInvalidate } = useTypedSelector((state) => state.riPropertyManagementReducer);

    const addUser = () => {
        ri_property_management_add_user_to_owner(newUserType, newUserEmail, newUserSendReleaseEmail, newUserFirstName, newUserLastName, selectedEigentuemer ? selectedEigentuemer.eid : '0');
    };

    const deleteUserOwner = (userId: string, userOwnerFirstName: string, userOwnerLastName: string) => {
        showConfirmPopup({
            title: t('general:are_you_sure'),
            text: t('general:no_revert'),
            confirmButtonText: t('ri-property-management:remove_user_from_owner', {
                owner: selectedEigentuemer ? selectedEigentuemer.nachname : null,
            }),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: () => {
                const eigentuemerEid = selectedEigentuemer ? selectedEigentuemer.eid : '0';
                ri_property_management_delete_user_from_owner(userId, eigentuemerEid);
                //benit_organization_configuration_delete_user(userOwnerId);
                fireToast('success', `${userOwnerFirstName} ${userOwnerLastName} ${t('general:unassigned')}`);
            },
        });
    };

    useEffect(() => {
        ri_property_management_get_eigentuemer();
    }, []);

    useEffect(() => {
        if (usersOwnerDidInvalidate) {
            ri_property_management_get_user_eigentuemer(selectedEigentuemer ? selectedEigentuemer.eid : '0', searchTerm);
        }
    }, [usersOwnerDidInvalidate]);

    useEffect(() => {
        ri_property_management_get_user_eigentuemer(selectedEigentuemer ? selectedEigentuemer.eid : '0', searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        if (newUserEmail) {
            ri_property_management_check_user_email(newUserEmail);
        }
    }, [newUserEmail]);

    useEffect(() => {
        if (newUserAddedSuccessfully && newUserEmail !== '') {
            fireToast('success', `${t('general:user')} ${t('general:added')}`);
            setNewUserEmail('');
            setNewUserFirstName('');
            setNewUserLastName('');
        }
    }, [newUserAddedSuccessfully]);

    const changeEigentuemer = (eid: string) => {
        if (eid !== '0') {
            ri_property_management_get_user_eigentuemer(eid);
        } else {
            ri_property_management_reset_user_eigentuemer();
        }
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-4 offset-md-4">
                    <div className="form-group">
                        <select className="form-control" onChange={(e) => changeEigentuemer(e.target.value)}>
                            <option value="0">{t('ri-property-management:select_owner')}</option>
                            {eigentuemer
                                ? eigentuemer.map((e) => {
                                      if (!selectedEigentuemer) {
                                          return <option value={e.eid}>{e.nachname}</option>;
                                      } else if (selectedEigentuemer.eid === e.eid) {
                                          return (
                                              <option value={e.eid} selected>
                                                  {e.nachname}
                                              </option>
                                          );
                                      } else if (selectedEigentuemer.eid !== e.eid) {
                                          return <option value={e.eid}>{e.nachname}</option>;
                                      }
                                  })
                                : null}
                        </select>
                    </div>
                </div>
            </div>
            {selectedEigentuemer ? (
                <div>
                    <div className="row">
                        <div className="col-md-4 offset-md-4">
                            <div className="card card-success mb-1 collapsed-card">
                                <div className="btn btn-lg btn-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                    {t('ri-property-management:add_user')}
                                </div>
                                <div className="card-body box-profile">
                                    <div className="text-center">
                                        <Avatar name={newUserFirstName + ' ' + newUserLastName} size="128" round=".3rem" color="green" />
                                    </div>

                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <select className="form-control" onChange={(e) => setNewUserType(e.target.value)}>
                                            <option value="eigentuemer" selected>
                                                Eigentümer
                                            </option>
                                            <option value="weg">WEG</option>
                                        </select>
                                    </div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <input
                                            type="email"
                                            className={`form-control control-border ${newUserEmailExists ? 'is-valid' : ''}`}
                                            placeholder={t('ri-property-management:user_email')}
                                            value={newUserEmail}
                                            onChange={(e) => setNewUserEmail(e.target.value)}
                                        />
                                        {newUserEmailExists ? <span className="valid-feedback">{t('ri-property-management:user_already_exists')}</span> : null}
                                    </div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <div className="input-group text-center">
                                            <Switch onChange={(e) => setNewUserSendReleaseEmail(e)} checked={newUserSendReleaseEmail} uncheckedIcon={false} />
                                            <label className="align-middle mt-1 ml-2">Beleg-Freigabe E-Mail</label>
                                        </div>
                                    </div>

                                    {newUserEmailExists ? null : (
                                        <div>
                                            <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control control-border"
                                                    placeholder={t('ri-property-management:user_first_name')}
                                                    value={newUserFirstName}
                                                    onChange={(e) => setNewUserFirstName(e.target.value)}
                                                />
                                            </div>

                                            <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control control-border"
                                                    placeholder={t('ri-property-management:user_last_name')}
                                                    value={newUserLastName}
                                                    onChange={(e) => setNewUserLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <button className="btn btn-primary btn-block" onClick={addUser}>
                                        <b>
                                            {t('ri-property-management:add_user_to_owner', {
                                                owner: selectedEigentuemer ? selectedEigentuemer.nachname : null,
                                            })}
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 offset-md-4 p-1">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    placeholder={t('ri-property-management:search_user')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row pb-4">
                        {userEigentuemer ? (
                            userEigentuemer.map((ue) => {
                                return (
                                    <div className="col-md-3 mt-2">
                                        <div className="card card-primary card-outline h-100 ">
                                            <div className="card-body box-profile">
                                                <div key={ue.id}>
                                                    <div className="text-center">
                                                        <Avatar name={ue.user.firstName + ' ' + ue.user.lastName} round=".3rem" size="128" color="grey" />
                                                    </div>
                                                    <div>
                                                        {' '}
                                                        <h3 className="profile-username text-center">
                                                            {ue.user.firstName} {ue.user.lastName}
                                                        </h3>
                                                        <p className="text-muted text-center">{ue.user.email}</p>
                                                        <p className="mt-3 mb-3 text-center">
                                                            <div className="input-group text-center">
                                                                <Switch
                                                                    onChange={(e) => ri_property_management_update_user_to_owner(ue.id, e)}
                                                                    checked={ue.sendReleaseEmail ? ue.sendReleaseEmail : false}
                                                                    uncheckedIcon={false}
                                                                />
                                                                <label className="align-middle mt-1 ml-2">Beleg-Freigabe E-Mail</label>
                                                            </div>
                                                        </p>
                                                        <div>
                                                            <button className="btn btn-danger btn-block" onClick={(e) => deleteUserOwner(ue.user.id, ue.user.firstName, ue.user.lastName)}>
                                                                <b>
                                                                    {t('ri-property-management:remove_user_from_owner', {
                                                                        owner: selectedEigentuemer ? selectedEigentuemer.nachname : null,
                                                                    })}
                                                                </b>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="offset-md-3 col-md-6 mt-2 p-1 alert alert-info">
                                <h5>
                                    <i className="icon fas fa-info"></i> {searchTerm.length > 0 ? t('ri-property-management:no_users_found') : t('ri-property-management:no_users_yet')}
                                    {searchTerm}
                                </h5>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default UserEigentuemerApp;
