import Avatar from 'react-avatar';
import styles from './SetPasswordStyle.module.css';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions } from '../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import LanguagePicker from '../../../central-ui/components/Container/MainWindow/LanguagePicker/LanguagePicker';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';

const SetPassword = () => {
    const [passwordInput, setPassword] = useState('');
    const [confirmPasswordInput, setConfirmPassword] = useState('');
    const [userId, setUserId] = useState('');
    const [passwordToken, setPasswordToken] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { benit_auth_setPassword, benit_auth_loginRedirect, benit_auth_validate_setPassword_url } = useActions();
    const { errors } = useTypedSelector((state) => state.benitAuthReducer);

    const { t } = useTranslation(['benit-auth', 'general']);
    const { mlfv } = useMultiLanguageFieldValue();

    const history = useHistory();

    const { passwordSetSuccessfully } = useTypedSelector((state) => state.benitAuthReducer);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        benit_auth_setPassword(userId, passwordToken, passwordInput);
    };

    useEffect(() => {
        const pathPieces = window.location.pathname.split('/');
        setUserId(pathPieces[4]);
        setPasswordToken(pathPieces[5]);
        benit_auth_validate_setPassword_url(pathPieces[4], pathPieces[5]);
    }, []);

    const host = window.location.host;

    return (
        <div className={styles.centerPosition}>
            <div className="login-box">
                <div className="card">
                    <div>
                        <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ borderBottom: 'none' }}>
                            <ul className="navbar-nav ml-auto">
                                <LanguagePicker />
                            </ul>
                        </nav>
                        <div className="card-body login-card-body">
                            <div>
                                <img
                                    src={`${host == 'app.benit-solutions.com' || host == 'benit.dev' ? process.env.PUBLIC_URL + '/img/benit.png' : process.env.PUBLIC_URL + '/img/RI-Original.jpg'}`}
                                    className="mb-4"
                                    alt="benit"
                                    width="40%"
                                />
                                <div className="mb-3 border-bottom"></div>
                                {errors ? (
                                    <div>
                                        {errors.map((error: any) => {
                                            if (error.description) {
                                                return (
                                                    <div className="alert alert-danger " key={error.message}>
                                                        {mlfv(error.description)}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div className="alert alert-danger " key={error.message}>
                                                        {error.message}
                                                    </div>
                                                );
                                            }
                                        })}
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    onClick={(e) => {
                                                        benit_auth_loginRedirect();
                                                    }}
                                                    className="btn btn-primary btn-block"
                                                >
                                                    {t('benit-auth:to_login')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : !passwordSetSuccessfully ? (
                                    <div>
                                        <h4 className="mb-3">{t('benit-auth:set_password')}</h4>
                                        <form onSubmit={onSubmit}>
                                            <div className="input-group mb-3">
                                                <input
                                                    type={`${showPassword ? 'text' : 'password'}`}
                                                    className="form-control"
                                                    placeholder={t('benit-auth:password')}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={passwordInput}
                                                />
                                                <div onClick={(e) => setShowPassword(!showPassword)} className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input
                                                    type={`${showConfirmPassword ? 'text' : 'password'}`}
                                                    className="form-control"
                                                    placeholder={t('benit-auth:repeat_password')}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    value={confirmPasswordInput}
                                                />
                                                <div onClick={(e) => setShowConfirmPassword(!showConfirmPassword)} className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className={`fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <button disabled={passwordInput !== confirmPasswordInput || passwordInput.length < 4} type="submit" className="btn btn-primary btn-block">
                                                        {t('benit-auth:save_password')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className="mb-3">{t('benit-auth:password_set')}</h4>
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    onClick={(e) => {
                                                        benit_auth_loginRedirect();
                                                    }}
                                                    className="btn btn-primary btn-block"
                                                >
                                                    {t('benit-auth:to_login')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPassword;
