import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const ri_property_management_get_eigentuemer = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/eigentuemer`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_EIGENTUEMER_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_get_user_eigentuemer = (eigentuemerEid: string, searchTerm: string = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        if (eigentuemerEid !== '0') {
            const { doRequest } = useRequest({
                url: `/api/ri/property-management/user-eigentuemer/${eigentuemerEid}?searchTerm=${searchTerm}`,
                method: 'get',
                body: {},
                onSuccess: (data) => {
                    dispatch({
                        type: ActionType.RI_PROPERTY_MANAGEMENT_USER_EIGENTUEMER_GET_SUCCESS,
                        payload: data,
                    });
                    dispatch(actionCreators.benit_central_ui_delete_all_errors());
                },
                onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
            });

            doRequest();
        }
    };
};

export const ri_property_management_reset_user_eigentuemer = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_USER_EIGENTUEMER_RESET,
        });
    };
};

export const ri_property_management_check_user_email = (newUserEmail = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/auth/users/check/${newUserEmail}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_CHECK_USER_EMAIL_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_add_user_to_owner = (userType: string, email: string, sendReleaseEmail: boolean, firstName: string, lastName: string, eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_ADD_USER_TO_OWNER_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/ri/property-management/user-eigentuemer',
            method: 'post',
            body: { userType, email, sendReleaseEmail, firstName, lastName, eigentuemerEid },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_ADD_USER_TO_OWNER_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_property_management_update_user_to_owner = (id: string, sendReleaseEmail: boolean) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_USER_TO_OWNER_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/ri/property-management/user-eigentuemer',
            method: 'put',
            body: { id, sendReleaseEmail },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_USER_TO_OWNER_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_property_management_delete_user_from_owner = (userId: string, eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_DELETE_USER_FROM_OWNER_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/ri/property-management/user-eigentuemer/${userId}/${eigentuemerEid}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_DELETE_USER_FROM_OWNER_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};
