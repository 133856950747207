import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';

interface RIPropertyManagementState {
    eigentuemer:
        | [
              {
                  nachname: string;
                  eid: string;
              }
          ]
        | null;
    userEigentuemer:
        | [
              {
                  user: {
                      email: string;
                      firstName: string;
                      lastName: string;
                      id: string;
                  };
                  id: string;
                  sendReleaseEmail: boolean;
              }
          ]
        | null;
    selectedEigentuemer: {
        nachname: string;
        eid: string;
    } | null;
    newUserEmailExists: boolean;
    usersOwnerDidInvalidate: boolean;
    newUserAddedSuccessfully: boolean;
    newUserCreationPending: boolean;
    transactionPending: boolean;
    transactions:
        | [
              {
                  tid: number;
                  buchungstag: Date;
                  wert?: Date;
                  umsatzart?: string;
                  beguenstigter_auftraggeber?: string;
                  verwendungszweck?: string;
                  IBAN?: string;
                  BIC?: string;
                  kundenreferenz?: string;
                  mandatsreferenz?: string;
                  glaeubiger_id?: string;
                  fremde_gebuehren?: string;
                  betrag: number;
                  abweichender_empfaenger?: string;
                  soll?: number;
                  haben?: number;
                  waehrung?: string;
                  archivid?:
                      | {
                            archivid: number;
                            rechnung: number;
                            dokument?: string;
                            erfassungsdatum?: Date | null;
                            belegart?: string;
                            beschreibung?: string;
                            eigentuemer: number | null;
                            mietobjekt?: number | null;
                            mieteinheit?: number | null;
                            mieter?: number | null;
                            dienstleister?: number | null;
                            betrag: number;
                            bk_faehig?: string;
                            referenznummer?: string;
                            belegnummer?: string;
                            kostenart: number | null;
                            mietobjekt_bezug?: string;
                            status?: string;
                            bka?: number;
                        }
                      | number
                      | null;
                  eigentuemer:
                      | {
                            eid: number;
                            anrede?: string;
                            titel?: string;
                            vorname?: string;
                            nachname?: string;
                            telefonnummer?: string;
                            email?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            verwaltervertrag_von?: Date | null;
                            verwaltervertrag_bis?: Date | null;
                            IBAN?: string;
                            BIC?: string;
                            aktueller_kontostand?: number | null;
                        }
                      | number
                      | null;
                  mietobjekt?:
                      | {
                            moid: number;
                            eid: number | null;
                            flurstueck?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            status?: string;
                            gesamtflaeche?: number;
                            anzahl_me?: number;
                        }
                      | number
                      | null;
                  mieteinheit?:
                      | {
                            meid: number;
                            moid: number | null;
                            beschreibung?: string;
                            lage?: string;
                            typ?: string;
                            quadratmeter?: number;
                        }
                      | number
                      | null;
                  mieter?:
                      | {
                            mid: number;
                            meid: number | null;
                            anrede?: string;
                            titel?: string;
                            vorname?: string;
                            nachname?: string;
                            telefonnummer?: string;
                            email?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            nutzungsart?: string;
                            IBAN?: string;
                            BIC?: string;
                            quadratmeter?: number;
                            wohnungsnummer?: string;
                        }
                      | number
                      | null;
                  dienstleister?:
                      | {
                            did: number;
                            unternehmen?: string;
                            anrede?: string;
                            titel?: string;
                            vorname?: string;
                            nachname?: string;
                            telefonnummer?: string;
                            email?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            IBAN?: string;
                            BIC?: string;
                        }
                      | number
                      | null;
                  bk_faehig?: string;
                  referenznummer?: string;
                  belegnummer?: string;
                  kostenart?:
                      | {
                            kaid: number;
                            kurzname?: string;
                            langname?: string;
                            einnahme?: string;
                            ausgabe?: string;
                            mieterbindung?: string;
                            handwerker?: string;
                            umbuchbar?: string;
                            objekt_mieterbindung?: string;
                            kategorie?: string;
                            bka_kostenart?: string;
                        }
                      | number
                      | null;
                  kaltmiete?: number;
                  betriebskosten?: number;
                  heizkosten?: number;
                  pkw?: number;
                  garten?: number;
                  strom?: number;
                  antenne?: number;
                  mwst?: number;
                  mietobjekt_bezug?: string;
              }
          ]
        | null;
    belegeDidInvalidate: boolean;
    belegePending: boolean;
    belege:
        | [
              {
                  archivid: number;
                  rechnung: number;
                  dokument?: string;
                  erfassungsdatum?: Date;
                  belegart?: string;
                  beschreibung?: string;
                  betrag: number;
                  bk_faehig?: string;
                  referenznummer?: string;
                  belegnummer?: string;
                  mietobjekt_bezug?: string;
                  status?: string;
                  bka?: number;
                  freigabeStatus?: string;
                  eigentuemer:
                      | {
                            eid: number;
                            anrede?: string;
                            titel?: string;
                            vorname?: string;
                            nachname?: string;
                            telefonnummer?: string;
                            email?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            verwaltervertrag_von?: Date | null;
                            verwaltervertrag_bis?: Date | null;
                            IBAN?: string;
                            BIC?: string;
                            aktueller_kontostand?: number | null;
                        }
                      | number
                      | null;
                  mietobjekt?:
                      | {
                            moid: number;
                            eid: number | null;
                            flurstueck?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            status?: string;
                            gesamtflaeche?: number;
                            anzahl_me?: number;
                        }
                      | number
                      | null;
                  mieteinheit?:
                      | {
                            meid: number;
                            moid: number | null;
                            beschreibung?: string;
                            lage?: string;
                            typ?: string;
                            quadratmeter?: number;
                        }
                      | number
                      | null;
                  mieter?:
                      | {
                            mid: number;
                            meid: number | null;
                            anrede?: string;
                            titel?: string;
                            vorname?: string;
                            nachname?: string;
                            telefonnummer?: string;
                            email?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            nutzungsart?: string;
                            IBAN?: string;
                            BIC?: string;
                            quadratmeter?: number;
                            wohnungsnummer?: string;
                        }
                      | number
                      | null;
                  dienstleister?:
                      | {
                            did: number;
                            unternehmen?: string;
                            anrede?: string;
                            titel?: string;
                            vorname?: string;
                            nachname?: string;
                            telefonnummer?: string;
                            email?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            IBAN?: string;
                            BIC?: string;
                        }
                      | number
                      | null;
                  kostenart?:
                      | {
                            kaid: number;
                            kurzname?: string;
                            langname?: string;
                            einnahme?: string;
                            ausgabe?: string;
                            mieterbindung?: string;
                            handwerker?: string;
                            umbuchbar?: string;
                            objekt_mieterbindung?: string;
                            kategorie?: string;
                            bka_kostenart?: string;
                        }
                      | number
                      | null;
              }
          ]
        | null;
    currentUserEigentuemer:
        | [
              {
                  eid: string;
                  nachname: string;
                  mietobjekte?:
                      | [
                            {
                                moid: number;
                                eid: number | null;
                                flurstueck?: string;
                                strasse?: string;
                                hausnummer?: string;
                                postleitzahl?: string;
                                ort?: string;
                                status?: string;
                                gesamtflaeche?: number;
                                anzahl_me?: number;
                            }
                        ]
                      | null;
                  mieter?:
                      | [
                            {
                                mid: number;
                                meid: number | null;
                                anrede?: string;
                                titel?: string;
                                vorname?: string;
                                nachname?: string;
                                telefonnummer?: string;
                                email?: string;
                                strasse?: string;
                                hausnummer?: string;
                                postleitzahl?: string;
                                ort?: string;
                                nutzungsart?: string;
                                IBAN?: string;
                                BIC?: string;
                                quadratmeter?: number;
                                wohnungsnummer?: string;
                            }
                        ]
                      | null;
                  kostenarten?:
                      | [
                            {
                                kaid: number;
                                kurzname?: string;
                                langname?: string;
                                einnahme?: string;
                                ausgabe?: string;
                                mieterbindung?: string;
                                handwerker?: string;
                                umbuchbar?: string;
                                objekt_mieterbindung?: string;
                                kategorie?: string;
                                bka_kostenart?: string;
                            }
                        ]
                      | null;
                  buchungen_start_jahr: number | null;
                  buchungen_end_jahr: number | null;
              }
          ]
        | null;
    selectedCurrentUserEigentuemer: { eid: string; nachname: string } | null;
    refreshDataSuccess: boolean;
    excelDownloadPending: boolean;
    excelDownloadSuccess: boolean;
    jahresauswertungPending: boolean;
    jahresauswertung: {
        id?: string;
        eigentuemer: {
            eid: number;
            anrede?: string;
            titel?: string;
            vorname?: string;
            nachname?: string;
            telefonnummer?: string;
            email?: string;
            strasse?: string;
            hausnummer?: string;
            postleitzahl?: string;
            ort?: string;
            verwaltervertrag_von?: Date | null;
            verwaltervertrag_bis?: Date | null;
            IBAN?: string;
            BIC?: string;
            aktueller_kontostand?: number | null;
            mietobjekte?: string[] | null;
            mieter?: string[] | null;
            kostenarten?: string[] | null;
        };
        jahr: number;
        summe_monate: number[];
        summe_total: number;
        mietobjekte: [
            {
                mietobjekt: {
                    moid: number;
                    eid: number | null;
                    flurstueck?: string;
                    strasse?: string;
                    hausnummer?: string;
                    postleitzahl?: string;
                    ort?: string;
                    status?: string;
                    gesamtflaeche?: number;
                    anzahl_me?: number;
                };
                summe_monate: number[];
                summe_total: number;
                mieter: [
                    {
                        mieter: {
                            mid: number;
                            meid: number | null;
                            anrede?: string;
                            titel?: string;
                            vorname?: string;
                            nachname?: string;
                            telefonnummer?: string;
                            email?: string;
                            strasse?: string;
                            hausnummer?: string;
                            postleitzahl?: string;
                            ort?: string;
                            nutzungsart?: string;
                            IBAN?: string;
                            BIC?: string;
                            quadratmeter?: number;
                            wohnungsnummer?: string;
                        } | null;
                        summe_monate: number[];
                        summe_total: number;
                        kostenarten: [
                            {
                                kostenart: {
                                    kaid: number;
                                    kurzname?: string;
                                    langname?: string;
                                    einnahme?: string;
                                    ausgabe?: string;
                                    mieterbindung?: string;
                                    handwerker?: string;
                                    umbuchbar?: string;
                                    objekt_mieterbindung?: string;
                                    kategorie?: string;
                                    bka_kostenart?: string;
                                };
                                summe_monate: number[];
                                summe_total: number;
                            }
                        ];
                    }
                ];
            }
        ];
    } | null;
    kontostand: number | null;
    configurationDidInvalidate: boolean;
    configurationUpdated: boolean;
    configurations:
        | [
              {
                  configIdentifier: string;
                  properties: { [x: string]: string };
              }
          ]
        | null;
    wegBeschluesse:
        | [
              {
                  id?: string;
                  eigentuemerEid: number;
                  beschlussnummer: string;
                  beschreibung: string;
                  pdfName?: string;
              }
          ]
        | null;
    wegBeschlussDidInvalidate: boolean;
    wegVersammlungen:
        | [
              {
                  id?: string;
                  eigentuemerEid: number;
                  versammlungsnummer: string;
                  datum: string;
                  ort: string;
                  pdfName?: string;
              }
          ]
        | null;
    wegVersammlungenDidInvalidate: boolean;
    wegChatMessages: any[];
    wegChatSocket: any;
    wegChatUserEigentuemer:
        | [
              {
                  user: {
                      email: string;
                      firstName: string;
                      lastName: string;
                      id: string;
                  };
                  id: string;
              }
          ]
        | null;
    analyticsResponse: any;
    analyticsResponsePending: boolean;
}

const initialState = {
    eigentuemer: null,
    userEigentuemer: null,
    selectedEigentuemer: null,
    newUserEmailExists: false,
    usersOwnerDidInvalidate: false,
    newUserAddedSuccessfully: false,
    newUserCreationPending: false,
    transactionPending: false,
    transactions: null,
    belegeDidInvalidate: false,
    belegePending: false,
    belege: null,
    currentUserEigentuemer: null,
    selectedCurrentUserEigentuemer: null,
    refreshDataSuccess: false,
    excelDownloadPending: false,
    excelDownloadSuccess: false,
    jahresauswertungPending: false,
    jahresauswertung: null,
    kontostand: null,
    configurationDidInvalidate: false,
    configurationUpdated: false,
    configurations: null,
    wegBeschluesse: null,
    wegBeschlussDidInvalidate: false,
    wegVersammlungen: null,
    wegVersammlungenDidInvalidate: false,
    wegChatMessages: [],
    wegChatSocket: null,
    wegChatUserEigentuemer: null,
    analyticsResponse: null,
    analyticsResponsePending: false,
};

const reducer = (state: RIPropertyManagementState = initialState, action: Action): RIPropertyManagementState => {
    switch (action.type) {
        case ActionType.RI_PROPERTY_MANAGEMENT_EIGENTUEMER_GET_SUCCESS:
            return {
                ...state,
                eigentuemer: action.payload.eigentuemer,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_USER_EIGENTUEMER_GET_SUCCESS:
            return {
                ...state,
                userEigentuemer: action.payload.userEigentuemer,
                selectedEigentuemer: action.payload.eigentuemer,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_USER_EIGENTUEMER_RESET:
            return {
                ...state,
                userEigentuemer: null,
                selectedEigentuemer: null,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_CHECK_USER_EMAIL_SUCCESS:
            return {
                ...state,
                newUserEmailExists: action.payload.userExists,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_ADD_USER_TO_OWNER_PENDING:
            return {
                ...state,
                newUserAddedSuccessfully: false,
                newUserCreationPending: true,
                usersOwnerDidInvalidate: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_ADD_USER_TO_OWNER_SUCCESS:
            return {
                ...state,
                usersOwnerDidInvalidate: true,
                newUserAddedSuccessfully: true,
                newUserEmailExists: false,
                newUserCreationPending: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_USER_TO_OWNER_PENDING:
            return {
                ...state,
                usersOwnerDidInvalidate: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_USER_TO_OWNER_SUCCESS:
            return {
                ...state,
                usersOwnerDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_DELETE_USER_FROM_OWNER_PENDING:
            return {
                ...state,
                usersOwnerDidInvalidate: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_DELETE_USER_FROM_OWNER_SUCCESS:
            return {
                ...state,
                usersOwnerDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_TRANSACTION_GET_PENDING:
            return {
                ...state,
                transactionPending: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_TRANSACTION_GET_SUCCESS:
            return {
                ...state,
                transactions: action.payload.buchungen,
                selectedCurrentUserEigentuemer: action.payload.eigentuemer,
                transactionPending: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_CLEAR_TRANSACTION:
            return {
                ...state,
                transactions: null,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_GET_PENDING:
            return {
                ...state,
                belegePending: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_GET_SUCCESS:
            return {
                ...state,
                belege: action.payload.belege,
                selectedCurrentUserEigentuemer: action.payload.eigentuemer,
                belegePending: false,
                belegeDidInvalidate: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_CLEAR_BELEGE:
            return {
                ...state,
                belege: null,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_STATUS_PENDING:
            return {
                ...state,
                belegeDidInvalidate: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_BELEGE_STATUS_SUCCESS:
            return {
                ...state,
                belegeDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_CURRENT_USER_EIGENTUEMER_GET_SUCCESS:
            return {
                ...state,
                currentUserEigentuemer: action.payload.currentUserEigentuemer,
                transactions: null,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_REFRESH_DATA_PENDING:
            return {
                ...state,
                refreshDataSuccess: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_REFRESH_DATA_SUCCESS:
            return {
                ...state,
                refreshDataSuccess: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_EXCEL_DOWNLOAD_PENDING:
            return {
                ...state,
                excelDownloadSuccess: false,
                excelDownloadPending: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_EXCEL_DOWNLOAD_SUCCESS:
            return {
                ...state,
                excelDownloadSuccess: true,
                excelDownloadPending: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_EXCEL_DOWNLOAD_POPUP_SHOWN:
            return {
                ...state,
                excelDownloadSuccess: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_JAHRESAUSWERTUNG_GET_PENDING:
            return {
                ...state,
                jahresauswertungPending: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_JAHRESAUSWERTUNG_GET_SUCCESS:
            return {
                ...state,
                jahresauswertung: action.payload.jahresauswertung,
                jahresauswertungPending: false,
                kontostand: action.payload.kontostand,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_CONFIGURATION_GET_SUCCESS:
            return {
                ...state,
                configurations: action.payload.configurations,
                configurationDidInvalidate: false,
                configurationUpdated: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_CONFIGURATION_PENDING:
            return {
                ...state,
                configurationUpdated: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                configurationUpdated: true,
                configurationDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_BESCHLUESSE_GET_SUCCESS:
            return {
                ...state,
                wegBeschluesse: action.payload.wegBeschluesse,
                wegBeschlussDidInvalidate: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_NEW_WEG_BESCHLUSS_SUCCESS:
            return {
                ...state,
                wegBeschlussDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_UPDATE_WEG_BESCHLUSS_SUCCESS:
            return {
                ...state,
                wegBeschlussDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_DELETE_WEG_BESCHLUSS_SUCCESS:
            return {
                ...state,
                wegBeschlussDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_VERSAMMLUNGEN_GET_SUCCESS:
            return {
                ...state,
                wegVersammlungen: action.payload.wegVersammlungen,
                wegVersammlungenDidInvalidate: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_NEW_WEG_VERSAMMLUNGEN_SUCCESS:
            return {
                ...state,
                wegVersammlungenDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_UPDATE_WEG_VERSAMMLUNGEN_SUCCESS:
            return {
                ...state,
                wegVersammlungenDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_DELETE_WEG_VERSAMMLUNGEN_SUCCESS:
            return {
                ...state,
                wegVersammlungenDidInvalidate: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_GET_SUCCESS:
            return {
                ...state,
                wegChatMessages: action.payload.wegChat,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_NEW_MESSAGE:
            return {
                ...state,
                wegChatMessages: [...state.wegChatMessages, action.payload],
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_SOCKET:
            if (state.wegChatSocket) {
                state.wegChatSocket.disconnect();
            }
            return {
                ...state,
                wegChatSocket: action.payload,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_DELETE_MESSAGES:
            return {
                ...state,
                wegChatMessages: [],
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_USER_EIGENTUEMER_GET_SUCCESS:
            return {
                ...state,
                wegChatUserEigentuemer: action.payload.userEigentuemer,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_PENDING:
            return {
                ...state,
                analyticsResponse: null,
                analyticsResponsePending: true,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_FAIL:
            return {
                ...state,
                analyticsResponsePending: false,
            };
        case ActionType.RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_SUCCESS:
            return {
                ...state,
                analyticsResponse: action.payload,
                analyticsResponsePending: false,
            };
        default:
            return state;
    }
};

export default reducer;
