import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './UserEigentuemerApp.module.css';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import showConfirmPopup from '../../../../benit/central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';

const RIRealEstateConfigurationApp = () => {
    const [isConfig, setIsConfig] = useState<{ [x: string]: string }>({
        token: '',
        tokenSecret: '',
        verifier: '',
    });

    const [ftpConfigIVD, setFtpConfigIVD] = useState<{ [x: string]: string }>({
        anbieterNr: '',
        host: '',
        port: '',
        user: '',
        password: '',
    });

    const [ftpConfigEbay, setFtpConfigEbay] = useState<{ [x: string]: string }>({
        anbieterNr: '',
        host: '',
        port: '',
        user: '',
        password: '',
    });

    const [ftpConfigImmowelt, setFtpConfigImmowelt] = useState<{ [x: string]: string }>({
        anbieterNr: '',
        host: '',
        port: '',
        user: '',
        password: '',
    });

    const [standardValues, setStandardValues] = useState<{ [x: string]: string }>({
        courtage: '',
        courtageNote: '',
        otherNote: '',
    });
    const [exposeValues, setExposeValues] = useState<{ [x: string]: string }>({
        agb: '',
    });
    const { t } = useTranslation(['general', 'ri-property-management']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { ri_real_estate_get_configuration, ri_real_estate_change_configuration } = useActions();

    const { configurationDidInvalidate, configurationUpdated, configurations } = useTypedSelector((state) => state.riRealEstateReducer);

    useEffect(() => {
        ri_real_estate_get_configuration();
    }, []);

    useEffect(() => {
        if (configurationDidInvalidate) {
            ri_real_estate_get_configuration();
        }
    }, [configurationDidInvalidate]);

    useEffect(() => {
        if (configurationUpdated) {
            fireToast('success', `Konfiguration gespeichert`);
        }
    }, [configurationUpdated]);

    useEffect(() => {
        if (configurations) {
            configurations.map((configuration) => {
                if (configuration.configIdentifier === 'isConfig') {
                    setIsConfig(configuration.properties);
                }
                if (configuration.configIdentifier === 'standardValues') {
                    setStandardValues(configuration.properties);
                }
                if (configuration.configIdentifier === 'exposeValues') {
                    setExposeValues(configuration.properties);
                }
                if (configuration.configIdentifier === 'ftpConfigIVD') {
                    setFtpConfigIVD(configuration.properties);
                }
                if (configuration.configIdentifier === 'ftpConfigEbay') {
                    setFtpConfigEbay(configuration.properties);
                }
                if (configuration.configIdentifier === 'ftpConfigImmowelt') {
                    setFtpConfigImmowelt(configuration.properties);
                }
            });
        }
    }, [configurations]);

    return (
        <div>
            <div className="row">
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                Immobilienscout Integration
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Token</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={isConfig.token}
                                    onChange={(e) =>
                                        setIsConfig({
                                            ...isConfig,
                                            token: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Token Secret</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={isConfig.tokenSecret}
                                    onChange={(e) =>
                                        setIsConfig({
                                            ...isConfig,
                                            tokenSecret: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Verifier</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={isConfig.verifier}
                                    onChange={(e) =>
                                        setIsConfig({
                                            ...isConfig,
                                            verifier: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-primary" onClick={(e) => ri_real_estate_change_configuration('isConfig', isConfig)}>
                                    Konfiguration Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                IVD24 Integration
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Anbieter Nr.</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigIVD.anbieterNr}
                                    onChange={(e) =>
                                        setFtpConfigIVD({
                                            ...ftpConfigIVD,
                                            anbieterNr: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP URL</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigIVD.host}
                                    onChange={(e) =>
                                        setFtpConfigIVD({
                                            ...ftpConfigIVD,
                                            host: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP Port</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigIVD.port}
                                    onChange={(e) =>
                                        setFtpConfigIVD({
                                            ...ftpConfigIVD,
                                            port: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP User Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigIVD.user}
                                    onChange={(e) =>
                                        setFtpConfigIVD({
                                            ...ftpConfigIVD,
                                            user: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigIVD.password}
                                    onChange={(e) =>
                                        setFtpConfigIVD({
                                            ...ftpConfigIVD,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-primary" onClick={(e) => ri_real_estate_change_configuration('ftpConfigIVD', ftpConfigIVD)}>
                                    Konfiguration Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                Kleinanzeigen Integration
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Anbieter Nr.</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigEbay.anbieterNr}
                                    onChange={(e) =>
                                        setFtpConfigEbay({
                                            ...ftpConfigEbay,
                                            anbieterNr: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP URL</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigEbay.host}
                                    onChange={(e) =>
                                        setFtpConfigEbay({
                                            ...ftpConfigEbay,
                                            host: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP Port</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigEbay.port}
                                    onChange={(e) =>
                                        setFtpConfigEbay({
                                            ...ftpConfigEbay,
                                            port: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP User Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigEbay.user}
                                    onChange={(e) =>
                                        setFtpConfigEbay({
                                            ...ftpConfigEbay,
                                            user: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigEbay.password}
                                    onChange={(e) =>
                                        setFtpConfigEbay({
                                            ...ftpConfigEbay,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-primary" onClick={(e) => ri_real_estate_change_configuration('ftpConfigEbay', ftpConfigEbay)}>
                                    Konfiguration Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                immowelt Integration
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Anbieter Nr.</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigImmowelt.anbieterNr}
                                    onChange={(e) =>
                                        setFtpConfigImmowelt({
                                            ...ftpConfigImmowelt,
                                            anbieterNr: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP URL</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigImmowelt.host}
                                    onChange={(e) =>
                                        setFtpConfigImmowelt({
                                            ...ftpConfigImmowelt,
                                            host: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP Port</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigImmowelt.port}
                                    onChange={(e) =>
                                        setFtpConfigImmowelt({
                                            ...ftpConfigImmowelt,
                                            port: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP User Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigImmowelt.user}
                                    onChange={(e) =>
                                        setFtpConfigImmowelt({
                                            ...ftpConfigImmowelt,
                                            user: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>FTP Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder=""
                                    value={ftpConfigImmowelt.password}
                                    onChange={(e) =>
                                        setFtpConfigImmowelt({
                                            ...ftpConfigImmowelt,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-primary" onClick={(e) => ri_real_estate_change_configuration('ftpConfigImmowelt', ftpConfigImmowelt)}>
                                    Konfiguration Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                Standard-Werte
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Provision</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={standardValues.courtage}
                                    onChange={(e) =>
                                        setStandardValues({
                                            ...standardValues,
                                            courtage: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Provisionshinweis</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={standardValues.courtageNote}
                                    onChange={(e) =>
                                        setStandardValues({
                                            ...standardValues,
                                            courtageNote: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Sonstige Angaben</label>
                                <div className="input-group">
                                    <textarea
                                        className="form-control"
                                        rows={5}
                                        value={standardValues.otherNote}
                                        onChange={(e) =>
                                            setStandardValues({
                                                ...standardValues,
                                                otherNote: e.target.value,
                                            })
                                        }
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-primary" onClick={(e) => ri_real_estate_change_configuration('standardValues', standardValues)}>
                                    Standard-Werte Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6 col-xl-6 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                Exposé-Werte
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>AGB</label>
                                <div className="input-group">
                                    <textarea
                                        className="form-control"
                                        rows={20}
                                        value={exposeValues.agb}
                                        onChange={(e) =>
                                            setExposeValues({
                                                ...exposeValues,
                                                agb: e.target.value,
                                            })
                                        }
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-primary" onClick={(e) => ri_real_estate_change_configuration('exposeValues', exposeValues)}>
                                    Exposé-Werte Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default RIRealEstateConfigurationApp;
