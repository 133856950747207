export enum ActionType {
    BENIT_CENTRAL_CONFIGURATION_LANGUAGE_CHANGE = 'benit_central_configuration_language_change',
    BENIT_CENTRAL_CONFIGURATION_LANGUAGE_CHANGED = 'benit_central_configuration_language_changed',
    BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_GET_PENDING = 'benit_central_configuration_organizations_get_pending',
    BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_GET_SUCCESS = 'benit_central_configuration_organizations_get_success',
    BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_CREATE_PENDING = 'benit_central_configuration_organizations_create_pending',
    BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_CREATE_SUCCESS = 'benit_central_configuration_organizations_create_success',
    BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_UPDATE_PENDING = 'benit_central_configuration_organizations_update_pending',
    BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_UPDATE_SUCCESS = 'benit_central_configuration_organizations_update_success',
    BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_DELETE_SUCCESS = 'benit_central_configuration_organizations_delete_success',
    BENIT_CENTRAL_CONFIGURATION_SERVICES_GET_SUCCESS = 'benit_central_configuration_services_get_success',
    BENIT_CENTRAL_CONFIGURATION_SERVICES_GET_PENDING = 'benit_central_configuration_services_get_pending',
    BENIT_CENTRAL_CONFIGURATION_SERVICES_DELETE_SUCCESS = 'benit_central_configuration_services_delete_success',
    BENIT_CENTRAL_CONFIGURATION_CHECK_USER_EMAIL_SUCCESS = 'benit_central_configuration_check_user_email_success',
}
