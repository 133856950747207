export enum ActionType {
    BENIT_ASSISTANT_ASK_ASSISTANT_PENDING = 'benit_assistant_ask_assistant_pending',
    BENIT_ASSISTANT_ASK_ASSISTANT_SUCCESS = 'benit_assistant_ask_assistant_success',
    BENIT_ASSISTANT_ASK_ASSISTANT_FAIL = 'benit_assistant_ask_assistant_fail',
    BENIT_ASSISTANT_NEW_REPORT_PDF_PENDING = 'benit_assistant_new_report_pdf_pending',
    BENIT_ASSISTANT_NEW_REPORT_PDF_AVAILABLE = 'benit_assistant_new_report_pdf_available',
    BENIT_ASSISTANT_UPDATE_ANSWER = 'benit_assistant_update_answer',
    BENIT_ASSISTANT_TRANSCRIPTION_PENDING = 'benit_assistant_transcription_pending',
    BENIT_ASSISTANT_TRANSCRIPTION_SUCCESS = 'benit_assistant_transcription_success',
}
