import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';
import { useActions } from '../../../../../hooks/useActions';
import { actionCreators } from '../../../../../state';

export const benit_central_configuration_get_organizations = (searchTerm = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/central-configuration/organization?searchTerm=${searchTerm}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_central_configuration_create_organization = (
    name: string,
    identifier: string,
    industry: { [x: string]: string },
    userEmail: string,
    userFirstName: string,
    userLastName: string
) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_CREATE_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/benit/central-configuration/organization',
            method: 'post',
            body: {
                name,
                identifier,
                industry,
                userEmail,
                userFirstName,
                userLastName,
            },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_CREATE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_central_configuration_update_organization = (id: string, name: string, identifier: string, industry: { [x: string]: string }) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_UPDATE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/central-configuration/organization/${id}`,
            method: 'put',
            body: { name, industry, identifier },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_UPDATE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_central_configuration_delete_organization = (id: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/central-configuration/organization/${id}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_ORGANIZATIONS_DELETE_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_central_configuration_check_user_email = (newUserEmail = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/auth/users/check/${newUserEmail}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_CHECK_USER_EMAIL_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
