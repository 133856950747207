// @ts-nocheck
import useRequest from '../hooks/useRequest';
import { Dispatch } from 'redux';
import { actionCreators } from '../../../../../state';
import { ActionType } from '../services/benit/auth/state/action-types';

export default function jwtRefreshMiddleware() {
    return ({ dispatch, getState }) =>
        (next) =>
        (action) => {
            const { logonExpirationTime } = getState().benitAuthReducer;

            const expirationDate = new Date(logonExpirationTime);
            const thresholdDate = new Date(Math.floor((new Date().getTime() + 60000) / 1000));

            if (thresholdDate > expirationDate && expirationDate > 0) {
                const { doRequest } = useRequest({
                    url: '/api/benit/auth/users/get-new-jwt',
                    method: 'post',
                    body: {},
                    onSuccess: (data) => {
                        dispatch({
                            type: ActionType.LOGIN_USER_SUCCESS,
                            payload: data,
                        });
                    },
                    onError: (error) => {
                        window.location.reload();
                    },
                });

                doRequest();
            }
            return next(action);
        };
}
