import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './NewInvoiceCard.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../../hooks/useMultiLanguageFieldValue';
import fireToast from '../../../../central-ui/methods/fireToast';
import { availableLanguages } from '../../../../../../i18n';
import { useHistory } from 'react-router-dom';

type OptionType = {
    value: string;
    label: string;
};

const NewInvoiceCard = () => {
    const history = useHistory();
    const { languageKey } = useTypedSelector((state) => state.benitCentralConfigurationReducer);
    const [invoice, setInvoice] = useState({ invoiceId: '', positions: [{ vat: 0.19 }], language: languageKey });

    const { t } = useTranslation(['general', 'benit-accounting']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_accounting_create_invoice, benit_accounting_reset_invoice_creation_state } = useActions();

    const { newInvoiceCreationPending, createdInvoiceId, nextInvoiceId } = useTypedSelector((state) => state.benitAccountingReducer);

    useEffect(() => {
        if (createdInvoiceId) {
            history.push(`/benit/accounting/invoices/${createdInvoiceId}`);
            fireToast(
                'success',
                t('benit-accounting:copy_invoice_from', {
                    invoiceId: createdInvoiceId,
                })
            );
            benit_accounting_reset_invoice_creation_state();
        }
    }, [createdInvoiceId]);

    useEffect(() => {
        if (nextInvoiceId) {
            setInvoice({ ...invoice, invoiceId: nextInvoiceId });
        }
    }, [nextInvoiceId]);

    useEffect(() => {
        if (nextInvoiceId) {
            setInvoice({ ...invoice, language: languageKey ?? 'en' });
        }
    }, [languageKey]);

    return (
        <div className="card card-success mb-1 collapsed-card">
            <div className="btn btn-lg btn-outline-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                {t('benit-accounting:invoice_creation')}
            </div>
            <div className="card-body box-profile">
                <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                    <input
                        type="text"
                        className={`form-control control-border`}
                        placeholder={t('benit-accounting:invoice_number')}
                        value={invoice.invoiceId}
                        onChange={(e) =>
                            setInvoice({
                                ...invoice,
                                invoiceId: e.target.value,
                            })
                        }
                    />
                </div>

                <button className="btn btn-outline-primary btn-block mt-4" onClick={(e) => benit_accounting_create_invoice(invoice)} disabled={invoice.invoiceId != '' ? false : true}>
                    {newInvoiceCreationPending ? <div className={styles.loader}></div> : <b>{t('general:continue')}</b>}
                </button>
            </div>
        </div>
    );
};

export default NewInvoiceCard;
