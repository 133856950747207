import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const benit_organization_configuration_get_users_org = (searchTerm = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_USERS_ORGANIZATION_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/organization-configuration/user-org?searchTerm=${searchTerm}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_USERS_ORGANIZATION_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_organization_configuration_check_user_email = (newUserEmail = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/auth/users/check/${newUserEmail}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_CHECK_USER_EMAIL_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_organization_configuration_add_user = (
    email: string,
    firstName: string,
    lastName: string,
    permissionIdentifiers: string[],
    startApplicationId: string | null,
    userLanguages: string[] | null,
    userDefaultLanguage: string | null
) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/benit/organization-configuration/user-org',
            method: 'post',
            body: { email, firstName, lastName, permissionIdentifiers, startApplicationId, userLanguages, userDefaultLanguage },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_ERROR,
                });
            },
        });

        doRequest();
    };
};

export const benit_organization_configuration_get_permissions = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_PERMISSIONS_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/benit/organization-configuration/organization-permissions',
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_PERMISSIONS_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_organization_configuration_get_applications = (permissionIdentifiers: string[], newOrEdit: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: '/api/benit/organization-configuration/applications',
            method: 'post',
            body: { permissionIdentifiers },
            onSuccess: (data) => {
                data.newOrEdit = newOrEdit;
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_APPLICATIONS_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_organization_configuration_update_user_org = (
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    permissions: string[],
    startApplicationId: string | null,
    userLanguages: string[] | null,
    userDefaultLanguage: string | null
) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_ORG_UPDATE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/organization-configuration/user-org/${id}`,
            method: 'put',
            body: { email, firstName, lastName, permissions, startApplicationId, userLanguages, userDefaultLanguage },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_ORG_UPDATE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_organization_configuration_delete_user = (id: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/organization-configuration/user-org/${id}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_DELETE_USER_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
