import axios from 'axios';
import FileDownload from 'js-file-download';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const ri_property_management_get_transactions = (
    eigentuemerEid: string,
    from: string | null = '',
    to: string | null = '',
    moid: string | null = '',
    mid: string | null = '',
    kaid: string | null = ''
) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_TRANSACTION_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/ri/property-management/transactions/${eigentuemerEid}?from=${from}&to=${to}&moid=${moid}&mid=${mid}&kaid=${kaid}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_TRANSACTION_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_clear_transaction = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_CLEAR_TRANSACTION,
        });
    };
};

export const ri_property_management_get_current_user_eigentuemer = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/current-user-eigentuemer`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_CURRENT_USER_EIGENTUEMER_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_pdf_download = (documentName: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/get-file/${documentName}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                //FileDownload(data, documentName);
                const file = new Blob([data], { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                const pdfWindow = window.open();
                if (pdfWindow) {
                    pdfWindow.location.href = fileURL;
                }
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest({ responseType: 'blob' });
    };
};
