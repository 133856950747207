import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const benit_ask_assistant = (prompt: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ASSISTANT_ASK_ASSISTANT_PENDING,
            payload: { prompt },
        });

        const { doRequest } = useRequest({
            url: '/api/ri/property-management/assistant-interface',
            method: 'post',
            body: { prompt },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ASSISTANT_ASK_ASSISTANT_SUCCESS,
                    payload: data,
                });
                if (data?.answer?.type === 'report') {
                    dispatch(actionCreators.benit_assistant_get_report_document({ answer: data?.answer?.data?.text ? data.answer.data.text : '' }, false));
                }
                if (data?.answer?.type === 'sis') {
                    dispatch(actionCreators.benit_assistant_get_report_document({ answer: data?.answer?.data ? data.answer.data : '' }, false));
                }
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch({
                    type: ActionType.BENIT_ASSISTANT_ASK_ASSISTANT_FAIL,
                });
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const benit_assistant_get_report_document = (reportInput: any, downloadPdf = false) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ASSISTANT_NEW_REPORT_PDF_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/ri/property-management/poc/report`,
            method: 'post',
            body: { reportInput },
            onSuccess: (data) => {
                const buff = Buffer.from(data, 'hex');
                const file = new Blob([buff], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(file);

                dispatch({
                    type: ActionType.BENIT_ASSISTANT_NEW_REPORT_PDF_AVAILABLE,
                    payload: { fileURL },
                });

                if (downloadPdf) {
                    var element = document.createElement('a');
                    element.setAttribute('href', fileURL);
                    element.setAttribute('download', `Report.pdf`);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();
                    document.body.removeChild(element);
                }
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest({ responseType: 'blob' });
    };
};

export const benit_assistant_update_answer = (answer: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ASSISTANT_UPDATE_ANSWER,
            payload: { answer },
        });
    };
};

export const benit_assistant_transcribe_voice_input = (filePath: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        // console.log(picture);
        // picture.name = `${objectId}-${picture.name}`;
        dispatch({
            type: ActionType.BENIT_ASSISTANT_TRANSCRIPTION_PENDING,
        });

        let response = await fetch(filePath);
        let file = await response.blob();
        const uploadFile = new File([file], `voice.wav`, { type: 'audio/wav' });
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/assistant-transcription`,
            method: 'post',
            body: {},
            file: uploadFile,
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ASSISTANT_TRANSCRIPTION_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
