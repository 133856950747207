import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';

interface IProps {
    author: string;
    date: Date;
    text: string;
    ownMessage: boolean;
}

const ChatMessage = ({ author, date, text, ownMessage }: IProps) => {
    return (
        <div className={`direct-chat-msg ${ownMessage ? 'right' : null}`}>
            <div className="direct-chat-infos clearfix">
                <span className={`direct-chat-name ${ownMessage ? 'float-right' : 'float-left'}`}>{author}</span>
                <span className={`direct-chat-timestamp ${ownMessage ? 'float-left' : 'float-right'}`}>
                    {new Intl.DateTimeFormat('de-de', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }).format(new Date(date).getTime())}
                </span>
            </div>
            <span className="direct-chat-img">
                <Avatar name={author} round=".3rem" size="35" />
            </span>

            <div className="direct-chat-text">{text}</div>
        </div>
    );
};

export default ChatMessage;
