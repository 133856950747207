import { combineReducers } from 'redux';
import benitAuthReducer from '../services/benit/auth/state/reducers';
import benitCentralConfigurationReducer from '../services/benit/central-configuration/state/reducers';
import benitCentralUIReducer from '../services/benit/central-ui/state/reducers';
import benitAssistantReducer from '../services/benit/assistant/state/reducers';
import benitCareReducer from '../services/benit/care/state/reducers';
import benitOrganizationConfigurationReducer from '../services/benit/organization-configuration/state/reducers';
import benitAccountingReducer from '../services/benit/accounting/state/reducers';
import riPropertyManagementReducer from '../services/ri/property-management/state/reducers';
import riRealEstateReducer from '../services/ri/real-estate/state/reducers';

const reducers = combineReducers({
    benitAuthReducer,
    benitCentralConfigurationReducer,
    benitOrganizationConfigurationReducer,
    benitAccountingReducer,
    benitCareReducer,
    benitCentralUIReducer,
    benitAssistantReducer,
    riPropertyManagementReducer,
    riRealEstateReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
