import { data } from 'jquery';
import { ActionType } from '../action-types';
import { Action } from '../actions';

interface BenitAccountingState {
    configurationDidInvalidate: boolean;
    configurationUpdated: boolean;
    configurations:
        | [
              {
                  configIdentifier: string;
                  properties: { [x: string]: string };
              }
          ]
        | null;
    newInvoiceCreationPending: boolean;
    nextInvoiceId: string | null;
    createdInvoiceId: string | null;
    updateInvoicePending: boolean;
    updateInvoiceSuccess: boolean;
    updateInvoiceIdInvoiceId: string | null;
    invoices:
        | [
              {
                  invoiceId: string;
                  data: { [x: string]: any };
              }
          ]
        | null;
    getInvoicesPending: boolean;
    invoice: {
        invoiceId: string;
        data: { [x: string]: any };
    } | null;
    invoiceInternalUrl: string | null;
    getInvoicePending: boolean;
    deleteInvoicePending: boolean;
    deleteInvoiceSuccess: boolean;
    customerRecords: any | null;
    createdExpenseId: string | null;
    expenses:
        | [
              {
                  expenseId: string;
                  data: { [x: string]: any };
              }
          ]
        | null;
    expense: {
        expenseId: string;
        data: { [x: string]: any };
    } | null;
    newExpenseCreationPending: boolean;
    nextExpenseId: string | null;
    updateExpensePending: boolean;
    updateExpenseSuccess: boolean;
    updateExpenseIdExpenseId: string | null;
    getExpensesPending: boolean;
    expenseInternalUrl: string | null;
    getExpensePending: boolean;
    deleteExpensePending: boolean;
    deleteExpenseSuccess: boolean;
}

const initialState = {
    configurationDidInvalidate: false,
    configurationUpdated: false,
    configurations: null,
    newInvoiceCreationPending: false,
    nextInvoiceId: null,
    createdInvoiceId: null,
    updateInvoicePending: false,
    updateInvoiceSuccess: false,
    updateInvoiceIdInvoiceId: null,
    invoices: null,
    getInvoicesPending: false,
    invoice: null,
    getInvoicePending: false,
    deleteInvoicePending: false,
    deleteInvoiceSuccess: false,
    invoiceInternalUrl: null,
    customerRecords: null,
    createdExpenseId: null,
    expenses: null,
    expense: null,
    newExpenseCreationPending: false,
    nextExpenseId: null,
    updateExpensePending: false,
    updateExpenseSuccess: false,
    updateExpenseIdExpenseId: null,
    getExpensesPending: false,
    expenseInternalUrl: null,
    getExpensePending: false,
    deleteExpensePending: false,
    deleteExpenseSuccess: false,
};

const reducer = (state: BenitAccountingState = initialState, action: Action): BenitAccountingState => {
    switch (action.type) {
        case ActionType.BENIT_ACCOUNTING_CONFIGURATION_GET_SUCCESS:
            return {
                ...state,
                configurations: action.payload.configurations,
                configurationDidInvalidate: false,
                configurationUpdated: false,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_CONFIGURATION_PENDING:
            return {
                ...state,
                configurationUpdated: false,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                configurationUpdated: true,
                configurationDidInvalidate: true,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_PENDING:
            return {
                ...state,
                createdInvoiceId: null,
                newInvoiceCreationPending: true,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                createdInvoiceId: action.payload.invoice.invoiceId,
                newInvoiceCreationPending: false,
                invoices: null,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_BY_COPY_PENDING:
            return {
                ...state,
                createdInvoiceId: null,
                newInvoiceCreationPending: true,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_BY_COPY_SUCCESS:
            return {
                ...state,
                createdInvoiceId: action.payload.invoice.invoiceId,
                newInvoiceCreationPending: false,
                invoices: null,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_PENDING:
            return {
                ...state,
                updateInvoicePending: true,
                updateInvoiceSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_SUCCESS:
            return {
                ...state,
                updateInvoicePending: false,
                updateInvoiceSuccess: true,
                invoices: null,
                invoice: null,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_ID_PENDING:
            return {
                ...state,
                updateInvoiceIdInvoiceId: null,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_ID_SUCCESS:
            return {
                ...state,
                updateInvoiceIdInvoiceId: action.payload.invoice.invoiceId,
                invoices: null,
                invoice: null,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_INVOICE_CREATION_STATE:
            return {
                ...state,
                createdInvoiceId: null,
                newInvoiceCreationPending: false,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_INVOICE_UPDATE_STATE:
            return {
                ...state,
                updateInvoiceSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_INVOICE_UPDATE_ID_STATE:
            return {
                ...state,
                updateInvoiceIdInvoiceId: null,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_INVOICE_DELETION_STATE:
            return {
                ...state,
                deleteInvoiceSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_GET_INVOICES_PENDING:
            return {
                ...state,
                getInvoicesPending: true,
            };
        case ActionType.BENIT_ACCOUNTING_GET_INVOICES_SUCCESS:
            return {
                ...state,
                getInvoicesPending: false,
                invoices: action.payload.invoices,
            };
        case ActionType.BENIT_ACCOUNTING_GET_INVOICE_PENDING:
            return {
                ...state,
                invoiceInternalUrl: null,
                getInvoicePending: true,
                invoice: null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_INVOICE_SUCCESS:
            return {
                ...state,
                getInvoicePending: false,
                invoice: action.payload.invoices.length === 1 ? action.payload.invoices[0] : null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_NEXT_INVOICE_ID_PENDING:
            return {
                ...state,
                nextInvoiceId: null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_NEXT_INVOICE_ID_SUCCESS:
            return {
                ...state,
                nextInvoiceId: action.payload.nextInvoiceId,
            };
        case ActionType.BENIT_ACCOUNTING_DELETE_INVOICE_PENDING:
            return {
                ...state,
                deleteInvoicePending: true,
                deleteInvoiceSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                deleteInvoicePending: false,
                deleteInvoiceSuccess: true,
                invoice: null,
                invoices: null,
            };
        case ActionType.BENIT_ACCOUNTING_NEW_INVOICE_PDF_AVAILABLE:
            return {
                ...state,
                invoiceInternalUrl: action.payload.fileURL ?? null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_CUSTOMER_RECORDS_PENDING:
            return {
                ...state,
                customerRecords: null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_CUSTOMER_RECORDS_SUCCESS:
            return {
                ...state,
                customerRecords: action.payload.customerRecords ?? null,
            };
        case ActionType.BENIT_ACCOUNTING_EXPENSE_DOCUMENT_UPLOAD_SUCCESS:
            return {
                ...state,
                customerRecords: action.payload.customerRecords ?? null,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_EXPENSE_PENDING:
            return {
                ...state,
                createdExpenseId: null,
                newExpenseCreationPending: true,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_EXPENSE_SUCCESS:
            return {
                ...state,
                createdExpenseId: action.payload.expense.expenseId,
                newExpenseCreationPending: false,
                expenses: null,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_EXPENSE_BY_COPY_PENDING:
            return {
                ...state,
                createdExpenseId: null,
                newExpenseCreationPending: true,
            };
        case ActionType.BENIT_ACCOUNTING_CREATE_EXPENSE_BY_COPY_SUCCESS:
            return {
                ...state,
                createdExpenseId: action.payload.expense.expenseId,
                newExpenseCreationPending: false,
                expenses: null,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_EXPENSE_PENDING:
            return {
                ...state,
                updateExpensePending: true,
                updateExpenseSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_EXPENSE_SUCCESS:
            return {
                ...state,
                updateExpensePending: false,
                updateExpenseSuccess: true,
                expenses: null,
                expense: null,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_EXPENSE_ID_PENDING:
            return {
                ...state,
                updateExpenseIdExpenseId: null,
            };
        case ActionType.BENIT_ACCOUNTING_UPDATE_EXPENSE_ID_SUCCESS:
            return {
                ...state,
                updateExpenseIdExpenseId: action.payload.expense.expenseId,
                expenses: null,
                expense: null,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_EXPENSE_CREATION_STATE:
            return {
                ...state,
                createdExpenseId: null,
                newExpenseCreationPending: false,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_EXPENSE_UPDATE_STATE:
            return {
                ...state,
                updateExpenseSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_EXPENSE_UPDATE_ID_STATE:
            return {
                ...state,
                updateExpenseIdExpenseId: null,
            };
        case ActionType.BENIT_ACCOUNTING_RESET_EXPENSE_DELETION_STATE:
            return {
                ...state,
                deleteExpenseSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_GET_EXPENSES_PENDING:
            return {
                ...state,
                getExpensesPending: true,
            };
        case ActionType.BENIT_ACCOUNTING_GET_EXPENSES_SUCCESS:
            return {
                ...state,
                getExpensesPending: false,
                expenses: action.payload.expenses,
            };
        case ActionType.BENIT_ACCOUNTING_GET_EXPENSE_PENDING:
            return {
                ...state,
                expenseInternalUrl: null,
                getExpensePending: true,
                expense: null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_EXPENSE_SUCCESS:
            return {
                ...state,
                getExpensePending: false,
                expense: action.payload.expenses.length === 1 ? action.payload.expenses[0] : null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_NEXT_EXPENSE_ID_PENDING:
            return {
                ...state,
                nextExpenseId: null,
            };
        case ActionType.BENIT_ACCOUNTING_GET_NEXT_EXPENSE_ID_SUCCESS:
            return {
                ...state,
                nextExpenseId: action.payload.nextExpenseId,
            };
        case ActionType.BENIT_ACCOUNTING_DELETE_EXPENSE_PENDING:
            return {
                ...state,
                deleteExpensePending: true,
                deleteExpenseSuccess: false,
            };
        case ActionType.BENIT_ACCOUNTING_DELETE_EXPENSE_SUCCESS:
            return {
                ...state,
                deleteExpensePending: false,
                deleteExpenseSuccess: true,
                expense: null,
                expenses: null,
            };
        case ActionType.BENIT_ACCOUNTING_NEW_EXPENSE_PDF_AVAILABLE:
            return {
                ...state,
                expenseInternalUrl: action.payload.fileURL ?? null,
            };
        default:
            return state;
    }
};

export default reducer;
