import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

interface ResultOutputProps {
    onSendMessage: (message: string) => void;
    resultText: string;
}

const ResultOutputContainer = styled.div`
    display: block;
    align-items: end;
    justify-content: space-between;
    background-color: #fff;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const ResultOutputField = styled.textarea`
    flex: 1;
    font-size: 16px;
    padding: 8px;
    border: none;
    border-radius: 4px;
    outline: none;
    resize: none;
    min-height: 30px;
`;

const GenerateButtonContainer = styled.div`
    display: block;
    width: 100%;
`;

const GenerateButton = styled.button`
    display: block;
    display: inline-block;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: white;
    border: none;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    }
`;

const ResultOutput: React.FC<ResultOutputProps> = ({ onSendMessage, resultText }) => {
    const [message, setMessage] = useState<null | string>('');
    const inputFieldRef = useRef<HTMLTextAreaElement>(null);

    const handleSend = () => {
        if (message && message.trim() !== '') {
            onSendMessage(message);
            setMessage('');
            if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style) {
                inputFieldRef.current.style.height = '40px';
                inputFieldRef.current.focus();
            }
        }
    };

    useEffect(() => {
        setMessage(resultText);
    }, [resultText]);

    useEffect(() => {
        if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style && resultText) {
            inputFieldRef.current.style.height = '30px';
            inputFieldRef.current.style.height = `${inputFieldRef.current.scrollHeight}px`;
        }
    }, [inputFieldRef.current, resultText]);

    const handleSpeechToText = () => {
        // Implement speech to text functionality here
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
            setMessage('');
            if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style) {
                inputFieldRef.current.style.height = '40px';
            }
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style) {
            const target = event.target;
            inputFieldRef.current.style.height = '30px';
            inputFieldRef.current.style.height = `${target.scrollHeight}px`;
            setMessage(event.target.value);
        }
    };

    return (
        <ResultOutputContainer>
            <div className="row">
                <ResultOutputField rows={1} ref={inputFieldRef} placeholder="" value={message ?? ''} onChange={handleOnChange} />
            </div>
            <div className="row">
                <GenerateButtonContainer className="text-center">
                    <GenerateButton color="#0080ff" onClick={handleSend}>
                        Übernehmen
                    </GenerateButton>
                </GenerateButtonContainer>
            </div>
        </ResultOutputContainer>
    );
};

export default ResultOutput;
