import React from 'react';
import styled from 'styled-components';

interface VitalsCardProps {
    patientName: string;
    bloodPressure: string;
    heartRate: string;
    bodyTemperature: string;
}

// Styled components
const CardContainer = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 24px;
    max-width: 500px;
    margin: 0 auto;
    transition: transform 0.3s ease-in-out;
`;

const CardTitle = styled.h2`
    font-size: 24px;
    margin-bottom: 16px;
`;

const TableContainer = styled.div`
    overflow-x: auto;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0px;
`;

const TableRow = styled.tr``;

const TableCell = styled.td`
    padding: 12px;
`;

const FormInput = styled.input`
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    background-color: #ffffff;
    color: #555555;

    &:focus {
        outline: none;
        background-color: #ffffff;
    }
`;

const SaveButton = styled.button`
    width: 100%;
    padding: 12px;
    margin-top: 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    }
`;

const vitals = [
    { title: 'Blutdruck', property: 'bloodPressure' },
    { title: 'Herzfrequenz', property: 'heartRate' },
    { title: 'Körpertemperatur', property: 'bodyTemperature' },
];

const VitalsCard: React.FC<any> = (vitalsProps) => {
    return (
        <CardContainer>
            <CardTitle>Vitalwerte - {vitalsProps.patientName}</CardTitle>
            <TableContainer>
                <Table>
                    <tbody>
                        {vitals.map((vital) => (
                            <TableRow>
                                <TableCell>
                                    <strong>{vital.title}</strong>
                                </TableCell>
                                <TableCell>
                                    <FormInput
                                        onChange={(e: any) => vitalsProps.updateVitalValues(e.target.value, vital.property)}
                                        value={vitalsProps[vital.property] ?? null}
                                        type="text"
                                        placeholder="Wert eingeben"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
            <SaveButton>Speichern</SaveButton>
        </CardContainer>
    );
};

export default VitalsCard;
