import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './NewExpenseCard.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDropzone } from 'react-dropzone';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../../hooks/useMultiLanguageFieldValue';
import fireToast from '../../../../central-ui/methods/fireToast';
import { availableLanguages } from '../../../../../../i18n';
import { useHistory } from 'react-router-dom';

type OptionType = {
    value: string;
    label: string;
};

const NewExpenseCard = () => {
    const history = useHistory();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        multiple: false,
    });
    const { languageKey } = useTypedSelector((state) => state.benitCentralConfigurationReducer);

    const { t } = useTranslation(['general', 'benit-accounting']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_accounting_upload_expense_document, benit_accounting_reset_expense_creation_state } = useActions();

    const { newExpenseCreationPending, createdExpenseId, nextExpenseId } = useTypedSelector((state) => state.benitAccountingReducer);

    useEffect(() => {
        if (createdExpenseId) {
            history.push(`/benit/accounting/expenses/${createdExpenseId}`);
            fireToast('success', t('benit-accounting:expense_created'));
            benit_accounting_reset_expense_creation_state();
        }
    }, [createdExpenseId]);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            benit_accounting_upload_expense_document(acceptedFiles, null, true);
        }
    }, [acceptedFiles]);

    return (
        <div className="card card-success mb-1 collapsed-card">
            <div className="btn btn-lg btn-outline-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                {t('benit-accounting:expense_creation')}
            </div>
            <div className="card-body box-profile">
                <div className="row">
                    {newExpenseCreationPending ? (
                        <div className={styles.loader} />
                    ) : (
                        <section className="container">
                            <div {...getRootProps({ className: styles.dropzone })}>
                                <input {...getInputProps()} />
                                <p>{t('benit-accounting:document_upload')}</p>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NewExpenseCard;
