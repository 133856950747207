import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import fireToast from '../../../../central-ui/methods/fireToast';
import SingleTopicRiskMatrix from './SingleTopicRiskMatrix';

interface SISCategoryProps {
    topic: string;
    text: string;
    riskMatrixData: any;
}

const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const Header = styled.h2<{ textColor: string; color: string }>`
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.color};
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    border-radius: 8px 8px 0 0;
    font-size: 14px;
    padding: 8px;
`;

const BodyText = styled.p`
    font-size: 14px;
    padding: 8px;
    text-align: left;
`;

const SISCategory: React.FC<SISCategoryProps> = ({ topic, text, riskMatrixData}) => {
    const [inputText, setInputText] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleInputChange = (event: any) => {
        setInputText(event.target.value);
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [inputText]);

    // iterate over all objects in riskMatrixData, and concatenate the property explanation to the text
    useEffect(() => {
        // if riskMatrixData is an empty json or null, just set the inputText to the text
        let riskKeys = Object.keys(riskMatrixData);
        if (!riskMatrixData || riskKeys.length === 0) {
            setInputText(text);
            return;
        }

        let explainationText = 'Risiken:\n\n';
        riskKeys.forEach((risk) => {
            explainationText += riskMatrixData[risk].explanation + '\n\n';
        });

        setInputText(text + '\n\n' + explainationText);
    }, [riskMatrixData, text]);

    const copyToClipboard = (inputValue: any) => {
        const el = document.createElement('textarea');
        el.value = inputValue;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const topicTextColors = {
        topic0: '#FFFFFF',
        topic1: '#000000',
        topic2: '#000000',
        topic3: '#000000',
        topic4: '#000000',
        topic5: '#000000',
        topic6: '#000000',
      };

      const topicColors = {
        topic0: '#CA2B1F',
        topic1: '#F7714C',
        topic2: '#FBC100',
        topic3: '#80D96F',
        topic4: '#9E54AD',
        topic5: '#79B7DE',
        topic6: '#FA687B',
      };

      const topicTranslations = {
        topic0: 'Was bewegt Sie im Augenblick? Was brauchen Sie? Was können wir für Sie tun?',
        topic1: 'Themenfeld 1 - Kognitive und kommunikative Fähigkeiten',
        topic2: 'Themenfeld 2 - Mobilität und Bewegung',
        topic3: 'Themenfeld 3 - Krankheitsbezogene Anforderungen und Belastungen',
        topic4: 'Themenfeld 4 - Selbstversorgung',
        topic5: 'Themenfeld 5 - Leben in sozialen Beziehungen',
        topic6: 'Themenfeld 6 - Haushaltsführung',
      };

    const translatedTopic = topicTranslations[topic as keyof typeof topicTranslations];
    const topicColor = topicColors[topic as keyof typeof topicColors];
    const topicTextColor = topicTextColors[topic as keyof typeof topicTextColors];
    return (
        <CategoryContainer>
            <Header textColor={topicTextColor} color={topicColor}>
                <div className="row">
                    <div className="col-10">{translatedTopic}</div>
                    <div className="col-2 text-right align-middle" style={{ color: inputText.length > 1024 ? 'red' : topicTextColor }}>
                        {inputText.length}/{1024}
                    </div>
                </div>
            </Header>
            {/* <BodyText>{bodyText}</BodyText> */}

            <div style={{ display: 'flex' }}>
                <textarea
                    ref={textareaRef}
                    value={inputText}
                    disabled={true}
                    style={{ flexGrow: 1, flex: 1, fontSize: '14px', padding: '8px', border: 'none', borderRadius: '4px', outline: 'none', resize: 'none', maxHeight: '100%' }}
                    placeholder="Text eingeben..."
                />
            </div>
            <button
                className="btn btn-sm btn-outline-secondary ml-2 mr-2 mb-2"
                onClick={() => {
                    copyToClipboard(inputText);
                    fireToast('info', `Text in den Zwischenspeicher kopiert`);
                }}
            >
                Text kopieren
            </button>
            <SingleTopicRiskMatrix data={riskMatrixData} topic={topic} />
        </CategoryContainer>
    );
};

export default SISCategory;
