import Avatar from 'react-avatar';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { availableLanguages } from '../../../../../i18n';

const OrganizationApp = () => {
    const [newOrganizationName, setNewOrganizationName] = useState('');
    const [newOrganizationIdentifier, setNewOrganizationIdentifier] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserFirstName, setNewUserFirstName] = useState('');
    const [newUserLastName, setNewUserLastName] = useState('');
    const [newOrganizationIndustry, setNewOrganizationIndustry] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [editOrganizationId, setEditOrganizationId] = useState('');
    const [editOrganizationName, setEditOrganizationName] = useState('');
    const [editOrganizationIdentifier, setEditOrganizationIdentifier] = useState('');
    const [editOrganizationIndustry, setEditOrganizationIndustry] = useState({});
    const { t } = useTranslation(['general', 'benit-central-configuration']);
    const { mlfv } = useMultiLanguageFieldValue();

    const {
        benit_central_configuration_get_organizations,
        benit_central_configuration_create_organization,
        benit_central_configuration_update_organization,
        benit_central_configuration_delete_organization,
        benit_central_configuration_check_user_email,
    } = useActions();

    const { organizations, organizationsLoading, organizationsDidInvalidate, newOrganizationAddedSuccessfully, updatedOrganizationSuccessfully, newUserEmailExists } = useTypedSelector(
        (state) => state.benitCentralConfigurationReducer
    );

    const addOrganization = () => {
        benit_central_configuration_create_organization(newOrganizationName, newOrganizationIdentifier, newOrganizationIndustry, newUserEmail, newUserFirstName, newUserLastName);
    };

    useEffect(() => {
        if (newOrganizationAddedSuccessfully && newOrganizationName !== '') {
            fireToast('success', `${newOrganizationName} ${t('general:added')}`);
            setNewOrganizationName('');
            setNewOrganizationIndustry({});
            setNewUserEmail('');
            setNewUserFirstName('');
            setNewUserLastName('');
        }
    }, [newOrganizationAddedSuccessfully]);

    const editOrganization = (organizationId: string, organizationName: string, organizationIndustry: {}) => {
        setEditOrganizationId(organizationId);
        setEditOrganizationName(organizationName);
        setEditOrganizationIndustry(organizationIndustry);
    };

    const saveEditedOrganization = () => {
        benit_central_configuration_update_organization(editOrganizationId, editOrganizationName, editOrganizationIdentifier, editOrganizationIndustry);
    };

    const cancelEditedOrganization = () => {
        setEditOrganizationId('');
        setEditOrganizationName('');
        setEditOrganizationIndustry({});
    };

    useEffect(() => {
        if (updatedOrganizationSuccessfully && editOrganizationName !== '') {
            fireToast('success', `${editOrganizationName} ${t('general:updated')}`);
            setEditOrganizationId('');
            setEditOrganizationName('');
            setEditOrganizationIndustry({});
        }
    }, [updatedOrganizationSuccessfully]);

    const deleteOrganization = (organisationId: string, organizationName: string) => {
        showConfirmPopup({
            title: t('general:are_you_sure'),
            text: t('general:no_revert'),
            confirmButtonText: t('general:delete'),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: () => {
                benit_central_configuration_delete_organization(organisationId);
                fireToast('success', `${organizationName} ${t('general:deleted')}`);
            },
        });
    };

    useEffect(() => {
        benit_central_configuration_get_organizations();
    }, []);

    useEffect(() => {
        if (organizationsDidInvalidate) {
            benit_central_configuration_get_organizations(searchTerm);
        }
    }, [organizationsDidInvalidate]);

    useEffect(() => {
        benit_central_configuration_get_organizations(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        if (newUserEmail) {
            benit_central_configuration_check_user_email(newUserEmail);
        }
    }, [newUserEmail]);

    return (
        <div>
            <div className="row">
                <div className="col-md-4 offset-md-4">
                    <div className="card card-success mb-1 collapsed-card">
                        <div className="btn btn-lg btn-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                            {t('benit-central-configuration:add_organization')}
                        </div>
                        <div className="card-body box-profile">
                            <div className="text-center">
                                <Avatar name={newOrganizationName} size="128" round=".3rem" color="green" />
                            </div>
                            <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('benit-central-configuration:organization')}
                                    value={newOrganizationName}
                                    onChange={(e) => setNewOrganizationName(e.target.value)}
                                />
                            </div>
                            <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('benit-central-configuration:identifier')}
                                    value={newOrganizationIdentifier}
                                    onChange={(e) => setNewOrganizationIdentifier(e.target.value)}
                                />
                            </div>

                            <MultiLanguageInput
                                type="text"
                                className="mt-2 col-md-10 offset-md-1 mb-3"
                                placeholderLanguageNamespace="benit-central-configuration"
                                placeholderLanguageKey="industry"
                                stateValue={newOrganizationIndustry}
                                stateFunction={setNewOrganizationIndustry}
                            />

                            <div className="mt-2 col-md-10 offset-md-1 input-group mb-3 border-bottom"></div>

                            <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                <input
                                    type="email"
                                    className={`form-control control-border ${newUserEmailExists ? 'is-valid' : ''}`}
                                    placeholder={t('benit-central-configuration:administrator_email')}
                                    value={newUserEmail}
                                    onChange={(e) => setNewUserEmail(e.target.value)}
                                />
                                {newUserEmailExists ? <span className="valid-feedback">{t('benit-central-configuration:user_already_exists')}</span> : null}
                            </div>

                            {newUserEmailExists ? null : (
                                <div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control control-border"
                                            placeholder={t('benit-central-configuration:administrator_first_name')}
                                            value={newUserFirstName}
                                            onChange={(e) => setNewUserFirstName(e.target.value)}
                                        />
                                    </div>

                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control control-border"
                                            placeholder={t('benit-central-configuration:administrator_last_name')}
                                            value={newUserLastName}
                                            onChange={(e) => setNewUserLastName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            )}

                            <button className="btn btn-primary btn-block" onClick={addOrganization}>
                                <b>{t('general:save')}</b>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 p-1">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            placeholder={t('benit-central-configuration:search_organization')}
                        />
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                {organizations ? (
                    organizations.map((organization) => {
                        return (
                            <div className="col-md-3 mt-2" key={organization.id}>
                                <div className="card card-primary card-outline h-100 ">
                                    <div className="card-body box-profile">
                                        {editOrganizationId !== organization.id ? (
                                            <div>
                                                <div className="text-center">
                                                    <Avatar name={organization.name} round=".3rem" size="128" color="grey" />
                                                </div>
                                                <h3 className="profile-username text-center">{organization.name}</h3>
                                                <p className="text-muted text-center">{mlfv(organization.industry)}</p>
                                                {editOrganizationId === '' ? (
                                                    <div>
                                                        <button className="btn btn-primary btn-block" onClick={(e) => editOrganization(organization.id, organization.name, organization.industry)}>
                                                            <b>{t('general:edit')}</b>
                                                        </button>

                                                        <button className="btn btn-danger btn-block" onClick={(e) => deleteOrganization(organization.id, organization.name)}>
                                                            <b>{t('general:delete')}</b>
                                                        </button>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="text-center">
                                                    <Avatar name={editOrganizationName} round=".3rem" size="128" color="grey" />
                                                </div>
                                                <div className="mt-2 input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t('benit-central-configuration:organization')}
                                                        value={editOrganizationName}
                                                        onChange={(e) => setEditOrganizationName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="mt-2 input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t('benit-central-configuration:identifier')}
                                                        value={editOrganizationName}
                                                        onChange={(e) => setEditOrganizationIdentifier(e.target.value)}
                                                    />
                                                </div>

                                                <MultiLanguageInput
                                                    type="text"
                                                    className="mt-2 mb-3"
                                                    placeholderLanguageNamespace="benit-central-configuration"
                                                    placeholderLanguageKey="industry"
                                                    stateValue={editOrganizationIndustry}
                                                    stateFunction={setEditOrganizationIndustry}
                                                />
                                                <button className="btn btn-primary btn-block" onClick={(e) => saveEditedOrganization()}>
                                                    <b>{t('general:save')}</b>
                                                </button>
                                                <button className="btn btn-secondary btn-block" onClick={(e) => cancelEditedOrganization()}>
                                                    <b>{t('general:cancel')}</b>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="offset-md-3 col-md-6 mt-2 p-1 alert alert-info">
                        <h5>
                            <i className="icon fas fa-info"></i>{' '}
                            {searchTerm.length > 0 ? t('benit-central-configuration:no_organizations_found') : t('benit-central-configuration:no_organizations_yet')}
                            {searchTerm}
                        </h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrganizationApp;
