import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './UserEigentuemerApp.module.css';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';

const AccountingConfigurationApp = () => {
    const [invoiceConfig, setInvoiceConfig] = useState<{ [x: string]: string }>({
        companyName: '',
        street: '',
        postcode: '',
        city: '',
        telephone: '',
        eMail: '',
        bank: '',
        IBAN: '',
        BIC: '',
        companyOwner: '',
        taxNumber: '',
        vatNumber: '',
        hrbNumber: '',
    });
    const [numberingConfig, setNumberingConfig] = useState<{ [x: string]: string }>({
        invoiceNumberingPattern: '',
        invoiceStartNumber: '',
    });
    const [logoConfig, setLogoConfig] = useState<any>({
        logo: null,
    });
    const [showInvoiceIdPatternHelp, setShowInvoiceIdPatternHelp] = useState(false);

    const { t } = useTranslation(['general', 'benit-accounting']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { benit_accounting_get_configuration, benit_accounting_change_configuration } = useActions();

    const { configurationDidInvalidate, configurationUpdated, configurations } = useTypedSelector((state) => state.benitAccountingReducer);

    useEffect(() => {
        benit_accounting_get_configuration();
    }, []);

    useEffect(() => {
        if (configurationDidInvalidate) {
            benit_accounting_get_configuration();
        }
    }, [configurationDidInvalidate]);

    useEffect(() => {
        if (configurationUpdated) {
            fireToast('success', `Konfiguration gespeichert`);
        }
    }, [configurationUpdated]);

    useEffect(() => {
        if (configurations) {
            configurations.map((configuration) => {
                if (configuration.configIdentifier === 'invoiceConfig') {
                    setInvoiceConfig(configuration.properties);
                }
                if (configuration.configIdentifier === 'numberingConfig') {
                    setNumberingConfig(configuration.properties);
                }
                if (configuration.configIdentifier === 'logoConfig') {
                    setLogoConfig(configuration.properties);
                }
            });
        }
    }, [configurations]);

    const handleFileRead = async (event: any) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        console.log(base64);
        setLogoConfig({
            ...logoConfig,
            logo: base64,
        });
    };

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return (
        <div>
            <div className="row">
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                {t('benit-accounting:invoice_fields')}
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>{t('benit-accounting:company_name')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.companyName}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            companyName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:street')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.street}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            street: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:postcode')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.postcode}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            postcode: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:city')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.city}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            city: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:telephone')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.telephone}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            telephone: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:e_mail')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.eMail}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            eMail: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:bank')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.bank}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            bank: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:iban')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.IBAN}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            IBAN: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:bic')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.BIC}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            BIC: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:company_owner')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.companyOwner}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            companyOwner: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:tax_number')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.taxNumber}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            taxNumber: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:vat_number')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.vatNumber}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            vatNumber: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('benit-accounting:hrb_number')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={invoiceConfig.hrbNumber}
                                    onChange={(e) =>
                                        setInvoiceConfig({
                                            ...invoiceConfig,
                                            hrbNumber: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-outline-primary" onClick={(e) => benit_accounting_change_configuration('invoiceConfig', invoiceConfig)}>
                                    {t('benit-accounting:save_configuration')}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                {t('benit-accounting:object_numbering_settings')}
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>{t('benit-accounting:invoice_numbering_pattern')}</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={numberingConfig.invoiceNumberingPattern}
                                        onChange={(e) =>
                                            setNumberingConfig({
                                                ...numberingConfig,
                                                invoiceNumberingPattern: e.target.value,
                                            })
                                        }
                                    />
                                    <div className="input-group-append">
                                        <button
                                            type="button"
                                            className={`btn ${showInvoiceIdPatternHelp ? 'btn-primary' : 'btn-default'}`}
                                            onClick={(e) => setShowInvoiceIdPatternHelp(!showInvoiceIdPatternHelp)}
                                        >
                                            <i className="fa fa-info"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {showInvoiceIdPatternHelp ? (
                                <div className="border rounded mb-2 bg-light">
                                    <div className="p-2">
                                        {t('benit-accounting:pattern_help_yyyy')}
                                        <br />
                                        {t('benit-accounting:pattern_help_yy')}
                                        <br />
                                        {t('benit-accounting:pattern_help_mm')}
                                        <br />
                                        {t('benit-accounting:pattern_help_number')}
                                        <br />
                                        {t('benit-accounting:pattern_help_strings')}
                                        <br />
                                    </div>
                                </div>
                            ) : null}
                            <div className="form-group">
                                <label>{t('benit-accounting:invoice_start_number')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={numberingConfig.invoiceStartNumber}
                                    onChange={(e) =>
                                        setNumberingConfig({
                                            ...numberingConfig,
                                            invoiceStartNumber: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-outline-primary" onClick={(e) => benit_accounting_change_configuration('numberingConfig', numberingConfig)}>
                                    {t('benit-accounting:save_configuration')}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6 col-xl-3 connectedSortable">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-tools mr-1"></i>
                                {t('benit-accounting:company_logo_settings')}
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>{t('benit-accounting:company_logo')}</label>
                                {!logoConfig.logo ? (
                                    <input id="originalFileName" type="file" accept="image/png, image/jpeg" onChange={(e) => handleFileRead(e)} />
                                ) : (
                                    <>
                                        <div className="input-group">
                                            <img src={logoConfig.logo} alt="logo" width="100" height="auto" />
                                            <button
                                                className="btn btn-block btn-outline-danger mt-3"
                                                onClick={(e) =>
                                                    setLogoConfig({
                                                        ...logoConfig,
                                                        logo: null,
                                                    })
                                                }
                                            >
                                                {t('benit-accounting:replace_logo')}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="input-group">
                                <button className="btn btn-block btn-outline-primary" onClick={(e) => benit_accounting_change_configuration('logoConfig', logoConfig)}>
                                    {t('benit-accounting:save_configuration')}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AccountingConfigurationApp;
