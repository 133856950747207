import Swal, { SweetAlertIcon, SweetAlertInput } from 'sweetalert2';

interface showConfirmPopupInterface {
    title: string;
    text: string;
    confirmButtonText: string;
    cancelButtonText?: string;
    confirmedFunction: any;
    showCancelButton?: boolean;
    confirmButtonColor?: string;
    cancelButtonColor?: string;
    allowOutsideClick?: boolean;
    input?: SweetAlertInput | null;
    inputLabel?: string | null;
    inputPlaceholder?: string | null;
    inputValue?: string | null;
}

const showConfirmPopup = ({
    title,
    text,
    confirmButtonText,
    cancelButtonText = '',
    showCancelButton = true,
    confirmButtonColor = '#dc3545',
    cancelButtonColor = '#6c757d',
    allowOutsideClick = true,
    confirmedFunction,
    input = null,
    inputLabel = null,
    inputPlaceholder = null,
    inputValue = null,
}: showConfirmPopupInterface) => {
    if (input && inputLabel && inputPlaceholder && inputValue) {
        Swal.fire({
            title,
            text,
            input,
            inputLabel,
            inputPlaceholder,
            inputValue,
            showCancelButton,
            confirmButtonColor,
            cancelButtonColor,
            confirmButtonText,
            cancelButtonText,
            allowOutsideClick,
        }).then((result) => {
            if (result.isConfirmed) {
                confirmedFunction(result.value);
            }
        });
    } else if (input && inputLabel && inputPlaceholder) {
        Swal.fire({
            title,
            text,
            input,
            inputLabel,
            inputPlaceholder,
            showCancelButton,
            confirmButtonColor,
            cancelButtonColor,
            confirmButtonText,
            cancelButtonText,
            allowOutsideClick,
        }).then((result) => {
            if (result.isConfirmed) {
                confirmedFunction(result.value);
            }
        });
    } else {
        Swal.fire({
            title,
            text,
            showCancelButton,
            confirmButtonColor,
            cancelButtonColor,
            confirmButtonText,
            cancelButtonText,
            allowOutsideClick,
        }).then((result) => {
            if (result.isConfirmed) {
                confirmedFunction();
            }
        });
    }
};

export default showConfirmPopup;
