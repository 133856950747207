import Avatar from 'react-avatar';
import Select from 'react-select';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import showConfirmPopup from '../../../../benit/central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { Link, useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import styles from './RIRealEstateHomepageApp.module.css';

const RIRealEstateHomepageApp = () => {
    const history = useHistory();
    const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone();

    const [newObject, setNewObject] = useState<{ [x: string]: any }>({
        title: '',
        objectId: '',
        realEstateType: 'realestates.houseBuy',
        realEstateType_text: 'Hausverkauf',
    });
    const { t } = useTranslation(['general', 'ri-property-management']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { ri_real_estate_get_objekte, ri_real_estate_create_objekt } = useActions();

    const { objekte, objektCreated } = useTypedSelector((state) => state.riRealEstateReducer);

    useEffect(() => {
        ri_real_estate_get_objekte();
    }, []);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            console.log(inputRef.current ? inputRef.current.id : '');
        }
    }, [acceptedFiles]);

    const currency = (number: number) => {
        return new Intl.NumberFormat('de-de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    return (
        <>
            <div className="row mb-4 mt-4">
                <div className="col-12 text-center">
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                        <label className="btn btn-outline-primary active">
                            <input type="radio" name="options" id="option_a1" autoComplete="off" /> Startseite
                        </label>
                        <label className="btn btn-outline-primary">
                            <input type="radio" name="options" id="option_a2" autoComplete="off" /> Immobilie kaufen
                        </label>
                        <label className="btn btn-outline-primary">
                            <input type="radio" name="options" id="option_a3" autoComplete="off" /> Immobilie verkaufen
                        </label>
                        <label className="btn btn-outline-primary">
                            <input type="radio" name="options" id="option_a3" autoComplete="off" /> Immobilienverwaltung
                        </label>
                        <label className="btn btn-outline-primary">
                            <input type="radio" name="options" id="option_a3" autoComplete="off" /> Über uns
                        </label>
                    </div>
                </div>
            </div>
            <div className="row pb-4">
                <div className="col-12">
                    <div className="card card-draggable">
                        <div className="card-header">
                            <h3 className="card-title align-middle" style={{ marginTop: '3px' }}>
                                Bilderkarusell mit Navigation - ganzer Bildschirm
                            </h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" onClick={(e) => history.push(`/ri/real-estate/objects/`)}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row border-bottom text-center pb-2 pt-2">
                                <div className="col-xl-2">
                                    <section className="container">
                                        <div {...getRootProps({ className: styles.dropzone })}>
                                            <input id="grossesHintergrundbild" {...getInputProps()} />
                                            <p>Großes Hintergrundbild</p>
                                        </div>
                                    </section>
                                </div>
                                <div className="col-xl-2">
                                    <section className="container">
                                        <div {...getRootProps({ className: styles.dropzone })}>
                                            <input id="kleinesAvatarbild" {...getInputProps()} />
                                            <p>Kleines Avatarbild</p>
                                        </div>
                                    </section>
                                </div>
                                <div className="col-xl-2 ">
                                    <input type="text" className={`form-control control-border d-block`} placeholder="Überschrift" value={newObject.title} onChange={(e) => null} />
                                </div>
                                <div className="col-xl-4">
                                    <input type="text" className={`form-control control-border d-block`} placeholder="Text" value={newObject.title} onChange={(e) => null} />
                                </div>
                                <div className="col-xl-2">
                                    <input type="text" className={`form-control control-border d-block`} placeholder="Navigation zu" value={newObject.title} onChange={(e) => null} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pb-4">
                <div className="offset-4 col-4 text-center">
                    <button type="button" className="btn btn-block btn-primary">
                        Speichern
                    </button>
                </div>
            </div>
        </>
    );
};

export default RIRealEstateHomepageApp;
