import axios from 'axios';
import FileDownload from 'js-file-download';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const ri_property_management_get_analytics = (prompt: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/ri/property-management/analytics`,
            method: 'post',
            body: { prompt },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_GET_ANALYTICS_FAIL,
                });
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};
