import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import styles from './ReportApp.module.css';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';

const ReportApp = () => {
    const [selectedEigentuemer, setSelectedEigentuemer] = useState('0');
    const [startJahr, setStartJahr] = useState<number | null>(null);
    const [endJahr, setEndJahr] = useState<number | null>(null);
    const [selectedJahr, setSelectedJahr] = useState(new Date().getFullYear());

    const { t } = useTranslation(['general', 'benit-central-configuration', 'ri-property-management']);

    const { ri_property_management_get_jahresauswertung, ri_property_management_get_current_user_eigentuemer } = useActions();

    const { currentUserEigentuemer, jahresauswertungPending, jahresauswertung, kontostand } = useTypedSelector((state) => state.riPropertyManagementReducer);

    useEffect(() => {
        ri_property_management_get_current_user_eigentuemer();
    }, []);

    useEffect(() => {
        if (currentUserEigentuemer && currentUserEigentuemer.length === 1) {
            changeEigentuemer(currentUserEigentuemer[0].eid);
        }
    }, [currentUserEigentuemer]);

    const changeEigentuemer = (eid: string) => {
        if (currentUserEigentuemer) {
            for (const eigentuemer of currentUserEigentuemer) {
                if (eigentuemer.eid == eid) {
                    setStartJahr(eigentuemer.buchungen_start_jahr);
                    setEndJahr(eigentuemer.buchungen_end_jahr);
                    setSelectedJahr(eigentuemer.buchungen_end_jahr ? eigentuemer.buchungen_end_jahr : new Date().getFullYear());
                    break;
                }
            }
            setSelectedEigentuemer(eid);
        }
    };

    useEffect(() => {
        if (parseInt(selectedEigentuemer) !== 0 && selectedJahr) {
            ri_property_management_get_jahresauswertung(selectedEigentuemer, selectedJahr);
        }
    }, [selectedEigentuemer, selectedJahr]);

    const currency = (number: number) => {
        return new Intl.NumberFormat('de-de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    const navigateToTransactionPage = (params: { eid: string; from?: string | null; to?: string | null; moid?: string | null; mid?: string | null; kaid?: string | null }) => {
        const { eid, from, to, moid, mid, kaid } = params;
        const base_url = window.location.origin;
        const pathArray = window.location.pathname.split('/');
        if (window) {
            const openWindow = window.open(
                `${base_url}/${pathArray[1]}/${pathArray[2]}/transactions?eid=${eid}${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}${moid ? `&moid=${moid}` : ''}${mid ? `&mid=${mid}` : ''}${
                    kaid ? `&kaid=${kaid}` : ''
                }`,
                '_blank'
            );

            if (openWindow) {
                openWindow.focus();
            }
        }
    };

    const jahresOptionen = (startJahrInput: number, endJahrInput: number) => {
        let jahre: number[] = [];
        for (let j = startJahrInput; j <= endJahrInput; j++) {
            jahre.push(j);
        }
        jahre.reverse();
        return (
            <>
                {jahre.map((jahr, index) => {
                    return (
                        <option value={`${jahr}`} selected={index === 0}>
                            {jahr}
                        </option>
                    );
                })}
            </>
        );
    };

    return (
        <div>
            {currentUserEigentuemer && currentUserEigentuemer.length > 1 ? (
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="form-group">
                            <select className="form-control" onChange={(e) => changeEigentuemer(e.target.value)}>
                                {selectedEigentuemer === '0' ? <option value="0">{t('ri-property-management:select_owner')}</option> : null}
                                {currentUserEigentuemer
                                    ? currentUserEigentuemer.map((e) => {
                                          if (selectedEigentuemer === '0') {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          } else if (selectedEigentuemer === e.eid) {
                                              return (
                                                  <option value={e.eid} selected>
                                                      {e.nachname}
                                                  </option>
                                              );
                                          } else if (selectedEigentuemer !== e.eid) {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          }
                                      })
                                    : null}
                            </select>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="row">
                {selectedEigentuemer !== '0' ? (
                    <div className="col-md-4 mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-success" type="button" disabled>
                                    Kontostand
                                </button>
                            </div>
                            <input type="text" className={`form-control text-left`} placeholder="" value={currency(kontostand ? kontostand : 0)} disabled />
                        </div>
                    </div>
                ) : null}
                {startJahr && endJahr ? (
                    <div className="col-md-4">
                        <div className="form-group">
                            <select className="form-control" onChange={(e) => setSelectedJahr(parseInt(e.target.value))}>
                                {jahresOptionen(startJahr, endJahr)}
                            </select>
                        </div>
                    </div>
                ) : null}
            </div>
            {selectedEigentuemer !== '0' ? (
                jahresauswertungPending ? (
                    <LoadingAnimation />
                ) : jahresauswertung ? (
                    <div>
                        <div className="table-responsive">
                            <table className="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ minWidth: '240px', borderTop: 'none' }}>
                                            {jahresauswertung.eigentuemer.nachname} - {jahresauswertung.jahr}
                                        </th>
                                        {jahresauswertung.summe_monate.map((monat, index) => (
                                            <th scope="col" className="text-right" style={{ minWidth: '130px', borderTop: 'none' }}>
                                                {`${new Date(jahresauswertung.jahr, index).toLocaleString('de-de', { month: 'long' })} ${jahresauswertung.jahr}`}
                                            </th>
                                        ))}
                                        <th scope="col" className="text-right" style={{ minWidth: '130px', borderTop: 'none' }}>
                                            Gesamtergebnis
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jahresauswertung.mietobjekte.map((mietobjekt) => (
                                        <>
                                            <tr className="table-primary">
                                                <th scope="row">
                                                    {mietobjekt.mietobjekt.strasse} {mietobjekt.mietobjekt.hausnummer}
                                                </th>
                                                {mietobjekt.summe_monate.map((summe, index) => (
                                                    <td
                                                        className="text-right align-middle"
                                                        style={{ cursor: `${summe === 0 ? 'auto' : 'pointer'}` }}
                                                        onClick={(e) =>
                                                            summe === 0
                                                                ? null
                                                                : navigateToTransactionPage({
                                                                      eid: jahresauswertung.eigentuemer.eid.toString(),
                                                                      moid: mietobjekt.mietobjekt.moid.toString(),
                                                                      from: `01.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                      to: `${moment(`01.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`, 'DD.MM.YYYY', 'de').daysInMonth()}.${(
                                                                          index + 1
                                                                      )
                                                                          .toString()
                                                                          .padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                  })
                                                        }
                                                    >
                                                        {summe === 0 ? null : currency(summe)}
                                                    </td>
                                                ))}
                                                <th
                                                    scope="row"
                                                    className="text-right align-middle"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e) =>
                                                        navigateToTransactionPage({
                                                            eid: jahresauswertung.eigentuemer.eid.toString(),
                                                            moid: mietobjekt.mietobjekt.moid.toString(),
                                                            from: `01.01.${jahresauswertung.jahr}`,
                                                            to: `31.12.${jahresauswertung.jahr}`,
                                                        })
                                                    }
                                                >
                                                    {currency(mietobjekt.summe_total)}
                                                </th>
                                            </tr>
                                            {mietobjekt.mieter.map((mieter) => (
                                                <>
                                                    <tr>
                                                        <th scope="row" style={{ textIndent: '12px' }}>
                                                            {mieter.mieter ? `${mieter.mieter.vorname} ${mieter.mieter.nachname}` : 'Objektbezogen'}
                                                        </th>
                                                        {mieter.summe_monate.map((summe, index) => (
                                                            <td
                                                                className="text-right align-middle"
                                                                style={{ cursor: `${summe === 0 ? 'auto' : 'pointer'}` }}
                                                                onClick={(e) =>
                                                                    summe === 0
                                                                        ? null
                                                                        : navigateToTransactionPage({
                                                                              eid: jahresauswertung.eigentuemer.eid.toString(),
                                                                              moid: mietobjekt.mietobjekt.moid.toString(),
                                                                              mid: mieter.mieter ? mieter.mieter.mid.toString() : '0',
                                                                              from: `01.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                              to: `${moment(
                                                                                  `01.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                                  'DD.MM.YYYY',
                                                                                  'de'
                                                                              ).daysInMonth()}.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                          })
                                                                }
                                                            >
                                                                {summe === 0 ? null : currency(summe)}
                                                            </td>
                                                        ))}
                                                        <th
                                                            scope="row"
                                                            className="text-right table-success align-middle"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={(e) =>
                                                                navigateToTransactionPage({
                                                                    eid: jahresauswertung.eigentuemer.eid.toString(),
                                                                    moid: mietobjekt.mietobjekt.moid.toString(),
                                                                    mid: mieter.mieter ? mieter.mieter.mid.toString() : '0',
                                                                    from: `01.01.${jahresauswertung.jahr}`,
                                                                    to: `31.12.${jahresauswertung.jahr}`,
                                                                })
                                                            }
                                                        >
                                                            {currency(mieter.summe_total)}
                                                        </th>
                                                    </tr>
                                                    {mieter.kostenarten.map((kostenart) => (
                                                        <tr>
                                                            <td style={{ textIndent: '22px' }}>{kostenart.kostenart.langname}</td>
                                                            {kostenart.summe_monate.map((summe, index) => (
                                                                <td
                                                                    className="text-right align-middle"
                                                                    style={{ cursor: `${summe === 0 ? 'auto' : 'pointer'}` }}
                                                                    onClick={(e) =>
                                                                        summe === 0
                                                                            ? null
                                                                            : navigateToTransactionPage({
                                                                                  eid: jahresauswertung.eigentuemer.eid.toString(),
                                                                                  moid: mietobjekt.mietobjekt.moid.toString(),
                                                                                  mid: mieter.mieter ? mieter.mieter.mid.toString() : '0',
                                                                                  kaid: kostenart.kostenart.kaid.toString(),
                                                                                  from: `01.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                                  to: `${moment(
                                                                                      `01.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                                      'DD.MM.YYYY',
                                                                                      'de'
                                                                                  ).daysInMonth()}.${(index + 1).toString().padStart(2, '0')}.${jahresauswertung.jahr}`,
                                                                              })
                                                                    }
                                                                >
                                                                    {summe === 0 ? null : currency(summe)}
                                                                </td>
                                                            ))}
                                                            <th
                                                                scope="row"
                                                                className="text-right table-success align-middle"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={(e) =>
                                                                    navigateToTransactionPage({
                                                                        eid: jahresauswertung.eigentuemer.eid.toString(),
                                                                        moid: mietobjekt.mietobjekt.moid.toString(),
                                                                        mid: mieter.mieter ? mieter.mieter.mid.toString() : '0',
                                                                        kaid: kostenart.kostenart.kaid.toString(),
                                                                        from: `01.01.${jahresauswertung.jahr}`,
                                                                        to: `31.12.${jahresauswertung.jahr}`,
                                                                    })
                                                                }
                                                            >
                                                                {currency(kostenart.summe_total)}
                                                            </th>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))}
                                        </>
                                    ))}
                                    <tr className="table-success">
                                        <th scope="row">Gesamtergebnis</th>
                                        {jahresauswertung.summe_monate.map((summe) => (
                                            <td className="text-right align-middle">{summe === 0 ? null : currency(summe)}</td>
                                        ))}
                                        <th scope="row" className="text-right align-middle">
                                            {currency(jahresauswertung.summe_total)}
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : null
            ) : null}
        </div>
    );
};

export default ReportApp;
