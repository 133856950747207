import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
//@ts-ignore
import JSONPretty from 'react-json-pretty';
import DataTable from 'react-data-table-component';
import { Chart } from 'react-google-charts';
import { Dna } from 'react-loader-spinner';

const AnalyticsApp = () => {
    const { t } = useTranslation(['general', 'benit-central-configuration', 'ri-property-management']);
    //useState for input prompt text
    const [inputPrompt, setInputPrompt] = useState<string>('');

    const { ri_property_management_get_analytics } = useActions();

    const { analyticsResponse, analyticsResponsePending } = useTypedSelector((state) => state.riPropertyManagementReducer);

    function capitalizeFirstLetter(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 mt-2">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                                setInputPrompt(e.target.value);
                            }}
                            placeholder="Analyse"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 offset-md-3 mt-3">
                    <button className="btn btn-primary btn-block" onClick={(e) => ri_property_management_get_analytics(inputPrompt)}>
                        <b>Generieren</b>
                    </button>
                </div>
            </div>

            {analyticsResponsePending ? (
                <div className="row">
                    <div className="col-md-6 offset-md-3 mt-3 text-center">
                        <Dna visible={true} height="300" width="420" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />{' '}
                    </div>
                </div>
            ) : null}

            {!analyticsResponsePending && analyticsResponse ? (
                <>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 mt-3">
                            {/* <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={[
                            ['Year', 'Sales', 'Expenses', 'Profit'],
                            ['2014', 1000, 400, 200],
                            ['2015', 1170, 460, 250],
                            ['2016', 660, 1120, 300],
                            ['2017', 1030, 540, 350],
                        ]}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                            },
                        }}
                    /> */}
                            {analyticsResponse && analyticsResponse.chartJSOutput ? (
                                <Chart
                                    chartType="Bar"
                                    width="100%"
                                    height="400px"
                                    data={analyticsResponse ? analyticsResponse.chartJSOutput.data : []}
                                    options={{
                                        chart: {
                                            title: analyticsResponse.chartJSOutput.chartTitle ?? '',
                                            subtitle: analyticsResponse.chartJSOutput.chartSubTitle ?? '',
                                        },
                                        hAxis: {
                                            title: analyticsResponse.chartJSOutput.titleForXAxis ?? '',
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: analyticsResponse.chartJSOutput.titleForYAxis ?? '',
                                        },
                                        bars: analyticsResponse.chartJSOutput.barType ?? '',
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="row col-xl-8 offset-xl-2 mt-5">
                        <DataTable
                            columns={analyticsResponse?.result?.fields?.map((field: any) => {
                                let formatedFieldName = field.name === 'sum' ? 'Summe' : capitalizeFirstLetter(field.name);
                                return { name: capitalizeFirstLetter(field.name), selector: (row: any) => row[field.name], right: field.name === 'sum' || field.name === 'gesamt' };
                            })}
                            data={analyticsResponse?.result?.rows?.map((row: any, index: number) => {
                                for (const property in row) {
                                    if (!isNaN(row[property]) && row[property].toString().indexOf('.') !== -1) {
                                        row[property] = new Intl.NumberFormat('de-de', {
                                            style: 'currency',
                                            currency: 'EUR',
                                            minimumFractionDigits: 2,
                                        }).format(parseFloat(row[property]));
                                    }
                                }
                                return { id: index, ...row };
                            })}
                        />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <JSONPretty id="json-pretty" data={analyticsResponse}></JSONPretty>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default AnalyticsApp;
