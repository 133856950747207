import Switch from 'react-switch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { Link, useHistory } from 'react-router-dom';
import styles from './ObjectsApp.module.css';

const BenitRealEstateObjectsApp = () => {
    const history = useHistory();

    const [newObject, setNewObject] = useState<{ [x: string]: any }>({
        title: '',
        objectId: '',
        realEstateType: 'realestates.houseBuy',
        realEstateType_text: 'Hausverkauf',
        copyObjectFrom: 'new',
    });
    const [objectIdUpdatingScoutRecreation, setObjectIdUpdatingScoutRecreation] = useState<null | string>(null);
    const [objectIdPublishingType, setObjectIdPublishingType] = useState<null | string>(null);
    const [objectIdOnTopScoutPlacement, setObjectIdOnTopScoutPlacement] = useState<null | string>(null);
    const [publishingType, setPublishingType] = useState<null | string>(null);

    const { t } = useTranslation(['general', 'ri-property-management']);
    const { mlfv } = useMultiLanguageFieldValue();

    const { ri_real_estate_get_objekte, ri_real_estate_create_objekt, ri_real_estate_change_objekt } = useActions();

    const { objekte, objektCreated, objektUpdated, objektUpdating } = useTypedSelector((state) => state.riRealEstateReducer);

    useEffect(() => {}, []);

    const currency = (number: number) => {
        return new Intl.NumberFormat('de-de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    const addObject = () => {};

    return (
        <>
            {/* {!objekte ? <LoadingAnimation /> : null} */}
            <div className={``}>
                <div className="row pb-4">
                    <div className="col-md-4 offset-md-4">
                        <div className="card card-success mb-1 collapsed-card">
                            <div className="btn btn-lg btn-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                Neues Objekt anlegen
                            </div>
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <input
                                            type="text"
                                            className={`form-control control-border`}
                                            placeholder="Objekt-ID"
                                            value={newObject.objectId}
                                            onChange={(e) =>
                                                setNewObject({
                                                    ...newObject,
                                                    objectId: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <input
                                            type="text"
                                            className={`form-control control-border`}
                                            placeholder="Titel (max. 100 Zeichen)"
                                            value={newObject.title}
                                            onChange={(e) =>
                                                setNewObject({
                                                    ...newObject,
                                                    title: e.target.value,
                                                })
                                            }
                                            maxLength={100}
                                        />
                                    </div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <select
                                            className="form-control"
                                            onChange={(e) => {
                                                setNewObject({
                                                    ...newObject,
                                                    realEstateType: e.target.value,
                                                    realEstateType_text: e.target.selectedOptions[0].text,
                                                });
                                            }}
                                            value={newObject.realEstateType}
                                        >
                                            <option value="realestates.houseBuy">Hausverkauf</option>
                                            <option value="realestates.houseRent">Hausvermietung</option>
                                            <option value="realestates.apartmentBuy">Wohnungsverkauf</option>
                                            <option value="realestates.apartmentRent">Wohnungsvermietung</option>
                                            <option value="realestates.livingBuySite">Grundstückverkauf</option>
                                            <option value="realestates.office">Büro / Praxis</option>
                                            <option value="realestates.investment">Anlageimmobilie</option>
                                            <option value="realestates.garageBuy">Garagenverkauf</option>
                                            <option value="realestates.garageRent">Garagenvermietung</option>
                                            <option value="realestates.store">Einzelhandel</option>
                                            <option value="realestates.tradeSite">Gewerbegrundstück</option>
                                        </select>
                                    </div>
                                    <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                                        <select
                                            className="form-control"
                                            onChange={(e) => {
                                                setNewObject({
                                                    ...newObject,
                                                    copyObjectFrom: e.target.value,
                                                });
                                            }}
                                            value={newObject.copyObjectFrom}
                                        >
                                            <option value="new">Neues Objekt ohne Vorlage</option>
                                            {objekte
                                                ? objekte.map((objekt: any) => {
                                                      return <option value={objekt.data.externalId}>Daten von Objekt {objekt.data.externalId} übernehmen </option>;
                                                  })
                                                : null}
                                        </select>
                                    </div>
                                    <button className="btn btn-primary btn-block" onClick={addObject}>
                                        <b>Objekt anlegen</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-md-6 mt-2 offset-md-3">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">Willkommen bei benit Real Estate</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-md-3 mt-2 offset-md-3">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">Objekte von Immobilienscout importieren</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.{' '}
                                </p>
                                <button className="btn btn-block btn-primary">Objekte importieren</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mt-2">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">Portalanbindung</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.{' '}
                                </p>
                                <button className="btn btn-block btn-primary">Portale konfigurieren</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row pb-4" style={{ marginTop: '140px', marginBottom: '140px' }}>
                    <div className="col-md-4 mt-2 offset-md-4">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">Objekte von Immobilienscout importieren</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Um Objekte von Immobilienscout zu importieren, müssen Sie sich vorher bei Immobilienscout anmelden und benit Berechtigungen geben, Objekte zu synchronisieren.{' '}
                                </p>
                                <button className="btn btn-success btn-block">
                                    <i className="fas fa-plug mr-2"></i>mit Immobilienscout24 verbinden
                                </button>
                                <button className="btn btn-secondary btn-block">Abbrechen</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row pb-3">
                    <div className="col-md-4 offset-md-4">
                        <button className="btn btn-lg btn-secondary btn-block">Abbrechen</button>
                    </div>
                </div>
                <div className="row pb-3">
                    <div className="col-md-4 offset-md-4">
                        <div className="input-group">
                            <input type="text" className="form-control form-control-lg" placeholder="Objekte suchen" />
                        </div>
                    </div>
                </div>
                <div className="row pb-2">
                    <div className="col-md-4 offset-md-4">
                        <button className="btn btn-lg btn-success btn-block">
                            <i className="fas fa-download mr-2"></i>Alle angezeigten Objekte importieren
                        </button>
                    </div>
                </div>
                <div className="row pb-4" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success btn-block">
                                        <b>
                                            <i className="fas fa-download mr-2"></i>Objekt importieren
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary btn-block" disabled>
                                        <b>
                                            <div className={styles.loader}></div>Objekt wird zurzeit importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success btn-block">
                                        <b>
                                            <i className="fas fa-download mr-2"></i>Objekt importieren
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success btn-block">
                                        <b>
                                            <i className="fas fa-download mr-2"></i>Objekt importieren
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success btn-block">
                                        <b>
                                            <i className="fas fa-download mr-2"></i>Objekt importieren
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success btn-block">
                                        <b>
                                            <i className="fas fa-download mr-2"></i>Objekt importieren
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success btn-block">
                                        <b>
                                            <i className="fas fa-download mr-2"></i>Objekt importieren
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1700-227 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Wohn- und Geschäftshaus mit Denkmalschutz in Großschönau </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (133678363)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1700-227-Titel.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">420.000,00&nbsp;€</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success btn-block">
                                        <b>
                                            <i className="fas fa-download mr-2"></i>Objekt importieren
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-md-3 mt-2">
                        <span className="position-relative">
                            <div className="card null null card-primary card-outline h-100">
                                <div className="card-body pb-0">
                                    <div>
                                        <div>
                                            {' '}
                                            <h3 className="profile-username text-center mt-2">1501 </h3>
                                            <h3 className="profile-username text-center text-bold mt-2">Attraktive Erdgeschoss- 4-Raum-Wohnung in Bernau bei Berlin </h3>
                                            <h3 className="profile-username text-center">
                                                <a href="https://www.immobilienscout24.de/scoutmanager/exposepreview/133678363/browser.html" target="_blank">
                                                    (134998200)
                                                </a>
                                            </h3>
                                            <div className="text-center mb-3 mt-3">
                                                <img className="mr-2" src="https://benit.cloud/api/ri/real-estate/objekt/files/1501-IMG_3463.jpeg" alt="message user image" style={{ height: 150 }} />
                                            </div>
                                            <h5 className="text-center mt-4 text-secondary text-bold">260.000,00 €</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-secondary btn-block" disabled>
                                        <b>
                                            <i className="fas fa-check mr-2"></i>Objekt bereits importiert
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <hr />

                <div className="row pb-3" style={{ marginTop: '10px' }}>
                    <div className="col-md-4 offset-md-4">
                        <button className="btn btn-lg btn-secondary btn-block">Abbrechen</button>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-lg-6 col-xl-3 mt-2">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">Export zu Immobilienscout24 konfigurieren</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.{' '}
                                </p>
                                <button className="btn btn-block btn-success">
                                    {' '}
                                    <i className="fas fa-plug mr-2"></i>mit Immobilienscout24 verbinden
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 mt-2">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">IVD Export Konfigurieren</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Um sich mit dem IVD Portal zu verbinden, muss zuerst ein FTP Zugang bei IVD beantragt werden. Bitte schicken Sie den folgenden text an{' '}
                                    <a href="mailto:info@ivd.de">info@ivd.de</a>{' '}
                                </p>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label>
                                            E-Mail Text an IVD{' '}
                                            {/* <button onClick={(e) => ri_real_estate_get_texts(dataTmp)} className="ml-2 btn btn-sm btn-primary">
                                                    Textvorschlag
                                                </button> */}
                                        </label>
                                        <div>
                                            <button className="mb-2 btn-block btn btn-sm btn-secondary">Text in Zwischenablage kopieren</button>
                                        </div>
                                        <div className="input-group">
                                            <textarea className="form-control" rows={10} value={'blub'} onChange={(e) => null} readOnly></textarea>
                                        </div>
                                    </div>
                                </div>
                                <p className="card-text">Nachdem Sie die Antwort von IVD erhalten haben, kopieren Sie bitte die Zugangsdaten in die entsprechenden Felder weiter unten </p>
                                <div className="form-group">
                                    <label>Anbieter Nr.</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP URL</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP Port</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP User Name</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP Password</label>
                                    <input type="password" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <button className="btn btn-block btn-primary">IVD Konfiguration speichern</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 mt-2">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">Kleinanzeigen Konfigurieren</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Um sich mit dem Kleinanzeigen zu verbinden, muss zuerst ein FTP Zugang bei Kleinanzeigen beantragt werden. Bitte schicken Sie den folgenden text an{' '}
                                    <a href="mailto:info@ivd.de">ebay@ebay.de</a>{' '}
                                </p>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label>
                                            E-Mail Text an eBay{' '}
                                            {/* <button onClick={(e) => ri_real_estate_get_texts(dataTmp)} className="ml-2 btn btn-sm btn-primary">
                                                    Textvorschlag
                                                </button> */}
                                        </label>
                                        <div>
                                            <button className="mb-2 btn-block btn btn-sm btn-secondary">Text in Zwischenablage kopieren</button>
                                        </div>
                                        <div className="input-group">
                                            <textarea className="form-control" rows={10} value={'blub'} onChange={(e) => null} readOnly></textarea>
                                        </div>
                                    </div>
                                </div>
                                <p className="card-text">Nachdem Sie die Antwort von Kleinanzeigen erhalten haben, kopieren Sie bitte die Zugangsdaten in die entsprechenden Felder weiter unten </p>
                                <div className="form-group">
                                    <label>Anbieter Nr.</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP URL</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP Port</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP User Name</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP Password</label>
                                    <input type="password" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <button className="btn btn-block btn-primary">Kleinanzeigen Konfiguration speichern</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 mt-2">
                        <div className="card">
                            <div className="card-header text-center">
                                <h5 className="card-title m-0">immowelt Export Konfigurieren</h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Um sich mit immowelt zu verbinden, muss zuerst ein FTP Zugang bei IVD beantragt werden. Bitte schicken Sie den folgenden text an{' '}
                                    <a href="mailto:info@ivd.de">immowelt@immowelt.de</a>{' '}
                                </p>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label>
                                            E-Mail Text an immowelt{' '}
                                            {/* <button onClick={(e) => ri_real_estate_get_texts(dataTmp)} className="ml-2 btn btn-sm btn-primary">
                                                    Textvorschlag
                                                </button> */}
                                        </label>
                                        <div>
                                            <button className="mb-2 btn-block btn btn-sm btn-secondary">Text in Zwischenablage kopieren</button>
                                        </div>
                                        <div className="input-group">
                                            <textarea className="form-control" rows={10} value={'blub'} onChange={(e) => null} readOnly></textarea>
                                        </div>
                                    </div>
                                </div>
                                <p className="card-text">Nachdem Sie die Antwort von immowelt erhalten haben, kopieren Sie bitte die Zugangsdaten in die entsprechenden Felder weiter unten </p>
                                <div className="form-group">
                                    <label>Anbieter Nr.</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP URL</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP Port</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP User Name</label>
                                    <input type="text" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <div className="form-group">
                                    <label>FTP Password</label>
                                    <input type="password" className="form-control" placeholder="" value={''} onChange={(e) => null} />
                                </div>
                                <button className="btn btn-block btn-primary">immowelt Konfiguration speichern</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BenitRealEstateObjectsApp;
