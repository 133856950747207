import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';

const ServiceApp = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const { t } = useTranslation(['general', 'benit-central-configuration']);
    const { mlfv } = useMultiLanguageFieldValue();

    const {
        benit_central_configuration_get_services,
        benit_central_configuration_delete_service,
    } = useActions();

    const { services, servicesLoading, servicesDidInvalidate } =
        useTypedSelector((state) => state.benitCentralConfigurationReducer);

    useEffect(() => {
        benit_central_configuration_get_services();
    }, []);

    useEffect(() => {
        benit_central_configuration_get_services(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        if (servicesDidInvalidate) {
            benit_central_configuration_get_services(searchTerm);
        }
    }, [servicesDidInvalidate]);

    const deleteService = (serviceId: string, serviceName: string | null) => {
        showConfirmPopup({
            title: t('general:are_you_sure'),
            text: t('general:no_revert'),
            confirmButtonText: t('general:delete'),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: () => {
                benit_central_configuration_delete_service(serviceId);
                fireToast('success', `${serviceName} ${t('general:deleted')}`);
            },
        });
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-4 offset-md-4 p-1">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            placeholder={t(
                                'benit-central-configuration:search_service'
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {services ? (
                    services.map((service: any) => {
                        return (
                            <div className="col-md-12 mt-2">
                                <div className="card card-primary card-outline ">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            {mlfv(service.name)}
                                        </h3>

                                        <div className="card-tools">
                                            <button
                                                type="button"
                                                className="btn btn-tool"
                                                data-card-widget="collapse"
                                            >
                                                <i className="fas fa-minus"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-tool"
                                                data-card-widget="maximize"
                                            >
                                                <i className="fas fa-expand"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body box-profile">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="callout callout-info">
                                                    <h5>
                                                        {mlfv(
                                                            service.description
                                                        )}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center">
                                                            {t(
                                                                'general:mandatory'
                                                            )}
                                                        </span>
                                                        <span className="info-box-number text-center mb-0">
                                                            {service.mandatory
                                                                ? t(
                                                                      'general:yes'
                                                                  )
                                                                : t(
                                                                      'general:no'
                                                                  )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="info-box bg-light">
                                                    <div className="info-box-content">
                                                        <span className="info-box-text text-center">
                                                            {t(
                                                                'benit-central-configuration:organization_specific'
                                                            )}
                                                        </span>
                                                        <span className="info-box-number text-center mb-0">
                                                            {service.organizationSpecific
                                                                ? t(
                                                                      'general:yes'
                                                                  )
                                                                : t(
                                                                      'general:no'
                                                                  )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>
                                                    {t('general:applications')}
                                                </h5>
                                                {service.applications.map(
                                                    (application: any) => {
                                                        return (
                                                            <div className="card card-primary collapsed-card">
                                                                <div className="card-header">
                                                                    <h3 className="card-title">
                                                                        {mlfv(
                                                                            application.name
                                                                        )}{' '}
                                                                        (
                                                                        {application.type[0].toUpperCase() +
                                                                            application.type.substring(
                                                                                1
                                                                            )}
                                                                        )
                                                                    </h3>

                                                                    <div className="card-tools">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-tool"
                                                                            data-card-widget="collapse"
                                                                        >
                                                                            <i className="fas fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="callout callout-info">
                                                                                <h5>
                                                                                    {mlfv(
                                                                                        application.description
                                                                                    )}
                                                                                </h5>
                                                                            </div>
                                                                            {service.applicationPermissions.map(
                                                                                (
                                                                                    applicationPermission: any
                                                                                ) => {
                                                                                    if (
                                                                                        applicationPermission.application ===
                                                                                        application.id
                                                                                    ) {
                                                                                        return (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-block btn-secondary"
                                                                                            >
                                                                                                {service.permissions.map(
                                                                                                    (
                                                                                                        permission: any
                                                                                                    ) => {
                                                                                                        if (
                                                                                                            applicationPermission.permission ===
                                                                                                            permission.id
                                                                                                        ) {
                                                                                                            return permission.identifier;
                                                                                                        } else {
                                                                                                            return null;
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </button>
                                                                                        );
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <div className="text-right">
                                                                        <a
                                                                            href="#"
                                                                            className="btn btn-sm btn-primary"
                                                                        >
                                                                            {t(
                                                                                'benit-central-configuration:go_to_application'
                                                                            )}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <h5>
                                                    {t('general:permissions')}
                                                </h5>
                                                {service.permissions.map(
                                                    (permission: any) => {
                                                        return (
                                                            <div className="card card-secondary collapsed-card">
                                                                <div className="card-header">
                                                                    <h3 className="card-title">
                                                                        {
                                                                            permission.identifier
                                                                        }
                                                                    </h3>

                                                                    <div className="card-tools">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-tool"
                                                                            data-card-widget="collapse"
                                                                        >
                                                                            <i className="fas fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="callout callout-info">
                                                                        <h5>
                                                                            {mlfv(
                                                                                permission.description
                                                                            )}
                                                                        </h5>
                                                                    </div>
                                                                    {service.applicationPermissions.map(
                                                                        (
                                                                            applicationPermission: any
                                                                        ) => {
                                                                            if (
                                                                                applicationPermission.permission ===
                                                                                permission.id
                                                                            ) {
                                                                                return (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-block btn-primary"
                                                                                    >
                                                                                        {service.applications.map(
                                                                                            (
                                                                                                application: any
                                                                                            ) => {
                                                                                                if (
                                                                                                    applicationPermission.application ===
                                                                                                    application.id
                                                                                                ) {
                                                                                                    return mlfv(
                                                                                                        application.name
                                                                                                    );
                                                                                                } else {
                                                                                                    return null;
                                                                                                }
                                                                                            }
                                                                                        )}
                                                                                    </button>
                                                                                );
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="card-footer"
                                        style={{ display: 'block' }}
                                    >
                                        <button
                                            className="btn btn-danger btn-block"
                                            onClick={(e) =>
                                                deleteService(
                                                    service.id,
                                                    mlfv(service.name)
                                                )
                                            }
                                        >
                                            <b>{t('general:delete')} </b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="offset-md-3 col-md-6 mt-2 p-1 alert alert-info">
                        <h5>
                            <i className="icon fas fa-info"></i>{' '}
                            {searchTerm.length > 0
                                ? t(
                                      'benit-central-configuration:no_services_found'
                                  )
                                : t(
                                      'benit-central-configuration:no_services_yet'
                                  )}
                            {searchTerm}
                        </h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceApp;
