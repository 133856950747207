import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ThreeDots } from 'react-loader-spinner';
import { useRecorder } from 'use-recorder';
import { useActions } from '../../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';

interface ChatInputProps {
    onSendMessage: (message: string) => void;
}

const ChatInputContainer = styled.div`
    display: flex;
    align-items: end;
    justify-content: space-between;
    background-color: #fff;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const ChatButtonContainer = styled.div`
    display: flex;
`;

const ChatButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: white;
    border: none;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    margin-left: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    }
`;

const RecorderStatus = {
    PAUSED: 'paused',
    RECORDING: 'recording',
    PLAYING: 'playing',
    SILENT: 'silent',
};

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
    const [message, setMessage] = useState<null | string>('');
    const inputFieldRef = useRef<HTMLTextAreaElement>(null);
    const [recording, setRecording] = useState(false);
    const [status, setStatus] = React.useState(RecorderStatus.PAUSED);
    const { start, stop, player } = useRecorder();

    const [maxHeight, setMaxHeight] = useState(200);

    useEffect(() => {
        const handleResize = () => {
            const screenHeight = window.innerHeight;
            setMaxHeight(screenHeight / 2);
        };

        handleResize(); // Initial calculation

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { benit_care_assistant_transcribe_voice_input } = useActions();

    const { transcriptionPending, transcribedText } = useTypedSelector((state) => state.benitCareReducer);

    const handleSend = () => {
        if (message && message.trim() !== '') {
            onSendMessage(message);
            setMessage('');
            if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style) {
                inputFieldRef.current.style.height = '40px';
                inputFieldRef.current.focus();
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
            setMessage('');
            if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style) {
                inputFieldRef.current.style.height = '40px';
            }
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style) {
            const target = event.target;
            inputFieldRef.current.style.height = '30px';
            inputFieldRef.current.style.height = `${target.scrollHeight < 200 ? target.scrollHeight : 200}px`;
            setMessage(event.target.value);
        }
    };

    useEffect(() => {
        if (player && player.src) {
            benit_care_assistant_transcribe_voice_input(player.src);
        }
    }, [player]);

    useEffect(() => {
        if (transcribedText) {
            setMessage(transcribedText);
        }
    }, [transcribedText]);

    useEffect(() => {
        if (inputFieldRef && inputFieldRef.current && inputFieldRef.current.style) {
            inputFieldRef.current.style.height = `${inputFieldRef.current.scrollHeight}px`;
        }
    }, [message]);

    return (
        <ChatInputContainer>
            <ChatButtonContainer>
                {recording ? (
                    <ChatButton
                        color="#1c1c1e"
                        onClick={(e) => {
                            setRecording(!recording);
                            stop();
                        }}
                    >
                        <i className="fa fa-stop" />
                    </ChatButton>
                ) : (
                    <ChatButton
                        color="#1c1c1e"
                        onClick={(e) => {
                            setRecording(!recording);
                            start();
                        }}
                    >
                        <i className="fa fa-microphone-alt" />
                    </ChatButton>
                )}
            </ChatButtonContainer>
            {/* {!!player && (
                <a href={player.src} download={`recording-${Date.now()}`}>
                    <span>download</span>
                </a>
            )} */}
            {recording || transcriptionPending ? (
                <ThreeDots height="40" width="80" radius="9" color="#1c1c1e" ariaLabel="three-dots-loading" wrapperStyle={{}} visible={true} />
            ) : (
                <textarea
                    style={{ flex: 1, fontSize: '16px', padding: '8px', border: 'none', borderRadius: '4px', outline: 'none', resize: 'none', minHeight: '30px', maxHeight: `${maxHeight}px` }}
                    rows={1}
                    onKeyDown={handleKeyDown}
                    ref={inputFieldRef}
                    placeholder="Bericht eingeben..."
                    value={message ?? ''}
                    onChange={handleOnChange}
                />
            )}

            <ChatButtonContainer>
                <ChatButton color="#0080ff" onClick={handleSend}>
                    <i className="fa fa-paper-plane" />
                </ChatButton>
            </ChatButtonContainer>
        </ChatInputContainer>
    );
};

export default ChatInput;
