import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';
import { actionCreators } from '../../../../../state';

export const benit_central_configuration_change_language = (languageKey: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: '/api/benit/organization-configuration/change-language',
            method: 'put',
            body: {
                userDefaultLanguage: languageKey,
            },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_LANGUAGE_CHANGE,
                    payload: { languageKey },
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_central_configuration_language_changed = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_CONFIGURATION_LANGUAGE_CHANGED,
        });
    };
};
