import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { useDropzone } from 'react-dropzone';
import styles from './Expenses.module.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

interface IProps {
    expenseId: string;
}

interface IDocumentLoadSuccessPayload {
    numPages: any;
}

const ExpensesDetailApp = ({ expenseId }: IProps) => {
    //pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + `/js/pdfworker/${pdfjs.version}/pdf.worker.min.js`;
    const history = useHistory();

    const { t } = useTranslation(['general', 'benit-accounting']);

    const saveExpenseButton = useRef<any>(null);
    const downloadExpenseButton = useRef<any>(null);

    const [numPages, setNumPages] = useState(null);
    const [formerNumPages, setFormerNumPages] = useState(null);
    const [expenseLoaded, setExpenseLoaded] = useState(false);

    const [expenseTmp, setExpenseTmp] = useState<{ [x: string]: any } | null>({});

    const {
        benit_accounting_get_expense,
        benit_accounting_update_expense,
        benit_accounting_reset_expense_update_state,
        benit_accounting_delete_expense,
        benit_accounting_reset_expense_deletion_state,
        benit_accounting_get_expense_document,
    } = useActions();

    const { expense, getExpensePending, updateExpensePending, updateExpenseSuccess, deleteExpenseSuccess, deleteExpensePending, expenseInternalUrl } = useTypedSelector(
        (state) => state.benitAccountingReducer
    );

    configure({
        ignoreTags: ['input', 'select', 'textarea'],
        ignoreEventsCondition: () => false,
    });

    const keyMap = {
        SAVE: ['command+s', 'ctrl+s'],
        DOWNLOADEXPENSE: ['command+p', 'ctrl+p'],
    };

    const saveObject = useCallback((e) => {
        e.preventDefault();
        if (saveExpenseButton && saveExpenseButton.current) {
            saveExpenseButton.current.click()!;
        }
    }, []);

    const downloadExpense = useCallback((e) => {
        e.preventDefault();
        if (downloadExpenseButton && downloadExpenseButton.current) {
            downloadExpenseButton.current.click()!;
        }
    }, []);

    const handlers = {
        SAVE: (e: any) => saveObject(e),
        DOWNLOADEXPENSE: (e: any) => downloadExpense(e),
    };

    useEffect(() => {
        benit_accounting_get_expense(expenseId);
    }, [expenseId]);

    useEffect(() => {
        if (expense) {
            setExpenseTmp(expense.data);
            // benit_accounting_get_expense_document(expense, false);
        }
    }, [expense]);

    useEffect(() => {
        if (deleteExpenseSuccess) {
            fireToast('success', t('benit-accounting:expense_deleted'));
            history.push(`/benit/accounting/expenses/`);
            benit_accounting_reset_expense_deletion_state();
        }
    }, [deleteExpenseSuccess]);

    useEffect(() => {
        if (updateExpenseSuccess) {
            fireToast('success', t('benit-accounting:expense_saved'));
            history.push(`/benit/accounting/expenses/`);
            benit_accounting_reset_expense_update_state();
        }
    }, [updateExpenseSuccess]);

    useEffect(() => {
        if (expenseInternalUrl) {
            setExpenseLoaded(false);
        }
    }, [expenseInternalUrl]);

    const onDocumentLoadSuccess = ({ numPages }: IDocumentLoadSuccessPayload) => {
        setNumPages(numPages);
        setExpenseLoaded(true);
    };

    const inputField = (
        label: string,
        field: string,
        type: string = 'text',
        additionalClasses?: string | null,
        appendText?: string | null,
        maxLength?: number | null,
        prependText?: string | null,
        disabled?: boolean | null
    ) => {
        return (
            <div className={`form-group`}>
                <label>{label}</label>
                <div className="input-group">
                    {prependText ? (
                        <div className="input-group-prepend">
                            <span className="input-group-text">{prependText}</span>
                        </div>
                    ) : null}
                    <input
                        type={type}
                        className={`form-control ${additionalClasses ? additionalClasses : ''} ${
                            label.includes('*') && expenseTmp && (!expenseTmp[field] || expenseTmp[field].length === 0) ? 'border-danger' : ''
                        }`}
                        placeholder=""
                        value={expenseTmp && expenseTmp[field] ? (expenseTmp[field] === '€' ? expenseTmp[field].replace('.', ',') : expenseTmp[field]) : ''}
                        onChange={(e) => {
                            let value: string | number = e.target.value;
                            if (type === 'number') {
                                value = e.target.value.replace(',', '.');
                            }
                            setExpenseTmp({
                                ...expenseTmp,
                                [field]: type === 'number' && value !== '0' ? parseFloat(value) : value,
                            });
                        }}
                        pattern={type === 'number' ? '[0-9]+([\\.,][0-9]+)?' : ''}
                        maxLength={maxLength ? maxLength : undefined}
                        disabled={disabled ? disabled : false}
                    />
                    {appendText ? (
                        <div className="input-group-append">
                            <span className="input-group-text">{appendText}</span>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    const inputTextarea = (label: string, field: string, rows: number = 3, additionalClasses?: string) => {
        return (
            <div className={`form-group`}>
                <label>{label}</label>
                <textarea
                    className={`form-control ${additionalClasses ? additionalClasses : ''} ${
                        label.includes('*') && expenseTmp && (!expenseTmp[field] || expenseTmp[field].length === 0) ? 'border-danger' : ''
                    }`}
                    placeholder=""
                    value={expenseTmp && expenseTmp[field] ? (expenseTmp[field] === '€' || expenseTmp[field] === 'kWh/(m²*a)' ? expenseTmp[field].replace('.', ',') : expenseTmp[field]) : ''}
                    onChange={(e) => {
                        let value: string | number = e.target.value;
                        setExpenseTmp({
                            ...expenseTmp,
                            [field]: value,
                        });
                    }}
                    rows={rows}
                />
            </div>
        );
    };

    const deleteExpense = (expenseId: string) => {
        showConfirmPopup({
            title: t('benit-accounting:delete_expense_question', {
                expenseId,
            }),
            text: t('general:no_revert'),
            confirmButtonText: t('general:delete'),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: () => {
                benit_accounting_delete_expense(expenseId);
            },
        });
    };

    const documentViewer = (url: string | null, loadSuccessCallback: any, numPagesInput: any) => {
        return url ? (
            <SizeMe>
                {({ size }) => (
                    <Document
                        file={url}
                        onLoadSuccess={loadSuccessCallback}
                        loading={
                            <div>
                                <div className={styles.loader}></div>
                            </div>
                        }
                    >
                        {Array.from(new Array(numPagesInput), (el, index) => (
                            <>
                                <Page width={size.width ?? 0} key={`page_${index + 1}`} pageNumber={index + 1} />
                                {numPagesInput && index < numPagesInput - 1 ? <hr /> : null}
                            </>
                        ))}
                        {/* <Page width={size.width} pageNumber={pageNumber} /> */}
                        {/* <p>
                                Page {pageNumber} of {numPagesInput}
                            </p> */}
                    </Document>
                )}
            </SizeMe>
        ) : null;
    };

    return (
        <div className="row">
            {getExpensePending ? (
                <div className={styles.loader}></div>
            ) : (
                <>
                    <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                    <section className="col-lg-8 col-xl-8 connectedSortable">
                        <div className="card card-primary card-outline scroll">
                            <div className="card-header">
                                <h3 className="card-title">
                                    {t('benit-accounting:invoice')} - {expenseTmp && expenseTmp.expenseId ? expenseTmp.expenseId : ''}
                                </h3>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="row" style={{}}>
                                        <div className="col-lg-12">{inputField(t('benit-accounting:description'), 'description', 'text')}</div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label>{t('benit-accounting:expense_number')}</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control text-right" value={expenseTmp && expenseTmp.expenseId ? expenseTmp.expenseId : ''} disabled />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">{inputField(t('benit-accounting:expense_date'), 'expenseDate', 'date')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="mt-2">
                                    <button
                                        id="submit_button"
                                        className="btn btn-outline-danger float-left"
                                        style={{ marginBottom: '5px', marginRight: '5px' }}
                                        onClick={(e) => (expenseTmp && expenseTmp.expenseId ? deleteExpense(expenseTmp.expenseId) : null)}
                                    >
                                        {deleteExpensePending ? <div className={styles.loader} /> : t('general:delete')}
                                    </button>
                                    <button
                                        id="trigger_rechnungSpeichern"
                                        ref={saveExpenseButton}
                                        type="button"
                                        className="btn btn-outline-primary float-right mb-2"
                                        style={{ marginRight: 5 }}
                                        onClick={(e) => benit_accounting_update_expense(expenseTmp)}
                                    >
                                        {updateExpensePending ? <div className={styles.loader} /> : t('benit-accounting:save_expense')}
                                    </button>
                                    <button onClick={(e) => history.push(`/benit/accounting/expenses/`)} className="mr-2 mb-2 btn btn-outline-secondary float-right">
                                        {t('general:cancel')}
                                    </button>
                                    <button
                                        type="button"
                                        ref={downloadExpenseButton}
                                        className="btn btn-outline-info float-right mb-2"
                                        style={{ marginRight: 5 }}
                                        onClick={(e) => (expenseTmp ? benit_accounting_get_expense_document(expenseTmp, true) : null)}
                                    >
                                        {t('benit-accounting:download_pdf')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="col-lg-4 col-xl-4 connectedSortable">
                        <div className="card card-secondary card-outline scroll">
                            <div className="card-header">
                                <h3 className="card-title">{t('benit-accounting:receipt')}</h3>
                            </div>
                            <div className="card-body">
                                <div className={`${!expenseLoaded ? 'd-none' : ''}`}>{documentViewer(expenseInternalUrl, onDocumentLoadSuccess, numPages)}</div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

export default ExpensesDetailApp;
