import { useActions } from '../../../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../../../hooks/useTypedSelector';
import { useEffect } from 'react';
import useMultiLanguageFieldValue from '../../../../../../../hooks/useMultiLanguageFieldValue';
import { useTranslation } from 'react-i18next';

const ErrorAlerts = () => {
    const { benit_central_ui_delete_error } = useActions();
    const { errors } = useTypedSelector((state) => state.benitCentralUIReducer);
    const { mlfv } = useMultiLanguageFieldValue();
    const { t } = useTranslation(['general']);

    useEffect(() => {
        if (errors) {
            console.log(errors);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [errors]);

    return (
        <div>
            {errors
                ? errors.map((error: any) => (
                      <div className="alert alert-danger alert-dismissible " key={error.message} onClick={(e) => benit_central_ui_delete_error(error)} style={{ cursor: 'pointer' }}>
                          <button type="button" className="close" onClick={(e) => benit_central_ui_delete_error(error)} aria-hidden="true">
                              ×
                          </button>
                          <h5>
                              <i className="icon fas fa-ban"></i> {t('general:error')!}
                          </h5>
                          {error.description ? mlfv(error.description) : error.message}
                      </div>
                  ))
                : null}
        </div>
    );
};

export default ErrorAlerts;
