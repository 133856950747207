import { useEffect, useState, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';

const BelegeApp = () => {
    const dateRangePicker = useRef<any>();
    const endDateDateFormat = new Date();
    const startDateDateFormat = new Date(endDateDateFormat.getTime() - 4 * 7 * 24 * 60 * 60 * 1000);
    const endDateStringFormat: string = endDateDateFormat.getDate() + '.' + (endDateDateFormat.getMonth() + 1) + '.' + endDateDateFormat.getFullYear();
    const startDateStringFormat: string = startDateDateFormat.getDate() + '.' + (startDateDateFormat.getMonth() + 1) + '.' + startDateDateFormat.getFullYear();

    const [endDate, setEndDate] = useState<string>(endDateStringFormat);
    const [startDate, setStartDate] = useState<string>(startDateStringFormat);

    const [urlParamsUsed, setUrlParamsUsed] = useState(false);

    const [eidParam, setEidParam] = useState<string | null>(null);
    const [datesParam, setDatesParam] = useState<string[] | null>(null);
    const [moidParam, setMoidParam] = useState<string | null>(null);
    const [midParam, setMidParam] = useState<string | null>(null);
    const [kaidParam, setKaidParam] = useState<string | null>(null);
    const [archividParam, setArchividParam] = useState<string | null>(null);
    const { t } = useTranslation(['general', 'benit-central-configuration', 'ri-property-management']);

    const [filterMietobjekte, setFilterMietobjekte] = useState<[{ value: string; label: string }] | null>(null);
    const [filterMieter, setFilterMieter] = useState<any[] | null>(null);
    const [filterKostenarten, setFilterKostenarten] = useState<any[] | null>(null);

    const [selectedEigentuemer, setSelectedEigentuemer] = useState('0');
    const [selectedArchivid, setSelectedArchivid] = useState<string | null>(null);
    const [selectedMietobjekte, setSelectedMietobjekte] = useState<[{ value: string; label: string }] | []>([]);
    const [selectedMieter, setSelectedMieter] = useState<[{ value: string; label: string }] | []>([]);
    const [selectedKostenarten, setSelectedKostenarten] = useState<[{ value: string; label: string }] | []>([]);

    const [userIsAdmin, setUserIsAdmin] = useState(false);

    const {
        ri_property_management_clear_belege,
        ri_property_management_get_belege,
        ri_property_management_set_beleg_status,
        ri_property_management_get_current_user_eigentuemer,
        ri_property_management_pdf_download,
    } = useActions();

    const { belegeDidInvalidate, belegePending, belege, currentUserEigentuemer, selectedCurrentUserEigentuemer } = useTypedSelector((state) => state.riPropertyManagementReducer);

    const { permissionIdentifiers } = useTypedSelector((state) => state.benitAuthReducer);

    useEffect(() => {
        ri_property_management_get_current_user_eigentuemer();
    }, []);

    useEffect(() => {
        if (permissionIdentifiers && permissionIdentifiers.includes('ri-property-management-belege-admin-permission')) {
            setUserIsAdmin(true);
        }
    }, [permissionIdentifiers]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let eid: number | string | null = queryParams.get('eid');
        if (eid) {
            changeEigentuemer(eid);
        } else {
            if (currentUserEigentuemer && currentUserEigentuemer.length === 1) {
                changeEigentuemer(currentUserEigentuemer[0].eid);
            }
        }
    }, [currentUserEigentuemer]);

    const changeEigentuemer = (eid: string) => {
        if (currentUserEigentuemer) {
            let tmpMietobjekte: any = [];
            let tmpMieter: any = [];
            let tmpKostenarten: any = [];
            let tmpMietobjekteSelected: any = [];
            let tmpMieterSelected: any = [];
            let tmpKostenartenSelected: any = [];
            let tmpMoidsUrl: any = [];
            let tmpMidsUrl: any = [];
            let tmpKaidsUrl: any = [];
            let tmpArchividUrl: string = '';
            let datesParamTmp = [startDate, endDate];

            if (!urlParamsUsed) {
                const queryParams = new URLSearchParams(window.location.search);
                const from = queryParams.get('from');
                if (from) {
                    datesParamTmp[0] = from;
                    setStartDate(datesParamTmp[0]);
                }
                const to = queryParams.get('to');
                if (to) {
                    datesParamTmp[1] = to;
                    setEndDate(datesParamTmp[1]);
                }
                const moid = queryParams.get('moid');
                if (moid) {
                    tmpMoidsUrl = moid.split(',');
                }
                const mid = queryParams.get('mid');
                if (mid) {
                    tmpMidsUrl = mid.split(',');
                }
                const kaid = queryParams.get('kaid');
                if (kaid) {
                    tmpKaidsUrl = kaid.split(',');
                }
                const archivid = queryParams.get('archivid');
                if (archivid) {
                    tmpArchividUrl = archivid;
                }
            }

            setDatesParam(datesParamTmp);
            for (const eigentuemer of currentUserEigentuemer) {
                if (eigentuemer.eid == eid) {
                    if (eigentuemer) {
                        if (eigentuemer.mietobjekte) {
                            for (const mietobjekt of eigentuemer.mietobjekte) {
                                const tmpMietobjekt = { label: mietobjekt.strasse + ' ' + mietobjekt.hausnummer, value: mietobjekt.moid };
                                tmpMietobjekte.push(tmpMietobjekt);
                                if (tmpMoidsUrl.includes(mietobjekt.moid.toString())) {
                                    tmpMietobjekteSelected.push(tmpMietobjekt);
                                }
                            }
                        }
                        if (eigentuemer.mieter) {
                            const tmpMieterObjektbezogen = { label: 'Objektbezogen', value: 0 };
                            tmpMieter.push(tmpMieterObjektbezogen);
                            if (tmpMidsUrl.includes('0')) {
                                tmpMieterSelected.push(tmpMieterObjektbezogen);
                            }
                            for (const mieter of eigentuemer.mieter) {
                                const tmpM = { label: mieter.vorname + ' ' + mieter.nachname, value: mieter.mid };
                                tmpMieter.push(tmpM);
                                if (tmpMidsUrl.includes(mieter.mid.toString())) {
                                    tmpMieterSelected.push(tmpM);
                                }
                            }
                        }
                        if (eigentuemer.kostenarten) {
                            for (const kostenart of eigentuemer.kostenarten) {
                                const tmpKostenart = { label: kostenart.langname, value: kostenart.kaid };
                                tmpKostenarten.push(tmpKostenart);
                                if (tmpKaidsUrl.includes(kostenart.kaid.toString())) {
                                    tmpKostenartenSelected.push(tmpKostenart);
                                }
                            }
                        }
                    }
                    break;
                }
            }
            setFilterMietobjekte(tmpMietobjekte);
            setFilterMieter(tmpMieter);
            setFilterKostenarten(tmpKostenarten);

            if (!urlParamsUsed) {
                setSelectedMietobjekte(tmpMietobjekteSelected);
                setSelectedMieter(tmpMieterSelected);
                setSelectedKostenarten(tmpKostenartenSelected);
                setSelectedArchivid(tmpArchividUrl);

                setUrlParamsUsed(true);
            }

            if (eid !== '0') {
                setEidParam(eid);
                setSelectedEigentuemer(eid);
            }
        }
    };

    useEffect(() => {
        //console.log('' + moidParam + ' ' + midParam + ' ' + kaidParam);
        if (eidParam && eidParam !== '0' && eidParam !== '' && (moidParam || moidParam === '') && (midParam || midParam === '') && (kaidParam || kaidParam === '') && datesParam) {
            const fromParam = datesParam[0];
            const toParam = datesParam[1];
            ri_property_management_get_belege(eidParam, fromParam, toParam, moidParam, midParam, kaidParam, archividParam);
        }
    }, [eidParam, datesParam, moidParam, midParam, kaidParam, archividParam]);

    useEffect(() => {
        if (belegeDidInvalidate) {
            if (eidParam && eidParam !== '0' && eidParam !== '' && (moidParam || moidParam === '') && (midParam || midParam === '') && (kaidParam || kaidParam === '') && datesParam) {
                const fromParam = datesParam[0];
                const toParam = datesParam[1];
                ri_property_management_get_belege(eidParam, fromParam, toParam, moidParam, midParam, kaidParam, archividParam);
            }
        }
    }, [belegeDidInvalidate]);

    useEffect(() => {
        if (eidParam && eidParam !== '' && eidParam !== '0') {
            const tmpMoids = (selectedMietobjekte as any[]).map((mietobjekt) => mietobjekt.value);
            setMoidParam(tmpMoids.join());
        }
    }, [selectedMietobjekte]);

    useEffect(() => {
        if (eidParam && eidParam !== '' && eidParam !== '0') {
            const tmpMids = (selectedMieter as any[]).map((mieter) => mieter.value);
            setMidParam(tmpMids.join());
        }
    }, [selectedMieter]);

    useEffect(() => {
        if (eidParam && eidParam !== '' && eidParam !== '0') {
            const tmpKaids = (selectedKostenarten as any[]).map((kostenart) => kostenart.value);
            setKaidParam(tmpKaids.join());
        }
    }, [selectedKostenarten]);

    useEffect(() => {
        setArchividParam(selectedArchivid);
    }, [selectedArchivid]);

    const currency = (number: number) => {
        return new Intl.NumberFormat('de-de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    const toDate = (date: Date) => {
        return new Intl.DateTimeFormat('de-de', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(date).getTime());
    };

    const setDates = (event: any, picker: any) => {
        const startDateTmp = new Date(picker.startDate);
        const endDateTmp = new Date(picker.endDate);

        setDatesParam([
            startDateTmp.getDate() + '.' + (startDateTmp.getMonth() + 1) + '.' + startDateTmp.getFullYear(),
            endDateTmp.getDate() + '.' + (endDateTmp.getMonth() + 1) + '.' + endDateTmp.getFullYear(),
        ]);
    };

    const belegCard = (
        beleg: any,
        transitionStatusOne?: { value: string; label: string; btnColor: string } | null,
        transitionStatusTwo?: { value: string; label: string; btnColor: string } | null
    ) => {
        return (
            <div className="card card-light card-outline">
                <div className="card-header">
                    <p className="card-title">
                        <span className="font-weight-bold mr-2">A{beleg && beleg.archivid ? beleg.archivid : null}</span>
                    </p>
                    <div className="card-tools">
                        <span className="time">
                            <i className="fas fa-clock"></i> {beleg.erfassungsdatum ? toDate(beleg.erfassungsdatum) : ''}
                        </span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-4">
                        <section className="col-xl-8">
                            <p className="font-weight-bold mt-1">{beleg.beschreibung}</p>
                        </section>
                        <section className="col-xl-4">
                            <h5 className="text-right font-weight-bold">{currency(beleg.betrag)}</h5>
                        </section>
                    </div>

                    {beleg.archivid ? (
                        <button className={`btn btn-sm btn-outline-secondary btn-block mb-1`} onClick={(e) => ri_property_management_pdf_download(`${beleg.dokument ? beleg.dokument : ''}`)}>
                            Beleg anzeigen
                        </button>
                    ) : null}

                    {/* <div className="card card-success mb-1 collapsed-card">
                        <div className="btn btn-sm btn-outline-secondary btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                            Mehr Details
                        </div>
                        <div className="card-body box-profile">
                            <div className="row">
                                <section className="col-xl-8">
                                    <p className="font-weight-bold">
                                        Mietobjekt: {beleg.mietobjekt && typeof beleg.mietobjekt !== 'number' ? `${beleg.mietobjekt.strasse} ${beleg.mietobjekt.hausnummer}` : null}
                                    </p>
                                </section>
                            </div>
                            <div className="row">
                                <section className="col-xl-8">
                                    Mieter: {beleg.mieter && typeof beleg.mieter !== 'number' ? `${beleg.mieter.vorname} ${beleg.mieter.nachname}` : 'Objektbezogen'}
                                </section>
                            </div>
                        </div>
                    </div> */}
                </div>
                {transitionStatusOne || transitionStatusTwo ? (
                    <div className="card-footer">
                        {transitionStatusOne ? (
                            <button
                                className={`btn btn-sm btn-${transitionStatusOne.btnColor} btn-block`}
                                onClick={(e) => ri_property_management_set_beleg_status(beleg.archivid, transitionStatusOne.value)}
                            >
                                {transitionStatusOne.label}
                            </button>
                        ) : null}
                        {transitionStatusTwo ? (
                            <button
                                className={`btn btn-sm btn-${transitionStatusTwo.btnColor} btn-block`}
                                onClick={(e) => ri_property_management_set_beleg_status(beleg.archivid, transitionStatusTwo.value)}
                            >
                                {transitionStatusTwo.label}
                            </button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <div>
            {currentUserEigentuemer && currentUserEigentuemer.length > 1 ? (
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="form-group">
                            <select className="form-control" onChange={(e) => changeEigentuemer(e.target.value)}>
                                {selectedEigentuemer === '0' ? <option value="0">{t('ri-property-management:select_owner')}</option> : null}
                                {currentUserEigentuemer
                                    ? currentUserEigentuemer.map((e) => {
                                          if (selectedEigentuemer === '0') {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          } else if (selectedEigentuemer === e.eid) {
                                              return (
                                                  <option value={e.eid} selected>
                                                      {e.nachname}
                                                  </option>
                                              );
                                          } else if (selectedEigentuemer !== e.eid) {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          }
                                      })
                                    : null}
                            </select>
                        </div>
                    </div>
                </div>
            ) : null}
            {selectedEigentuemer !== '0' ? (
                <>
                    {userIsAdmin ? (
                        <>
                            <div className="row col-md-6 offset-md-3 mt-2 mb-3">
                                <div className="col-xl-12 mb-1">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ height: '40px' }}
                                            onChange={(e) => {
                                                setSelectedArchivid(e.target.value);
                                            }}
                                            value={selectedArchivid ? selectedArchivid : ''}
                                            placeholder={'Nach Belegnummer suchen'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row col-md-6 offset-md-3 mt-2 mb-3">
                                <div className="col-xl-3 mb-1">
                                    <DateRangePicker
                                        initialSettings={{
                                            locale: {
                                                format: 'DD.MM.YYYY',
                                                separator: ' - ',
                                                applyLabel: 'Anwenden',
                                                cancelLabel: 'Abbrechen',
                                                fromLabel: 'Von',
                                                toLabel: 'Bis',
                                                customRangeLabel: 'Erstellen',
                                                daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                                                monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                                                firstDay: 1,
                                            },
                                            startDate: startDate,
                                            endDate: endDate,
                                        }}
                                        onApply={setDates}
                                        onHide={setDates}
                                        onHideCalendar={setDates}
                                        ref={dateRangePicker}
                                    >
                                        <input type="text" className="form-control" style={{ height: '100%' }} />
                                    </DateRangePicker>
                                </div>
                                <div className="col-xl-3 mb-1">
                                    <MultiSelect
                                        overrideStrings={{
                                            allItemsAreSelected: 'Alle Mieobjekte sind ausgewählt',
                                            clearSearch: 'Suche zurücksetzen',
                                            noOptions: 'Keine Mietobjekte vorhanden',
                                            search: 'Suchen',
                                            selectAll: 'Alle Mietobjekte auswählen',
                                            selectSomeItems: 'Nach Mietobjekt filtern',
                                        }}
                                        options={filterMietobjekte ? filterMietobjekte : [{ label: '', value: '' }]}
                                        value={selectedMietobjekte}
                                        onChange={setSelectedMietobjekte}
                                        labelledBy="Select"
                                    />
                                </div>
                                <div className="col-xl-3 mb-1">
                                    <MultiSelect
                                        overrideStrings={{
                                            allItemsAreSelected: 'Alle Mieter sind ausgewählt',
                                            clearSearch: 'Suche zurücksetzen',
                                            noOptions: 'Keine Mieter vorhanden',
                                            search: 'Suchen',
                                            selectAll: 'Alle Mieter auswählen',
                                            selectSomeItems: 'Nach Mieter filtern',
                                        }}
                                        options={filterMieter ? filterMieter : [{ label: '', value: '' }]}
                                        value={selectedMieter}
                                        onChange={setSelectedMieter}
                                        labelledBy="Select"
                                    />
                                </div>
                                <div className="col-xl-3 mb-1">
                                    <MultiSelect
                                        overrideStrings={{
                                            allItemsAreSelected: 'Alle Kostenarten sind ausgewählt',
                                            clearSearch: 'Suche zurücksetzen',
                                            noOptions: 'Keine Kostenart vorhanden',
                                            search: 'Suchen',
                                            selectAll: 'Alle Kostenarten auswählen',
                                            selectSomeItems: 'Nach Kostenart filtern',
                                        }}
                                        options={filterKostenarten ? filterKostenarten : [{ label: '', value: '' }]}
                                        value={selectedKostenarten}
                                        onChange={setSelectedKostenarten}
                                        labelledBy="Select"
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}

                    <div className="row">
                        {userIsAdmin ? (
                            <div className={`${userIsAdmin ? 'col-lg-3 col-xl-3' : 'col-lg-4 col-xl-4'} mb-3`}>
                                <div className="card card-row card-secondary h-100">
                                    <div className="card-header">
                                        <h3 className="card-title">Neue Belege</h3>
                                    </div>
                                    <div className="card-body">
                                        {belegePending ? (
                                            <LoadingAnimation />
                                        ) : (
                                            <div>
                                                {belege
                                                    ? belege.map((beleg) => {
                                                          return beleg.status === 'OFFEN' && (!beleg.freigabeStatus || beleg.freigabeStatus === 'NEU')
                                                              ? belegCard(
                                                                    beleg,
                                                                    { value: 'FREIZUGEBEN', label: 'Frei zu geben', btnColor: 'danger' },
                                                                    { value: 'AUTOMATISCH', label: 'Direkt frei geben', btnColor: 'success' }
                                                                )
                                                              : null;
                                                      })
                                                    : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className={`${userIsAdmin ? 'col-lg-3 col-xl-3' : 'col-lg-4 col-xl-4 offset-lg-4 offset-xl-4'} mb-3`}>
                            <div className={`card card-row card-${userIsAdmin ? 'danger' : 'warning'} h-100`}>
                                <div className="card-header">
                                    <h3 className="card-title">Freizugebende Belege</h3>
                                </div>
                                <div className="card-body">
                                    {belegePending ? (
                                        <LoadingAnimation />
                                    ) : (
                                        <div>
                                            {belege
                                                ? belege.map((beleg) => {
                                                      return beleg.status === 'OFFEN' && beleg.freigabeStatus && beleg.freigabeStatus === 'FREIZUGEBEN'
                                                          ? belegCard(
                                                                beleg,
                                                                !userIsAdmin ? { value: 'FREIGEGEBEN', label: 'Freigeben', btnColor: 'success' } : null,
                                                                userIsAdmin ? { value: 'NEU', label: 'Zurück zu neue Belege', btnColor: 'secondary' } : null
                                                            )
                                                          : null;
                                                  })
                                                : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {userIsAdmin ? (
                            <div className={`${userIsAdmin ? 'col-lg-3 col-xl-3' : 'col-lg-4 col-xl-4'} mb-3`}>
                                <div className="card card-row card-default h-100">
                                    <div className="card-header bg-warning">
                                        <h3 className="card-title">Freigegebene Belege</h3>
                                    </div>
                                    <div className="card-body">
                                        {belegePending ? (
                                            <LoadingAnimation />
                                        ) : (
                                            <div>
                                                {belege
                                                    ? belege.map((beleg) => {
                                                          return beleg.status === 'OFFEN' && beleg.freigabeStatus && beleg.freigabeStatus === 'FREIGEGEBEN'
                                                              ? belegCard(beleg, { value: 'UEBERWIESEN', label: 'Überwiesen', btnColor: 'success' })
                                                              : null;
                                                      })
                                                    : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {userIsAdmin ? (
                            <div className={`${userIsAdmin ? 'col-lg-3 col-xl-3' : 'col-lg-4 col-xl-4'} mb-3`}>
                                <div className="card card-row card-default h-100">
                                    <div className="card-header bg-secondary">
                                        <h3 className="card-title">Belege</h3>
                                    </div>
                                    <div className="card-body">
                                        {belegePending ? (
                                            <LoadingAnimation />
                                        ) : (
                                            <div>
                                                {belege
                                                    ? belege.map((beleg) => {
                                                          return beleg.status === 'BEZAHLT' ||
                                                              (beleg.status === 'OFFEN' && (beleg.freigabeStatus === 'AUTOMATISCH' || beleg.freigabeStatus === 'UEBERWIESEN'))
                                                              ? belegCard(
                                                                    beleg,
                                                                    userIsAdmin && beleg.status !== 'BEZAHLT' ? { value: 'NEU', label: 'Zurück zu neue Belege', btnColor: 'secondary' } : null
                                                                )
                                                              : null;
                                                      })
                                                    : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default BelegeApp;
