import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './NewUserCard.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../../hooks/useMultiLanguageFieldValue';
import fireToast from '../../../../central-ui/methods/fireToast';
import { availableLanguages } from '../../../../../../i18n';

type OptionType = {
    value: string;
    label: string;
};

const NewUserCard = () => {
    const [newUserPermissionOptions, setNewUserPermissionOptions] = useState<OptionType[] | null>(null);
    const [newUserApplicationOptions, setNewUserApplicationOptions] = useState<OptionType[] | null>(null);
    const [newUserLanguages, setNewUserLanguages] = useState<string[] | null>(null);

    //User Properties

    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserFirstName, setNewUserFirstName] = useState('');
    const [newUserLastName, setNewUserLastName] = useState('');
    const [newUserPermissions, setNewUserPermissions] = useState(['']);
    const [newUserStartApplicationId, setNewUserStartApplicationId] = useState<string | null>(null);
    const [newUserSelectedPermissionOptions, setNewUserSelectedPermissionOptions] = useState<OptionType[] | null>(null);
    const [newUserSelectedApplicationOptions, setNewUserSelectedApplicationOptions] = useState<OptionType[] | null>(null);
    const [newUserSelectedLanguages, setNewUserSelectedLanguages] = useState<string[] | null>(null);
    const [newUserDefaultLanguage, setNewUserDefaultLanguage] = useState<string | null>(null);

    const { t } = useTranslation(['general', 'benit-central-configuration', 'benit-organization-configuration']);
    const { mlfv } = useMultiLanguageFieldValue();

    const {
        benit_organization_configuration_check_user_email,
        benit_organization_configuration_add_user,
        benit_organization_configuration_get_permissions,
        benit_organization_configuration_get_applications,
    } = useActions();

    const { newUserEmailExists, newUserAddedSuccessfully, newUserCreationPending, permissionIdentifiers, newUserApplications } = useTypedSelector(
        (state) => state.benitOrganizationConfigurationReducer
    );

    const addUser = () => {
        benit_organization_configuration_add_user(newUserEmail, newUserFirstName, newUserLastName, newUserPermissions, newUserStartApplicationId, newUserLanguages, newUserDefaultLanguage);
    };

    useEffect(() => {
        benit_organization_configuration_get_permissions();
    }, []);

    useEffect(() => {
        if (newUserEmail) {
            benit_organization_configuration_check_user_email(newUserEmail);
        }
    }, [newUserEmail]);

    useEffect(() => {
        if (newUserAddedSuccessfully && newUserEmail !== '') {
            fireToast('success', `${t('general:user')}  ${t('general:added')}`);
            setNewUserEmail('');
            setNewUserFirstName('');
            setNewUserLastName('');
            setNewUserPermissions([]);
            setNewUserLanguages(null);
            setNewUserDefaultLanguage(null);
            setNewUserSelectedPermissionOptions(null);
            setNewUserSelectedLanguages(null);
            setNewUserSelectedApplicationOptions(null);
        }
    }, [newUserAddedSuccessfully]);

    useEffect(() => {
        const options: OptionType[] = permissionIdentifiers
            ? permissionIdentifiers.map((permissionIdentifier) => {
                  return {
                      value: permissionIdentifier,
                      label: permissionIdentifier,
                  };
              })
            : [{ value: '', label: '' }];
        setNewUserPermissionOptions(options);
    }, [permissionIdentifiers]);

    const getApplicationName = (applicationName: { [x: string]: string }) => {
        const applicationNameLanguageSpecific = mlfv(applicationName);
        if (applicationNameLanguageSpecific) {
            return applicationNameLanguageSpecific;
        } else {
            return '';
        }
    };

    useEffect(() => {
        const options: OptionType[] = newUserApplications
            ? newUserApplications.map((newUserApplication) => {
                  return {
                      value: newUserApplication.id,
                      label: `${getApplicationName(newUserApplication.name)} (${newUserApplication.identifier})`,
                  };
              })
            : [{ value: '', label: '' }];
        setNewUserApplicationOptions(options);
    }, [newUserApplications]);

    const newUserPermissionsChange = (selectedPermissions: any) => {
        setNewUserSelectedPermissionOptions(selectedPermissions);
        const tmpPermissionIdentifiers = selectedPermissions.map((selectedPermission: any) => selectedPermission.value);
        benit_organization_configuration_get_applications(tmpPermissionIdentifiers, 'new');
        setNewUserPermissions(tmpPermissionIdentifiers);
        setNewUserSelectedApplicationOptions(null);
    };

    const newUserStartApplicationChange = (selectedApplication: any) => {
        if (selectedApplication) {
            setNewUserStartApplicationId(selectedApplication.value);
        }
        setNewUserSelectedApplicationOptions(selectedApplication);
    };

    const newUserLanguagesChange = (selectedLanguages: any) => {
        setNewUserSelectedLanguages(selectedLanguages);
        const tmpLanguages = selectedLanguages.map((language: any) => language.value);
        setNewUserLanguages(tmpLanguages);
    };

    const newUserDefaultLanguagesChange = (selectedLanguage: any) => {
        if (selectedLanguage) {
            setNewUserDefaultLanguage(selectedLanguage.value);
        } else {
            setNewUserDefaultLanguage(null);
        }
    };

    return (
        <div className="card card-success mb-1 collapsed-card">
            <div className="btn btn-lg btn-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                {t('benit-organization-configuration:add_user')}
            </div>
            <div className="card-body box-profile">
                <div className="text-center">
                    <Avatar name={newUserFirstName + ' ' + newUserLastName} size="128" round=".3rem" color="green" />
                </div>

                <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                    <input
                        type="email"
                        className={`form-control control-border ${newUserEmailExists ? 'is-valid' : ''}`}
                        placeholder={t('benit-organization-configuration:user_email')}
                        value={newUserEmail}
                        onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                    {newUserEmailExists ? <span className="valid-feedback">{t('benit-organization-configuration:user_already_exists')}</span> : null}
                </div>

                {newUserEmailExists ? null : (
                    <div>
                        <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                            <input
                                type="text"
                                className="form-control control-border"
                                placeholder={t('benit-organization-configuration:user_first_name')}
                                value={newUserFirstName}
                                onChange={(e) => setNewUserFirstName(e.target.value)}
                            />
                        </div>

                        <div className="mt-2 col-md-10 offset-md-1 input-group mb-3">
                            <input
                                type="text"
                                className="form-control control-border"
                                placeholder={t('benit-organization-configuration:user_last_name')}
                                value={newUserLastName}
                                onChange={(e) => setNewUserLastName(e.target.value)}
                            />
                        </div>
                    </div>
                )}

                <div className="mt-2 col-md-10 offset-md-1 input-group mb-3 border-bottom"></div>

                <div className="mt-2 col-md-10 offset-md-1 mb-3">
                    <div className="form-group">
                        <label>{t('benit-organization-configuration:permissions')}</label>
                        <Select
                            placeholder={t('benit-organization-configuration:choose_permissions')}
                            noOptionsMessage={({ inputValue }) => t('benit-organization-configuration:no_permissions_left')}
                            isMulti
                            name="permissions"
                            options={newUserPermissionOptions ? newUserPermissionOptions : []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={newUserPermissionsChange}
                            value={newUserSelectedPermissionOptions}
                        />
                    </div>
                </div>

                {newUserApplications && newUserApplications.length > 0 ? (
                    <>
                        <div className="mt-2 col-md-10 offset-md-1 input-group mb-3 border-bottom"></div>

                        <div className="mt-2 col-md-10 offset-md-1 mb-3">
                            <div className="form-group">
                                <label>{t('benit-organization-configuration:start_application')}</label>
                                <Select
                                    placeholder={t('benit-organization-configuration:choose_start_application')}
                                    name="startApplication"
                                    options={newUserApplicationOptions ? newUserApplicationOptions : []}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={newUserStartApplicationChange}
                                    isClearable={true}
                                    isSearchable={true}
                                    value={newUserSelectedApplicationOptions}
                                />
                            </div>
                        </div>
                    </>
                ) : null}

                <div className="mt-2 col-md-10 offset-md-1 input-group mb-3 border-bottom"></div>

                <div className="mt-2 col-md-10 offset-md-1 mb-3">
                    <div className="form-group">
                        <label>{t('benit-organization-configuration:languages')}</label>
                        <Select
                            placeholder={t('benit-organization-configuration:choose_languages')}
                            noOptionsMessage={({ inputValue }) => t('benit-central-configuration:no_languages_left')}
                            isMulti
                            name="languages"
                            options={availableLanguages.map((languageKey) => {
                                const label = t(`general:${languageKey}`);
                                const value = languageKey;
                                return { label, value };
                            })}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={newUserLanguagesChange}
                            value={newUserSelectedLanguages}
                        />
                    </div>
                </div>

                {newUserLanguages && newUserLanguages.length > 0 ? (
                    <>
                        <div className="mt-2 col-md-10 offset-md-1 mb-3">
                            <div className="form-group">
                                <label>{t('benit-organization-configuration:default_language')}</label>
                                <Select
                                    placeholder={t('benit-organization-configuration:choose_default_language')}
                                    name="startApplication"
                                    options={newUserLanguages.map((languageKey) => {
                                        const label = t(`general:${languageKey}`);
                                        const value = languageKey;
                                        return { label, value };
                                    })}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={newUserDefaultLanguagesChange}
                                    isClearable={true}
                                    isSearchable={true}
                                />
                            </div>
                        </div>
                    </>
                ) : null}

                <button className="btn btn-primary btn-block" onClick={addUser} disabled={newUserDefaultLanguage && newUserStartApplicationId ? false : true}>
                    {newUserCreationPending ? <div className={styles.loader}></div> : <b>{t('general:save')}</b>}
                </button>
            </div>
        </div>
    );
};

export default NewUserCard;
