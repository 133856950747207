export enum ActionType {
    RI_REAL_ESTATE_CONFIGURATION_GET_SUCCESS = 'ri_real_estate_configuration_get_success',
    RI_REAL_ESTATE_UPDATE_CONFIGURATION_PENDING = 'ri_real_estate_update_configuration_pending',
    RI_REAL_ESTATE_UPDATE_CONFIGURATION_SUCCESS = 'ri_real_estate_update_configuration_success',
    RI_REAL_ESTATE_OBJEKTE_GET_SUCCESS = 'ri_real_estate_objekte_get_success',
    RI_REAL_ESTATE_OBJEKT_GET_SUCCESS = 'ri_real_estate_objekt_get_success',
    RI_REAL_ESTATE_UPDATE_OBJEKT_PENDING = 'ri_real_estate_update_objekt_pending',
    RI_REAL_ESTATE_UPDATE_OBJEKT_SUCCESS = 'ri_real_estate_update_objekt_success',
    RI_REAL_ESTATE_CREATE_OBJEKT_PENDING = 'ri_real_estate_create_objekt_pending',
    RI_REAL_ESTATE_CREATE_OBJEKT_SUCCESS = 'ri_real_estate_create_objekt_success',
    RI_REAL_ESTATE_DELETE_OBJEKT_SUCCESS = 'ri_real_estate_delete_objekt_success',
    RI_REAL_ESTATE_UPLOAD_PICTURE_SUCCESS = 'ri_real_estate_upload_pictures_success',
    RI_REAL_ESTATE_DELETE_PICTURE_SUCCESS = 'ri_real_estate_delete_pictures_success',
    RI_REAL_ESTATE_GET_TEXTS_SUCCESS = 'ri_real_estate_get_texts_success',
    RI_REAL_ESTATE_GET_TEXTS_PENDING = 'ri_real_estate_get_texts_pending',
    RI_REAL_ESTATE_STAKEHOLDER_GET_SUCCESS = 'ri_real_estate_stakeholder_get_success',
    RI_REAL_ESTATE_STAKEHOLDER_CREATE_SUCCESS = 'ri_real_estate_stakeholder_create_success',
    RI_REAL_ESTATE_STAKEHOLDER_UPDATE_SUCCESS = 'ri_real_estate_stakeholder_update_success',
    RI_REAL_ESTATE_STAKEHOLDER_DELETE_SUCCESS = 'ri_real_estate_stakeholder_delete_success',
    RI_REAL_ESTATE_STAKEHOLDER_DELETE_ALL_SUCCESS = 'ri_real_estate_stakeholder_delete_all_success',
    RI_REAL_ESTATE_STAKEHOLDER_DOCUMENT_SUCCESS = 'ri_real_estate_stakeholder_document_success',
    RI_REAL_ESTATE_TEXT_SUPPORT_PENDING = 'ri_real_estate_text_support_pending',
    RI_REAL_ESTATE_TEXT_SUPPORT_SUCCESS = 'ri_real_estate_text_support_success',
}
