export enum ActionType {
    BENIT_ORGANIZATION_CONFIGURATION_USERS_ORGANIZATION_GET_PENDING = 'benit_organization_configuration_organizations_get_pending',
    BENIT_ORGANIZATION_CONFIGURATION_USERS_ORGANIZATION_GET_SUCCESS = 'benit_organization_configuration_organizations_get_success',
    BENIT_ORGANIZATION_CONFIGURATION_CHECK_USER_EMAIL_SUCCESS = 'benit_organization_configuration_check_user_email_success',
    BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_PENDING = 'benit_organization_configuration_add_user_pending',
    BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_SUCCESS = 'benit_organization_configuration_add_user_success',
    BENIT_ORGANIZATION_CONFIGURATION_ADD_USER_ERROR = 'benit_organization_configuration_add_user_error',
    BENIT_ORGANIZATION_CONFIGURATION_USER_ORG_UPDATE_PENDING = 'benit_organization_configuration_update_user_org_pending',
    BENIT_ORGANIZATION_CONFIGURATION_USER_ORG_UPDATE_SUCCESS = 'benit_organization_configuration_update_user_org_success',
    BENIT_ORGANIZATION_CONFIGURATION_PERMISSIONS_GET_PENDING = 'benit_organization_configuration_permissions_get_pending',
    BENIT_ORGANIZATION_CONFIGURATION_PERMISSIONS_GET_SUCCESS = 'benit_organization_configuration_permissions_get_success',
    BENIT_ORGANIZATION_CONFIGURATION_APPLICATIONS_GET_SUCCESS = 'benit_organization_configuration_applications_get_success',
    BENIT_ORGANIZATION_CONFIGURATION_DELETE_USER_SUCCESS = 'benit_organization_configuration_delete_user_success',
    BENIT_ORGANIZATION_CONFIGURATION_USER_PROFILE_UPDATE_PENDING = 'benit_organization_configuration_update_user_profile_pending',
    BENIT_ORGANIZATION_CONFIGURATION_USER_PROFILE_UPDATE_SUCCESS = 'benit_organization_configuration_update_user_profile_success',
}
