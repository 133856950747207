import { ActionType } from '../action-types';
import { Action } from '../actions';

interface BenitCentralUIState {
    errors: {}[] | null;
    helpActivated: boolean;
}

const initialState = {
    errors: null,
    helpActivated: false,
};

const reducer = (state: BenitCentralUIState = initialState, action: Action): BenitCentralUIState => {
    switch (action.type) {
        case ActionType.BENIT_CENTRAL_UI_DELETE_ERROR:
            return {
                ...state,
                errors: state.errors!.filter((error: any) => error !== action.payload),
            };
        case ActionType.BENIT_CENTRAL_UI_DELETE_ALL_ERRORS:
            return {
                ...state,
                errors: null,
            };
        case ActionType.BENIT_CENTRAL_UI_ADD_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case ActionType.BENIT_CENTRAL_SET_HELP_ACTIVATED:
            return {
                ...state,
                helpActivated: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
