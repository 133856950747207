import Avatar from 'react-avatar';
import Select from 'react-select';
import styles from './UserApp.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import MultiLanguageInput from '../../../../../components/MultiLanguageInput/MultiLanguageInput';
import fireToast from '../../../central-ui/methods/fireToast';
import showConfirmPopup from '../../../central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { availableLanguages } from '../../../../../i18n';
import NewUserCard from './components/NewUserCard';

type OptionType = {
    value: string;
    label: string;
};

const UserApp = () => {
    const [newUserPermissionOptions, setNewUserPermissionOptions] = useState<OptionType[] | null>(null);

    const [editUserOrgId, setEditUserOrgId] = useState('');
    const [editUserOrgEmail, setEditUserOrgEmail] = useState('');
    const [editUserOrgFirstName, setEditUserOrgFirstName] = useState('');
    const [editUserOrgLastName, setEditUserOrgLastName] = useState('');
    const [editUserOrgPermissions, setEditUserOrgPermissions] = useState(['']);
    const [editUserOrgStartApplicationId, setEditUserOrgStartApplicationId] = useState<string | null>(null);
    const [editUserApplicationOptions, setEditUserApplicationOptions] = useState<OptionType[] | null>(null);
    const [editUserLanguages, setEditUserLanguages] = useState<string[] | null>(null);
    const [editUserDefaultLanguage, setEditUserDefaultLanguage] = useState<string | null>(null);

    const [searchTerm, setSearchTerm] = useState('');

    const { t } = useTranslation(['general', 'benit-central-configuration', 'benit-organization-configuration']);
    const { mlfv } = useMultiLanguageFieldValue();

    const {
        benit_organization_configuration_get_users_org,
        benit_organization_configuration_check_user_email,
        benit_organization_configuration_update_user_org,
        benit_organization_configuration_get_permissions,
        benit_organization_configuration_get_applications,
        benit_organization_configuration_delete_user,
    } = useActions();

    const { usersOrg, usersOrgLoading, usersOrgDidInvalidate, permissionIdentifiers, editUserApplications, userUpdatedSuccessfully } = useTypedSelector(
        (state) => state.benitOrganizationConfigurationReducer
    );

    const deleteUserOrg = (userOrgId: string, userOrgFirstName: string, userOrgLastName: string) => {
        showConfirmPopup({
            title: t('general:are_you_sure'),
            text: t('general:no_revert'),
            confirmButtonText: t('general:delete'),
            cancelButtonText: t('general:cancel'),
            confirmedFunction: () => {
                benit_organization_configuration_delete_user(userOrgId);
                fireToast('success', `${userOrgFirstName} ${userOrgLastName} ${t('general:deleted')}`);
            },
        });
    };

    const editUserOrg = (
        id: string,
        email: string,
        firstName: string,
        lastName: string,
        permissions: string[],
        startingApplicationId: string | null,
        userLanguages: string[] | null,
        userDefaultLanguage: string | null
    ) => {
        setEditUserOrgId(id);
        setEditUserOrgEmail(email);
        setEditUserOrgFirstName(firstName);
        setEditUserOrgLastName(lastName);
        setEditUserOrgPermissions(permissions);
        setEditUserOrgStartApplicationId(startingApplicationId);
        benit_organization_configuration_get_applications(permissions, 'edit');
        setEditUserLanguages(userLanguages);
        setEditUserDefaultLanguage(userDefaultLanguage);
    };

    const editUserPermissionsChange = (selectedPermissions: any) => {
        const tmpPermissionIdentifiers = selectedPermissions.map((selectedPermission: any) => selectedPermission.value);
        benit_organization_configuration_get_applications(tmpPermissionIdentifiers, 'edit');
        setEditUserOrgPermissions(tmpPermissionIdentifiers);
    };

    const editUserStartApplicationChange = (selectedApplication: any) => {
        if (selectedApplication) {
            setEditUserOrgStartApplicationId(selectedApplication.value);
        }
    };

    const editUserLanguagesChange = (selectedLanguages: any) => {
        const languageKeys = selectedLanguages.map((selectedLanguage: any) => selectedLanguage.value);
        setEditUserLanguages(languageKeys);
    };

    const editUserDefaultLanguageChange = (selectedDefaultLanguage: any) => {
        if (selectedDefaultLanguage) {
            setEditUserDefaultLanguage(selectedDefaultLanguage.value);
        }
    };

    const saveEditedUserOrg = () => {
        benit_organization_configuration_update_user_org(
            editUserOrgId,
            editUserOrgEmail,
            editUserOrgFirstName,
            editUserOrgLastName,
            editUserOrgPermissions,
            editUserOrgStartApplicationId,
            editUserLanguages,
            editUserDefaultLanguage
        );
    };

    const cancelEditedUserOrg = () => {
        setEditUserOrgId('');
        setEditUserOrgEmail('');
        setEditUserOrgFirstName('');
        setEditUserOrgLastName('');
        setEditUserOrgPermissions(['']);
        setEditUserOrgStartApplicationId(null);
        setEditUserLanguages(null);
        setEditUserDefaultLanguage(null);
    };

    useEffect(() => {
        if (userUpdatedSuccessfully && editUserOrgEmail !== '') {
            fireToast('success', `${editUserOrgFirstName} ${editUserOrgLastName} ${t('general:updated')}`);
            setEditUserOrgId('');
            setEditUserOrgEmail('');
            setEditUserOrgFirstName('');
            setEditUserOrgLastName('');
            setEditUserOrgPermissions(['']);
            setEditUserOrgStartApplicationId(null);
            setEditUserLanguages(null);
            setEditUserDefaultLanguage(null);
        }
    }, [userUpdatedSuccessfully]);

    useEffect(() => {
        benit_organization_configuration_get_users_org();
        benit_organization_configuration_get_permissions();
    }, []);

    useEffect(() => {
        if (usersOrgDidInvalidate) {
            benit_organization_configuration_get_users_org(searchTerm);
        }
    }, [usersOrgDidInvalidate]);

    useEffect(() => {
        benit_organization_configuration_get_users_org(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        const options: OptionType[] = permissionIdentifiers
            ? permissionIdentifiers.map((permissionIdentifier) => {
                  return {
                      value: permissionIdentifier,
                      label: permissionIdentifier,
                  };
              })
            : [{ value: '', label: '' }];
        setNewUserPermissionOptions(options);
    }, [permissionIdentifiers]);

    const getApplicationName = (applicationName: { [x: string]: string }) => {
        const applicationNameLanguageSpecific = mlfv(applicationName);
        if (applicationNameLanguageSpecific) {
            return applicationNameLanguageSpecific;
        } else {
            return '';
        }
    };

    useEffect(() => {
        const options: OptionType[] = editUserApplications
            ? editUserApplications.map((editUserApplication) => {
                  return {
                      value: editUserApplication.id,
                      label: `${getApplicationName(editUserApplication.name)} (${editUserApplication.identifier})`,
                  };
              })
            : [{ value: '', label: '' }];
        setEditUserApplicationOptions(options);
    }, [editUserApplications]);

    return (
        <div>
            <div className="row">
                <div className="col-md-4 offset-md-4">{<NewUserCard />}</div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 p-1">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            placeholder={t('benit-organization-configuration:search_user')}
                        />
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                {usersOrg ? (
                    usersOrg.map((userOrg) => {
                        return (
                            <div className="col-md-3 mt-2">
                                <div className="card card-primary card-outline h-100 ">
                                    <div className="card-body box-profile">
                                        {editUserOrgId !== userOrg.id ? (
                                            <div key={userOrg.id}>
                                                <div className="text-center">
                                                    <Avatar name={userOrg.user.firstName + ' ' + userOrg.user.lastName} round=".3rem" size="128" color="grey" />
                                                </div>
                                                <div>
                                                    {' '}
                                                    <h3 className="profile-username text-center">
                                                        {userOrg.user.firstName} {userOrg.user.lastName}
                                                    </h3>
                                                    <p className="text-muted text-center">{userOrg.user.email}</p>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        <li className="list-group-item">
                                                            <div className="form-group">
                                                                <label>{t('benit-organization-configuration:permissions')}</label>
                                                                <div className="form-group">
                                                                    <select multiple disabled className="form-control">
                                                                        {userOrg.permissions.map(({ identifier }) => {
                                                                            return <option className="small">{identifier}</option>;
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="form-group">
                                                                <label>{t('benit-organization-configuration:start_application')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control control-border"
                                                                    value={
                                                                        userOrg.startApplication ? `${getApplicationName(userOrg.startApplication.name)} (${userOrg.startApplication.identifier})` : ''
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="form-group">
                                                                <label>{t('benit-organization-configuration:languages')}</label>
                                                                <div className="form-group">
                                                                    <select multiple disabled className="form-control">
                                                                        {userOrg.userLanguages
                                                                            ? userOrg.userLanguages.map((userLanguage) => {
                                                                                  return <option className="small">{t(`general:${userLanguage}`)}</option>;
                                                                              })
                                                                            : null}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="form-group">
                                                                <label>{t('benit-organization-configuration:default_language')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control control-border"
                                                                    value={userOrg.userDefaultLanguage ? t(`general:${userOrg.userDefaultLanguage}`).toString() : ''}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <button
                                                            className="btn btn-primary btn-block"
                                                            onClick={(e) =>
                                                                editUserOrg(
                                                                    userOrg.id,
                                                                    userOrg.user.email,
                                                                    userOrg.user.firstName,
                                                                    userOrg.user.lastName,
                                                                    userOrg.permissions.map(({ identifier }) => {
                                                                        return identifier;
                                                                    }),
                                                                    userOrg.startApplication ? userOrg.startApplication.id : null,
                                                                    userOrg.userLanguages ? userOrg.userLanguages : null,
                                                                    userOrg.userDefaultLanguage ? userOrg.userDefaultLanguage : null
                                                                )
                                                            }
                                                        >
                                                            <b>{t('general:edit')}</b>
                                                        </button>

                                                        <button className="btn btn-danger btn-block" onClick={(e) => deleteUserOrg(userOrg.user.id, userOrg.user.firstName, userOrg.user.lastName)}>
                                                            <b>{t('general:delete')}</b>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={userOrg.id}>
                                                <div className="text-center">
                                                    <Avatar name={editUserOrgFirstName + ' ' + editUserOrgLastName} size="128" round=".3rem" color="green" />
                                                </div>

                                                <div className="mt-2 input-group mb-3">
                                                    <input
                                                        type="email"
                                                        className="form-control control-border"
                                                        placeholder={t('benit-organization-configuration:user_email')}
                                                        value={editUserOrgEmail}
                                                        onChange={(e) => setEditUserOrgEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="mt-2 input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control control-border"
                                                        placeholder={t('benit-organization-configuration:user_first_name')}
                                                        value={editUserOrgFirstName}
                                                        onChange={(e) => setEditUserOrgFirstName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mt-2 input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control control-border"
                                                        placeholder={t('benit-organization-configuration:user_last_name')}
                                                        value={editUserOrgLastName}
                                                        onChange={(e) => setEditUserOrgLastName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="mt-2 mb-3">
                                                    <div className="form-group">
                                                        <label>{t('benit-organization-configuration:permissions')}</label>
                                                        <Select
                                                            placeholder={t('benit-organization-configuration:choose_permissions')}
                                                            noOptionsMessage={({ inputValue }) => t('benit-organization-configuration:no_permissions_left')}
                                                            isMulti
                                                            defaultValue={editUserOrgPermissions.map((identifier) => {
                                                                return {
                                                                    value: identifier,
                                                                    label: identifier,
                                                                };
                                                            })}
                                                            name="colors"
                                                            options={newUserPermissionOptions ? newUserPermissionOptions : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={editUserPermissionsChange}
                                                        />
                                                    </div>
                                                </div>

                                                {editUserApplications && editUserApplications.length > 0 ? (
                                                    <>
                                                        <div className="mt-2  input-group mb-3 border-bottom"></div>

                                                        <div className="mt-2  mb-3">
                                                            <div className="form-group">
                                                                <label>{t('benit-organization-configuration:start_application')}</label>
                                                                <Select
                                                                    placeholder={t('benit-organization-configuration:choose_start_application')}
                                                                    defaultValue={[
                                                                        {
                                                                            label: userOrg.startApplication
                                                                                ? `${getApplicationName(userOrg.startApplication.name)} (${userOrg.startApplication.identifier})`
                                                                                : '',
                                                                            value: userOrg.startApplication ? `${userOrg.startApplication.id}` : '',
                                                                        },
                                                                    ]}
                                                                    name="startApplication"
                                                                    options={editUserApplicationOptions ? editUserApplicationOptions : []}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    onChange={editUserStartApplicationChange}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}

                                                <div className="mt-2 input-group mb-3 border-bottom"></div>

                                                <div className="mt-2 mb-3">
                                                    <div className="form-group">
                                                        <label>{t('benit-organization-configuration:languages')}</label>
                                                        <Select
                                                            placeholder={t('benit-organization-configuration:choose_languages')}
                                                            noOptionsMessage={({ inputValue }) => t('benit-central-configuration:no_languages_left')}
                                                            isMulti
                                                            defaultValue={
                                                                editUserLanguages
                                                                    ? editUserLanguages.map((languageKey) => {
                                                                          return {
                                                                              label: t(`general:${languageKey}`),
                                                                              value: languageKey,
                                                                          };
                                                                      })
                                                                    : []
                                                            }
                                                            name="languages"
                                                            options={availableLanguages.map((languageKey) => {
                                                                const label = t(`general:${languageKey}`);
                                                                const value = languageKey;
                                                                return { label, value };
                                                            })}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={editUserLanguagesChange}
                                                        />
                                                    </div>
                                                </div>

                                                {editUserLanguages && editUserLanguages.length > 0 ? (
                                                    <>
                                                        <div className="mt-2 mb-3">
                                                            <div className="form-group">
                                                                <label>{t('benit-organization-configuration:default_language')}</label>
                                                                <Select
                                                                    placeholder={t('benit-organization-configuration:choose_default_language')}
                                                                    name="startApplication"
                                                                    defaultValue={[
                                                                        {
                                                                            label: editUserDefaultLanguage ? t(`general:${editUserDefaultLanguage}`) : '',
                                                                            value: editUserDefaultLanguage ? editUserDefaultLanguage : null,
                                                                        },
                                                                    ]}
                                                                    options={editUserLanguages.map((languageKey) => {
                                                                        const label = t(`general:${languageKey}`);
                                                                        const value = languageKey;
                                                                        return { label, value };
                                                                    })}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    onChange={editUserDefaultLanguageChange}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}

                                                <button className="btn btn-primary btn-block" onClick={(e) => saveEditedUserOrg()}>
                                                    <b>{t('general:save')}</b>
                                                </button>
                                                <button className="btn btn-secondary btn-block" onClick={(e) => cancelEditedUserOrg()}>
                                                    <b>{t('general:cancel')}</b>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="offset-md-3 col-md-6 mt-2 p-1 alert alert-info">
                        <h5>
                            <i className="icon fas fa-info"></i> {searchTerm.length > 0 ? t('benit-organization-configuration:no_users_found') : t('benit-organization-configuration:no_users_yet')}
                            {searchTerm}
                        </h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserApp;
