import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';

export const benit_auth_loginUser = (email: string, password: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.LOGIN_USER_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/benit/auth/users/signin',
            method: 'post',
            body: {
                email,
                password,
            },
            onSuccess: (data) => {
                dispatch(actionCreators.benit_auth_getUserOrgs());

                dispatch({
                    type: ActionType.LOGIN_USER_SUCCESS,
                    payload: data,
                });
            },
            onError: (error) => {
                dispatch({
                    type: ActionType.LOGIN_USER_ERROR,
                    payload: error,
                });
            },
        });

        doRequest();
    };
};

export const benit_auth_logoutUser = () => {
    return async (dispatch: Dispatch<Action>) => {
        const { doRequest } = useRequest({
            url: '/api/benit/auth/users/signout',
            method: 'post',
            body: {},
            onSuccess: (data) =>
                dispatch({
                    type: ActionType.LOGOUT_USER_SUCCESS,
                }),
            onError: (error) =>
                dispatch({
                    type: ActionType.LOGOUT_USER_ERROR,
                    payload: error,
                }),
        });

        doRequest();
    };
};

export const benit_auth_getCurrentUser = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.CURRENT_USER_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/benit/auth/users/currentuser',
            method: 'get',
            body: {},
            onSuccess: (data) => {
                if (data.currentUser) {
                    dispatch(actionCreators.benit_auth_getUserOrgs());
                }

                dispatch({
                    type: ActionType.CURRENT_USER_SUCCESS,
                    payload: data,
                });
            },
            onError: (error) =>
                dispatch({
                    type: ActionType.CURRENT_USER_ERROR,
                    payload: error,
                }),
        });

        doRequest();
    };
};

export const benit_auth_getUserOrgs = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: '/api/benit/organization-configuration/current-user-orgs',
            method: 'get',
            body: {},
            onSuccess: (data) => {
                if (data.organizations.length === 1) {
                    dispatch(actionCreators.benit_auth_chooseOrg(data.organizations[0].id));
                } else if (data.chosenOrg) {
                    dispatch(actionCreators.benit_auth_chooseOrg(data.chosenOrg.id));
                }

                dispatch({
                    type: ActionType.CURRENT_USER_ORGS_SUCCESS,
                    payload: data,
                });
            },
            onError: (error) => {},
        });

        doRequest();
    };
};

export const benit_auth_chooseOrg = (organizationId: string) => {
    return async (dispatch: Dispatch<Action>) => {
        const { doRequest } = useRequest({
            url: `/api/benit/organization-configuration/current-org-data/${organizationId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.CURRENT_ORG_CHOSEN_SUCCESS,
                    payload: data,
                });
            },
            onError: (error) => {},
        });

        doRequest();
    };
};

export const benit_auth_setPassword = (userId: string, passwordToken: string, password: string) => {
    return async (dispatch: Dispatch<Action>) => {
        const { doRequest } = useRequest({
            url: `/api/benit/auth/users/setpassword/${userId}/${passwordToken}`,
            method: 'post',
            body: { password },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.PASSWORD_SET_SUCCESS,
                    payload: data,
                });
            },
            onError: (error) => {
                dispatch({
                    type: ActionType.PASSWORD_SET_ERROR,
                    payload: error,
                });
            },
        });

        doRequest();
    };
};

export const benit_auth_validate_setPassword_url = (userId: string, passwordToken: string) => {
    return async (dispatch: Dispatch<Action>) => {
        const { doRequest } = useRequest({
            url: `/api/benit/auth/users/setpassword/${userId}/${passwordToken}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {},
            onError: (error) => {
                dispatch({
                    type: ActionType.PASSWORD_URL_VALIDATE_ERROR,
                    payload: error,
                });
            },
        });

        doRequest();
    };
};

export const benit_auth_resetPassword = (email: string, languageKey: string | null = 'en') => {
    return async (dispatch: Dispatch<Action>) => {
        const { doRequest } = useRequest({
            url: `/api/benit/auth/users/resetpassword`,
            method: 'post',
            body: { email, languageKey },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.PASSWORD_RESET_SUCCESS,
                    payload: data,
                });
            },
            onError: (error) => {},
        });

        doRequest();
    };
};

export const benit_auth_loginRedirect = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.LOGIN_REDIRECT,
        });
    };
};
