import { getI18n, useTranslation } from 'react-i18next';
import { useState } from 'react';

interface MLIInterface {
    type: string;
    className: string;
    placeholderLanguageNamespace: string;
    placeholderLanguageKey: string;
    stateValue: { [x: string]: string };
    stateFunction: (e: any) => void;
}

const MultiLanguageInput = ({
    type,
    className,
    placeholderLanguageNamespace,
    placeholderLanguageKey,
    stateValue,
    stateFunction,
}: MLIInterface) => {
    const { t } = useTranslation([placeholderLanguageNamespace]);
    const [showAdditionalLanguages, setShowAdditionalLanguages] =
        useState(false);

    const otherLanguages = getI18n().languages.map((language: string) => {
        if (language !== getI18n().language) {
            return (
                <div className={className}>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                {language.toUpperCase()}
                            </span>
                        </div>
                        <input
                            type={type}
                            className="form-control"
                            placeholder={t(
                                `${placeholderLanguageNamespace}:${placeholderLanguageKey}`,
                                { lng: language }
                            )}
                            value={
                                stateValue[language] ? stateValue[language] : ''
                            }
                            onChange={(e) =>
                                stateFunction({
                                    ...stateValue,
                                    [language]: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            );
        }
        if (language === getI18n().language) {
            return null;
        }
    });

    return (
        <div>
            <div className={className}>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            {getI18n().language.toUpperCase()}
                        </span>
                    </div>
                    <input
                        type={type}
                        className="form-control"
                        placeholder={t(
                            `${placeholderLanguageNamespace}:${placeholderLanguageKey}`
                        )}
                        value={
                            stateValue[getI18n().language]
                                ? stateValue[getI18n().language]
                                : ''
                        }
                        onChange={(e) =>
                            stateFunction({
                                ...stateValue,
                                [getI18n().language]: e.target.value,
                            })
                        }
                    />
                    <div className="input-group-append">
                        <button
                            type="button"
                            className={`btn ${
                                showAdditionalLanguages
                                    ? 'btn-primary'
                                    : 'btn-default'
                            }`}
                            onClick={(e) =>
                                setShowAdditionalLanguages(
                                    !showAdditionalLanguages
                                )
                            }
                        >
                            <i className="fas fa-globe"></i>
                        </button>
                    </div>
                </div>
            </div>
            {showAdditionalLanguages ? otherLanguages : null}
        </div>
    );
};

export default MultiLanguageInput;
