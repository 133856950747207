import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const ri_real_estate_get_text_support = (dataTmp: any, textType: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_REAL_ESTATE_TEXT_SUPPORT_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/text-support`,
            method: 'post',
            body: { data: dataTmp, textType },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_TEXT_SUPPORT_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
