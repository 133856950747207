// @ts-nocheck
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import ChatWrapper from './Chat/ChatWrapper';

const WegApp = () => {
    const { t } = useTranslation(['general', 'benit-central-configuration', 'ri-property-management']);

    const {
        ri_property_management_get_weg_beschluesse,
        ri_property_management_new_weg_beschluss,
        ri_property_management_update_weg_beschluss,
        ri_property_management_delete_weg_beschluss,
        ri_property_management_get_weg_versammlungen,
        ri_property_management_new_weg_versammlungen,
        ri_property_management_update_weg_versammlungen,
        ri_property_management_delete_weg_versammlungen,
        ri_property_management_get_current_user_eigentuemer,
        ri_property_management_weg_beschluss_pdf_download,
        ri_property_management_weg_versammlungen_pdf_download,
        benit_central_set_help_activated,
    } = useActions();

    const { refreshDataSuccess, currentUserEigentuemer, wegBeschluesse, wegVersammlungen, wegBeschlussDidInvalidate, wegVersammlungenDidInvalidate } = useTypedSelector(
        (state) => state.riPropertyManagementReducer
    );

    const { helpActivated } = useTypedSelector((state) => state.benitCentralUIReducer);

    const { permissionIdentifiers } = useTypedSelector((state) => state.benitAuthReducer);

    const [selectedEigentuemer, setSelectedEigentuemer] = useState('0');
    const [userIsAdmin, setUserIsAdmin] = useState(false);

    const [tourActive, setTourActive] = useState(false);

    const textAdmin = '## Heading 2 \n * List item \n * List item';

    const [neuerBeschluss, setNeuerBeschluss] = useState<{ eigentuemerEid: string; beschlussnummer: string; beschreibung: string; pdfName: any }>({
        eigentuemerEid: '',
        beschlussnummer: '',
        beschreibung: '',
        pdfName: null,
    });

    const [editBeschluss, setEditBeschluss] = useState<{ id: string; eigentuemerEid: string; beschlussnummer: string; beschreibung: string; pdfName: any }>({
        id: '',
        eigentuemerEid: '',
        beschlussnummer: '',
        beschreibung: '',
        pdfName: null,
    });

    const [neueVersammlung, setNeueVersammlung] = useState<{ eigentuemerEid: string; versammlungsnummer: string; datum: string; ort: string; pdfName: any }>({
        eigentuemerEid: '',
        versammlungsnummer: '',
        datum: '',
        ort: '',
        pdfName: null,
    });

    const [editVersammlung, setEditVersammlung] = useState<{ id: string; eigentuemerEid: string; versammlungsnummer: string; datum: string; ort: string; pdfName: any }>({
        id: '',
        eigentuemerEid: '',
        versammlungsnummer: '',
        datum: '',
        ort: '',
        pdfName: null,
    });

    const stepsAdmin = [
        {
            target: '.content-wrapper',
            content: 'In dieser Applikation kannst du Sitzungsprotokolle und Beschlüsse für alle WEG Eigentümer hochladen. Außerdem können alle miteinander chatten.',
            disableBeacon: true,
        },
        {
            target: '.tour-beschluesse',
            content: 'In diesem Bereich werden alle Beschlüsse angezeigt',
            isFixed: true,
        },
        {
            target: '.tour-beschluss-button',
            content: 'Um einen Beschluss hinzuzufügen, diesen Button drücken',
            isFixed: true,
        },
        {
            target: '.tour-sitzungsprotokolle',
            content: 'In diesem Bereich werden Sitzungsprotkolle hinzugefügt',
            isFixed: true,
        },
        {
            target: '.tour-sitzungsprotokolle',
            content: 'In diesem Bereich werden Sitzungsprotkolle hinzugefügt',
            isFixed: true,
        },
    ];

    useEffect(() => {
        ri_property_management_get_current_user_eigentuemer();
    }, []);

    useEffect(() => {
        if (currentUserEigentuemer && currentUserEigentuemer.length === 1) {
            changeEigentuemer(currentUserEigentuemer[0].eid);
        }
    }, [currentUserEigentuemer]);

    useEffect(() => {
        if (permissionIdentifiers && permissionIdentifiers.includes('ri-property-management-weg-admin-permission')) {
            setUserIsAdmin(true);
        }
    }, [permissionIdentifiers]);

    useEffect(() => {
        if (wegBeschlussDidInvalidate || wegVersammlungenDidInvalidate) {
            changeEigentuemer(selectedEigentuemer);
        }
    }, [wegBeschlussDidInvalidate, wegVersammlungenDidInvalidate]);

    const changeEigentuemer = (eid: string) => {
        if (currentUserEigentuemer) {
            setSelectedEigentuemer(eid);
            setNeuerBeschluss({
                eigentuemerEid: eid,
                beschlussnummer: '',
                beschreibung: '',
                pdfName: null,
            });
            setEditBeschluss({
                eigentuemerEid: eid,
                id: '',
                beschlussnummer: '',
                beschreibung: '',
                pdfName: null,
            });
            setNeueVersammlung({
                eigentuemerEid: eid,
                versammlungsnummer: '',
                datum: '',
                ort: '',
                pdfName: null,
            });
            setEditVersammlung({
                id: '',
                eigentuemerEid: eid,
                versammlungsnummer: '',
                datum: '',
                ort: '',
                pdfName: null,
            });
            ri_property_management_get_weg_beschluesse(eid);
            ri_property_management_get_weg_versammlungen(eid);
        }
    };

    const toDateFull = (date: Date) => {
        return new Intl.DateTimeFormat('de-de', { dateStyle: 'full', timeStyle: 'short', timeZone: 'UTC' }).format(new Date(date).getTime());
    };

    const toDateMedium = (date: Date) => {
        return new Intl.DateTimeFormat('de-de', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'UTC' }).format(new Date(date).getTime());
    };

    return (
        <div>
            {currentUserEigentuemer && currentUserEigentuemer.length > 1 ? (
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="form-group">
                            <select className="form-control" onChange={(e) => changeEigentuemer(e.target.value)}>
                                {selectedEigentuemer === '0' ? <option value="0">{t('ri-property-management:select_owner')}</option> : null}
                                {currentUserEigentuemer
                                    ? currentUserEigentuemer.map((e) => {
                                          if (selectedEigentuemer === '0') {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          } else if (selectedEigentuemer === e.eid) {
                                              return (
                                                  <option value={e.eid} selected>
                                                      {e.nachname}
                                                  </option>
                                              );
                                          } else if (selectedEigentuemer !== e.eid) {
                                              return <option value={e.eid}>{e.nachname}</option>;
                                          }
                                      })
                                    : null}
                            </select>
                        </div>
                    </div>
                </div>
            ) : null}
            {selectedEigentuemer !== '0' ? (
                <div className="row mt-2">
                    <div className="col-md-4">
                        <div className="info-box bg-warning">
                            <span className="info-box-icon">
                                <i className="far fa-calendar-alt"></i>
                            </span>

                            <div className="info-box-content">
                                <span className="info-box-text">Nächste WEG Sitzung</span>
                                <span className="info-box-number">
                                    {wegVersammlungen && wegVersammlungen[wegVersammlungen.length - 1] ? `${toDateFull(new Date(wegVersammlungen[wegVersammlungen.length - 1].datum))} Uhr` : null}{' '}
                                    {' in '}
                                    {wegVersammlungen && wegVersammlungen[wegVersammlungen.length - 1] ? wegVersammlungen[wegVersammlungen.length - 1].ort : null}
                                </span>
                            </div>
                        </div>
                        <div className="card tour-beschluesse">
                            <div className="card-header">
                                <h3 className="card-title">Beschlüsse</h3>
                            </div>
                            <div className="card-body p-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10px' }}>#</th>
                                            <th>Beschluss</th>
                                            <th style={{ width: '130px' }}>Download</th>
                                            {userIsAdmin ? <th style={{ width: '130px' }}>Aktionen</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {wegBeschluesse
                                            ? wegBeschluesse.map((beschluss) => {
                                                  if (beschluss.id !== editBeschluss.id) {
                                                      return (
                                                          <tr>
                                                              <td className="align-middle">{beschluss.beschlussnummer}</td>
                                                              <td className="align-middle">{beschluss.beschreibung}</td>
                                                              <td>
                                                                  {beschluss.pdfName ? (
                                                                      <button
                                                                          type="button"
                                                                          className="btn btn-info btn-sm mr-2"
                                                                          onClick={(e) => ri_property_management_weg_beschluss_pdf_download(beschluss.id ? beschluss.id : '')}
                                                                      >
                                                                          <i className="fas fa-cloud-download-alt mr-1"></i>
                                                                          Download
                                                                      </button>
                                                                  ) : null}
                                                              </td>
                                                              {userIsAdmin ? (
                                                                  <td>
                                                                      <button
                                                                          type="button"
                                                                          className="btn btn-info btn-sm mr-2"
                                                                          onClick={(e) =>
                                                                              setEditBeschluss({
                                                                                  ...editBeschluss,
                                                                                  beschlussnummer: beschluss.beschlussnummer,
                                                                                  beschreibung: beschluss.beschreibung,
                                                                                  pdfName: beschluss.pdfName ? beschluss.pdfName : '',
                                                                                  id: beschluss.id ? beschluss.id : '',
                                                                              })
                                                                          }
                                                                      >
                                                                          <i className="fas fa-pencil-alt mr-1"></i>
                                                                      </button>
                                                                      <button
                                                                          type="button"
                                                                          className="btn btn-info btn-sm mr-2"
                                                                          onClick={(e) => ri_property_management_delete_weg_beschluss(beschluss.id ? beschluss.id : '', selectedEigentuemer)}
                                                                      >
                                                                          <i className="fas fa-trash-alt mr-1"></i>
                                                                      </button>
                                                                  </td>
                                                              ) : null}
                                                          </tr>
                                                      );
                                                  } else {
                                                      return (
                                                          <tr>
                                                              <td className="align-middle"></td>
                                                              <td className="align-middle">
                                                                  <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Beschlussnummer"
                                                                      value={editBeschluss.beschlussnummer}
                                                                      onChange={(e) =>
                                                                          setEditBeschluss({
                                                                              ...editBeschluss,
                                                                              beschlussnummer: e.target.value,
                                                                          })
                                                                      }
                                                                  />
                                                                  <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Beschreibung"
                                                                      value={editBeschluss.beschreibung}
                                                                      onChange={(e) =>
                                                                          setEditBeschluss({
                                                                              ...editBeschluss,
                                                                              beschreibung: e.target.value,
                                                                          })
                                                                      }
                                                                  />
                                                                  <input
                                                                      type="file"
                                                                      onChange={(e) =>
                                                                          setEditBeschluss({
                                                                              ...editBeschluss,
                                                                              pdfName: e.target.files ? e.target.files[0] : null,
                                                                          })
                                                                      }
                                                                  />
                                                              </td>
                                                              <td className="align-middle">
                                                                  <button
                                                                      type="button"
                                                                      className="btn btn-info btn-sm mr-2"
                                                                      onClick={(e) => ri_property_management_update_weg_beschluss(editBeschluss)}
                                                                  >
                                                                      Speichern
                                                                  </button>
                                                              </td>
                                                          </tr>
                                                      );
                                                  }
                                              })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            {userIsAdmin ? (
                                <div className="card-footer">
                                    <div className="card card-success m-2 collapsed-card">
                                        <div className="btn btn-sm btn-success btn-block tour-beschluss-button" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                            {t('ri-property-management:add_decision')}
                                        </div>
                                        <div className="card-body box-profile">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Beschlussnummer"
                                                    value={neuerBeschluss.beschlussnummer}
                                                    onChange={(e) =>
                                                        setNeuerBeschluss({
                                                            ...neuerBeschluss,
                                                            beschlussnummer: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Beschreibung"
                                                    value={neuerBeschluss.beschreibung}
                                                    onChange={(e) =>
                                                        setNeuerBeschluss({
                                                            ...neuerBeschluss,
                                                            beschreibung: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="file"
                                                    onChange={(e) =>
                                                        setNeuerBeschluss({
                                                            ...neuerBeschluss,
                                                            pdfName: e.target.files ? e.target.files[0] : null,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="input-group">
                                                <button className="btn btn-block btn-sm btn-primary" onClick={(e) => ri_property_management_new_weg_beschluss(neuerBeschluss)}>
                                                    Beschluss Speichern
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="card tour-sitzungsprotokolle">
                            <div className="card-header">
                                <h3 className="card-title">WEG Sitzungsprotokolle</h3>
                            </div>
                            <div className="card-body p-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10px' }}>#</th>
                                            <th>Datum</th>
                                            <th style={{ width: '160px' }}>Protokoll</th>
                                            {userIsAdmin ? <th style={{ width: '130px' }}>Aktionen</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {wegVersammlungen
                                            ? wegVersammlungen.map((versammlung) => {
                                                  if (versammlung.id !== editVersammlung.id) {
                                                      return (
                                                          <tr>
                                                              <td className="align-middle">{versammlung.versammlungsnummer}</td>
                                                              <td className="align-middle">{toDateMedium(new Date(versammlung.datum))} Uhr</td>
                                                              <td>
                                                                  {versammlung.pdfName ? (
                                                                      <button
                                                                          type="button"
                                                                          className="btn btn-info btn-sm mr-2"
                                                                          onClick={(e) => ri_property_management_weg_versammlungen_pdf_download(versammlung.id ? versammlung.id : '')}
                                                                      >
                                                                          <i className="fas fa-cloud-download-alt mr-1"></i>
                                                                          Download
                                                                      </button>
                                                                  ) : null}
                                                              </td>
                                                              {userIsAdmin ? (
                                                                  <td>
                                                                      <button
                                                                          type="button"
                                                                          className="btn btn-info btn-sm mr-2"
                                                                          onClick={(e) =>
                                                                              setEditVersammlung({
                                                                                  ...editVersammlung,
                                                                                  versammlungsnummer: versammlung.versammlungsnummer,
                                                                                  ort: versammlung.ort,
                                                                                  datum: versammlung.datum,
                                                                                  pdfName: versammlung.pdfName ? versammlung.pdfName : '',
                                                                                  id: versammlung.id ? versammlung.id : '',
                                                                              })
                                                                          }
                                                                      >
                                                                          <i className="fas fa-pencil-alt mr-1"></i>
                                                                      </button>
                                                                      <button
                                                                          type="button"
                                                                          className="btn btn-info btn-sm mr-2"
                                                                          onClick={(e) => ri_property_management_delete_weg_versammlungen(versammlung.id ? versammlung.id : '', selectedEigentuemer)}
                                                                      >
                                                                          <i className="fas fa-trash-alt mr-1"></i>
                                                                      </button>
                                                                  </td>
                                                              ) : null}
                                                          </tr>
                                                      );
                                                  } else {
                                                      return (
                                                          <tr>
                                                              <td className="align-middle"></td>
                                                              <td className="align-middle">
                                                                  <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Versammlungsnummer"
                                                                      value={editVersammlung.versammlungsnummer}
                                                                      onChange={(e) =>
                                                                          setEditVersammlung({
                                                                              ...editVersammlung,
                                                                              versammlungsnummer: e.target.value,
                                                                          })
                                                                      }
                                                                  />
                                                                  <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Ort"
                                                                      value={editVersammlung.ort}
                                                                      onChange={(e) =>
                                                                          setEditVersammlung({
                                                                              ...editVersammlung,
                                                                              ort: e.target.value,
                                                                          })
                                                                      }
                                                                  />
                                                                  <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Datum (z.B. '12.12.2021, 14:00')"
                                                                      value={editVersammlung.datum}
                                                                      onChange={(e) =>
                                                                          setEditVersammlung({
                                                                              ...editVersammlung,
                                                                              datum: e.target.value,
                                                                          })
                                                                      }
                                                                  />
                                                                  <input
                                                                      type="file"
                                                                      onChange={(e) =>
                                                                          setEditVersammlung({
                                                                              ...editVersammlung,
                                                                              pdfName: e.target.files ? e.target.files[0] : null,
                                                                          })
                                                                      }
                                                                  />
                                                              </td>
                                                              <td className="align-middle">
                                                                  <button
                                                                      type="button"
                                                                      className="btn btn-info btn-sm mr-2"
                                                                      onClick={(e) => ri_property_management_update_weg_versammlungen(editVersammlung)}
                                                                  >
                                                                      Speichern
                                                                  </button>
                                                              </td>
                                                          </tr>
                                                      );
                                                  }
                                              })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            {userIsAdmin ? (
                                <div className="card-footer">
                                    <div className="card card-success m-2 collapsed-card">
                                        <div className="btn btn-sm btn-success btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                            {t('ri-property-management:add_meeting')}
                                        </div>
                                        <div className="card-body box-profile">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Versammlungsnummer"
                                                    value={neueVersammlung.versammlungsnummer}
                                                    onChange={(e) =>
                                                        setNeueVersammlung({
                                                            ...neueVersammlung,
                                                            versammlungsnummer: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Ort"
                                                    value={neueVersammlung.ort}
                                                    onChange={(e) =>
                                                        setNeueVersammlung({
                                                            ...neueVersammlung,
                                                            ort: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Datum (z.B. '12.12.2021, 14:00')"
                                                    value={neueVersammlung.datum}
                                                    onChange={(e) =>
                                                        setNeueVersammlung({
                                                            ...neueVersammlung,
                                                            datum: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="file"
                                                    onChange={(e) =>
                                                        setNeueVersammlung({
                                                            ...neueVersammlung,
                                                            pdfName: e.target.files ? e.target.files[0] : null,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="input-group">
                                                <button className="btn btn-block btn-sm btn-primary" onClick={(e) => ri_property_management_new_weg_versammlungen(neueVersammlung)}>
                                                    Versammlung Speichern
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-8">
                        <ChatWrapper eigentuemer={selectedEigentuemer} userIsAdmin={userIsAdmin} />
                    </div>
                </div>
            ) : null}

            <div className="control-sidebar control-sidebar-dark" style={{ width: '100%', right: '0px', zIndex: 2000, opacity: '98%' }}>
                <div className="p-3 control-sidebar-content">
                    <div className="col-md-4  offset-md-4">
                        <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={(e) => {
                                benit_central_set_help_activated(false);
                                setTourActive(true);
                            }}
                        >
                            Start Tour
                        </button>
                    </div>

                    <ReactMarkdown className="text-wrap markdown-body" children={textAdmin} remarkPlugins={[remarkGfm]} />
                </div>
            </div>
        </div>
    );
};

export default WegApp;
