import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const ri_property_management_get_configuration = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/configuration`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_CONFIGURATION_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_change_configuration = (configIdentifier: string, properties: { [x: string]: string }) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_CONFIGURATION_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/ri/property-management/configuration',
            method: 'put',
            body: { configIdentifier, properties },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_UPDATE_CONFIGURATION_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};
