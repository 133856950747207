import { useActions } from '../../../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../../../hooks/useTypedSelector';
import { getI18n, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useEffect } from 'react';

const LanguagePicker = () => {
    const { languageKey } = useTypedSelector((state) => state.benitCentralConfigurationReducer);
    const { benit_central_configuration_change_language, benit_central_configuration_language_changed } = useActions();
    const { userLanguages, signedIn } = useTypedSelector((state) => state.benitAuthReducer);
    const { t, i18n } = useTranslation(['general']);

    const otherLanguageButtons =
        userLanguages && signedIn
            ? userLanguages.map((language: string) => {
                  const languageFlagIcon = language === 'en' ? 'us' : language === 'ru' ? 'ua' : language;
                  return (
                      <button onClick={() => benit_central_configuration_change_language(language)} className={`dropdown-item ${languageKey == language ? 'active' : ''}`}>
                          <i className={`flag-icon flag-icon-${languageFlagIcon} mr-2`}></i> {t(`general:${language}`)}
                      </button>
                  );
              })
            : i18next.languages.map((language: string) => {
                  const languageFlagIcon = language === 'en' ? 'us' : language === 'ru' ? 'ua' : language;
                  return (
                      <button
                          onClick={() => {
                              i18n.changeLanguage(language, benit_central_configuration_language_changed);
                              document.documentElement.lang = language;
                          }}
                          className={`dropdown-item ${i18n.language == language ? 'active' : ''}`}
                      >
                          <i className={`flag-icon flag-icon-${languageFlagIcon} mr-2`}></i> {t(`general:${language}`)}
                      </button>
                  );
              }, {});

    useEffect(() => {
        if (languageKey) {
            document.documentElement.lang = languageKey;
        }
    }, [languageKey]);

    return (
        <>
            <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }}>
                    <i
                        className={`flag-icon flag-icon-${languageKey && signedIn ? (languageKey != 'en' ? languageKey : 'us') : ''}${
                            !languageKey || !signedIn ? (i18n.language != 'en' ? i18n.language : 'us') : ''
                        }`}
                    ></i>
                </a>
                <div
                    className="dropdown-menu dropdown-menu-right p-0"
                    style={{
                        left: 'inherit',
                        right: '0px',
                    }}
                >
                    {otherLanguageButtons}
                </div>
            </li>
        </>
    );
};

export default LanguagePicker;
