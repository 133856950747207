import axios, { AxiosError } from 'axios';

interface RequestInput {
    url: string;
    method: string;
    body: { [x: string]: any };
    file?: any;
    onSuccess(response: any): void;
    onError(error: any): void;
}

function isAxiosError(error: any): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
}

const useRequest = ({ url, method, body, file, onSuccess, onError }: RequestInput) => {
    let errors = null;

    const doRequest = async (props = {}) => {
        try {
            let adaptedUrl = url;
            const host = window.location.host;
            if (host == 'localhost') {
                adaptedUrl = 'https://app.benit-solutions.com' + adaptedUrl;
            }
            let response: any;
            if (!file) {
                //@ts-ignore
                response = await axios[method](adaptedUrl, {
                    ...body,
                    ...props,
                    crossdomain: true,
                });
            } else {
                const formData = new FormData();
                formData.append('file', file);
                Object.keys(body).forEach((key) => formData.append(key, body[key]));
                //@ts-ignore
                response = await axios[method](adaptedUrl, formData, {
                    ...body,
                    ...props,
                    crossdomain: true,
                });
            }

            if (onSuccess) {
                onSuccess(response.data);
            }

            return response.data;
        } catch (err: any) {
            console.log('err: ' + err + 'url: ' + url);
            if (err.response) {
                errors = err.response.data.errors;
                if (onError) {
                    onError(errors);
                }
            }
        }
    };

    return { doRequest, errors };
};

export default useRequest;
