export enum ActionType {
    BENIT_CARE_ASSISTANT_ASK_ASSISTANT_PENDING = 'benit_care_assistant_ask_assistant_pending',
    BENIT_CARE_ASSISTANT_ASK_ASSISTANT_SUCCESS = 'benit_care_assistant_ask_assistant_success',
    BENIT_CARE_ASSISTANT_ASK_ASSISTANT_FAIL = 'benit_care_assistant_ask_assistant_fail',
    BENIT_CARE_ASSISTANT_NEW_REPORT_PDF_PENDING = 'benit_care_assistant_new_report_pdf_pending',
    BENIT_CARE_ASSISTANT_NEW_REPORT_PDF_AVAILABLE = 'benit_care_assistant_new_report_pdf_available',
    BENIT_CARE_ASSISTANT_UPDATE_ANSWER = 'benit_care_assistant_update_answer',
    BENIT_CARE_ASSISTANT_TRANSCRIPTION_PENDING = 'benit_care_assistant_transcription_pending',
    BENIT_CARE_ASSISTANT_TRANSCRIPTION_SUCCESS = 'benit_care_assistant_transcription_success',
}
