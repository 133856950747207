export enum ActionType {
    BENIT_ACCOUNTING_CONFIGURATION_GET_SUCCESS = 'benit_accounting_configuration_get_success',
    BENIT_ACCOUNTING_UPDATE_CONFIGURATION_PENDING = 'benit_accounting_update_configuration_pending',
    BENIT_ACCOUNTING_UPDATE_CONFIGURATION_SUCCESS = 'benit_accounting_update_configuration_success',
    BENIT_ACCOUNTING_CREATE_INVOICE_PENDING = 'benit_accounting_create_invoice_pending',
    BENIT_ACCOUNTING_CREATE_INVOICE_SUCCESS = 'benit_accounting_create_invoice_success',
    BENIT_ACCOUNTING_CREATE_INVOICE_BY_COPY_PENDING = 'benit_accounting_create_invoice_by_copy_pending',
    BENIT_ACCOUNTING_CREATE_INVOICE_BY_COPY_SUCCESS = 'benit_accounting_create_invoice_by_copy_success',
    BENIT_ACCOUNTING_RESET_INVOICE_CREATION_STATE = 'benit_accounting_reset_invoice_creation_state',
    BENIT_ACCOUNTING_RESET_INVOICE_UPDATE_STATE = 'benit_accounting_reset_invoice_update_state',
    BENIT_ACCOUNTING_RESET_INVOICE_UPDATE_ID_STATE = 'benit_accounting_reset_invoice_update_id_state',
    BENIT_ACCOUNTING_RESET_INVOICE_DELETION_STATE = 'benit_accounting_reset_invoice_deletion_state',
    BENIT_ACCOUNTING_GET_INVOICES_PENDING = 'benit_accounting_get_invoices_pending',
    BENIT_ACCOUNTING_GET_INVOICES_SUCCESS = 'benit_accounting_get_invoices_success',
    BENIT_ACCOUNTING_GET_INVOICE_PENDING = 'benit_accounting_get_invoice_pending',
    BENIT_ACCOUNTING_GET_INVOICE_SUCCESS = 'benit_accounting_get_invoice_success',
    BENIT_ACCOUNTING_GET_NEXT_INVOICE_ID_PENDING = 'benit_accounting_get_next_invoice_id_pending',
    BENIT_ACCOUNTING_GET_NEXT_INVOICE_ID_SUCCESS = 'benit_accounting_get_next_invoice_id_success',
    BENIT_ACCOUNTING_UPDATE_INVOICE_PENDING = 'benit_accounting_update_invoice_pending',
    BENIT_ACCOUNTING_UPDATE_INVOICE_SUCCESS = 'benit_accounting_update_invoice_success',
    BENIT_ACCOUNTING_UPDATE_INVOICE_ID_PENDING = 'benit_accounting_update_invoice_id_pending',
    BENIT_ACCOUNTING_UPDATE_INVOICE_ID_SUCCESS = 'benit_accounting_update_invoice_id_success',
    BENIT_ACCOUNTING_DELETE_INVOICE_PENDING = 'benit_accounting_delete_invoice_pending',
    BENIT_ACCOUNTING_DELETE_INVOICE_SUCCESS = 'benit_accounting_delete_invoice_success',
    BENIT_ACCOUNTING_NEW_INVOICE_PDF_AVAILABLE = 'benit_accounting_new_invoice_pdf_available',
    BENIT_ACCOUNTING_GET_CUSTOMER_RECORDS_PENDING = 'benit_accounting_get_customer_records_pending',
    BENIT_ACCOUNTING_GET_CUSTOMER_RECORDS_SUCCESS = 'benit_accounting_get_customer_records_success',
    BENIT_ACCOUNTING_EXPENSE_DOCUMENT_UPLOAD_SUCCESS = 'benit_accounting_expense_document_upload_success',
    BENIT_ACCOUNTING_CREATE_EXPENSE_PENDING = 'benit_accounting_create_expense_pending',
    BENIT_ACCOUNTING_CREATE_EXPENSE_SUCCESS = 'benit_accounting_create_expense_success',
    BENIT_ACCOUNTING_CREATE_EXPENSE_BY_COPY_PENDING = 'benit_accounting_create_expense_by_copy_pending',
    BENIT_ACCOUNTING_CREATE_EXPENSE_BY_COPY_SUCCESS = 'benit_accounting_create_expense_by_copy_success',
    BENIT_ACCOUNTING_RESET_EXPENSE_CREATION_STATE = 'benit_accounting_reset_expense_creation_state',
    BENIT_ACCOUNTING_RESET_EXPENSE_UPDATE_STATE = 'benit_accounting_reset_expense_update_state',
    BENIT_ACCOUNTING_RESET_EXPENSE_UPDATE_ID_STATE = 'benit_accounting_reset_expense_update_id_state',
    BENIT_ACCOUNTING_RESET_EXPENSE_DELETION_STATE = 'benit_accounting_reset_expense_deletion_state',
    BENIT_ACCOUNTING_GET_EXPENSES_PENDING = 'benit_accounting_get_expenses_pending',
    BENIT_ACCOUNTING_GET_EXPENSES_SUCCESS = 'benit_accounting_get_expenses_success',
    BENIT_ACCOUNTING_GET_EXPENSE_PENDING = 'benit_accounting_get_expense_pending',
    BENIT_ACCOUNTING_GET_EXPENSE_SUCCESS = 'benit_accounting_get_expense_success',
    BENIT_ACCOUNTING_GET_NEXT_EXPENSE_ID_PENDING = 'benit_accounting_get_next_expense_id_pending',
    BENIT_ACCOUNTING_GET_NEXT_EXPENSE_ID_SUCCESS = 'benit_accounting_get_next_expense_id_success',
    BENIT_ACCOUNTING_UPDATE_EXPENSE_PENDING = 'benit_accounting_update_expense_pending',
    BENIT_ACCOUNTING_UPDATE_EXPENSE_SUCCESS = 'benit_accounting_update_expense_success',
    BENIT_ACCOUNTING_UPDATE_EXPENSE_ID_PENDING = 'benit_accounting_update_expense_id_pending',
    BENIT_ACCOUNTING_UPDATE_EXPENSE_ID_SUCCESS = 'benit_accounting_update_expense_id_success',
    BENIT_ACCOUNTING_DELETE_EXPENSE_PENDING = 'benit_accounting_delete_expense_pending',
    BENIT_ACCOUNTING_DELETE_EXPENSE_SUCCESS = 'benit_accounting_delete_expense_success',
    BENIT_ACCOUNTING_NEW_EXPENSE_PDF_AVAILABLE = 'benit_accounting_new_expense_pdf_available',
}
