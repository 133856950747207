import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';
import socketClient from 'socket.io-client';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';

export const ri_property_management_get_weg_beschluesse = (eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-beschluesse/${eigentuemerEid}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_BESCHLUESSE_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_new_weg_beschluss = (wegBeschluss: { eigentuemerEid: string; beschlussnummer: string; beschreibung: string; pdfName: { [x: string]: any } | null }) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-beschluesse`,
            method: 'post',
            body: {
                eigentuemerEid: wegBeschluss.eigentuemerEid,
                beschlussnummer: wegBeschluss.beschlussnummer,
                beschreibung: wegBeschluss.beschreibung,
            },
            file: wegBeschluss.pdfName,
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_NEW_WEG_BESCHLUSS_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_update_weg_beschluss = (wegBeschluss: {
    id: string;
    eigentuemerEid: string;
    beschlussnummer: string;
    beschreibung: string;
    pdfName: { [x: string]: any } | null;
}) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-beschluesse`,
            method: 'put',
            body: {
                id: wegBeschluss.id,
                eigentuemerEid: wegBeschluss.eigentuemerEid,
                beschlussnummer: wegBeschluss.beschlussnummer,
                beschreibung: wegBeschluss.beschreibung,
            },
            file: wegBeschluss.pdfName,
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_UPDATE_WEG_BESCHLUSS_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_delete_weg_beschluss = (id: string, eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-beschluesse/${id}/${eigentuemerEid}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_DELETE_WEG_BESCHLUSS_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_weg_beschluss_pdf_download = (beschlussId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-beschluesse/get-file/${beschlussId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                //FileDownload(data, documentName);
                const file = new Blob([data], { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                var element = document.createElement('a');
                element.setAttribute('href', fileURL);
                element.setAttribute('download', `WEG-Beschluss-${beschlussId}`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);

                // const pdfWindow = window.open();
                // if (pdfWindow) {
                //     pdfWindow.location.href = fileURL;
                // }
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest({ responseType: 'blob' });
    };
};

export const ri_property_management_get_weg_versammlungen = (eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-versammlung/${eigentuemerEid}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_VERSAMMLUNGEN_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_new_weg_versammlungen = (wegVersammlung: {
    eigentuemerEid: string;
    versammlungsnummer: string;
    datum: string;
    ort: string;
    pdfName: { [x: string]: any } | null;
}) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-versammlung`,
            method: 'post',
            body: {
                eigentuemerEid: wegVersammlung.eigentuemerEid,
                versammlungsnummer: wegVersammlung.versammlungsnummer,
                datum: wegVersammlung.datum,
                ort: wegVersammlung.ort,
            },
            file: wegVersammlung.pdfName,
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_NEW_WEG_VERSAMMLUNGEN_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_update_weg_versammlungen = (wegVersammlung: {
    id: string;
    eigentuemerEid: string;
    versammlungsnummer: string;
    datum: string;
    ort: string;
    pdfName: { [x: string]: any } | null;
}) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-versammlung`,
            method: 'put',
            body: {
                id: wegVersammlung.id,
                eigentuemerEid: wegVersammlung.eigentuemerEid,
                versammlungsnummer: wegVersammlung.versammlungsnummer,
                datum: wegVersammlung.datum,
                ort: wegVersammlung.ort,
            },
            file: wegVersammlung.pdfName,
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_UPDATE_WEG_VERSAMMLUNGEN_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_delete_weg_versammlungen = (id: string, eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-versammlung/${id}/${eigentuemerEid}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_DELETE_WEG_VERSAMMLUNGEN_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_weg_versammlungen_pdf_download = (versammlungsId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-versammlung/get-file/${versammlungsId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                //FileDownload(data, documentName);
                const file = new Blob([data], { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                const pdfWindow = window.open();
                if (pdfWindow) {
                    pdfWindow.location.href = fileURL;
                }
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest({ responseType: 'blob' });
    };
};

export const ri_property_management_get_weg_chat = (eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-chat/${eigentuemerEid}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();

        if (eigentuemerEid !== '0') {
            const socketTmp = socketClient('/api/ri/property-management/socketio', { transports: ['websocket'], forceNew: true, path: '/api/ri/property-management/socket.io' });

            dispatch({
                type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_SOCKET,
                payload: socketTmp,
            });
        }
    };
};

export const ri_property_management_weg_chat_send_message = (message: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_NEW_MESSAGE,
            payload: message,
        });
    };
};

export const ri_property_management_weg_chat_delete_messages = (eigentuemerEid: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/property-management/weg-chat/${eigentuemerEid}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_DELETE_MESSAGES,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_property_management_weg_chat_get_user_eigentuemer = (eigentuemerEid: string, searchTerm: string = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        if (eigentuemerEid !== '0') {
            const { doRequest } = useRequest({
                url: `/api/ri/property-management/user-eigentuemer/${eigentuemerEid}?searchTerm=${searchTerm}`,
                method: 'get',
                body: {},
                onSuccess: (data) => {
                    dispatch({
                        type: ActionType.RI_PROPERTY_MANAGEMENT_WEG_CHAT_USER_EIGENTUEMER_GET_SUCCESS,
                        payload: data,
                    });
                    dispatch(actionCreators.benit_central_ui_delete_all_errors());
                },
                onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
            });

            doRequest();
        }
    };
};
