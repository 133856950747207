import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const benit_accounting_get_invoices = (searchTerm = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_GET_INVOICES_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice?searchTerm=${searchTerm}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_GET_INVOICES_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_get_invoice = (invoiceId: String) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_GET_INVOICE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice?invoiceId=${invoiceId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_GET_INVOICE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_get_next_invoice_id = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_GET_NEXT_INVOICE_ID_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice/next-invoice-id`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_GET_NEXT_INVOICE_ID_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_create_invoice = (invoice: { [x: string]: any }) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice`,
            method: 'post',
            body: { invoice },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_RESET_INVOICE_CREATION_STATE,
                });
            },
        });

        doRequest();
    };
};

export const benit_accounting_create_invoice_by_copy = (toBeCopiedFromInvoiceId: string, invoiceId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_BY_COPY_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice/copy`,
            method: 'post',
            body: { toBeCopiedFromInvoiceId, invoiceId },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_CREATE_INVOICE_BY_COPY_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_RESET_INVOICE_CREATION_STATE,
                });
            },
        });

        doRequest();
    };
};

export const benit_accounting_update_invoice = (invoice: { [x: string]: any } | null) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice`,
            method: 'put',
            body: { invoice },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_update_invoice_id = (formerInvoiceId: string, invoiceId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_ID_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice/invoice-id`,
            method: 'put',
            body: { formerInvoiceId, invoiceId },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_UPDATE_INVOICE_ID_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_delete_invoice = (invoiceId: String) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_DELETE_INVOICE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice/${invoiceId}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_DELETE_INVOICE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_accounting_reset_invoice_creation_state = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_RESET_INVOICE_CREATION_STATE,
        });
    };
};

export const benit_accounting_reset_invoice_update_state = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_RESET_INVOICE_UPDATE_STATE,
        });
    };
};

export const benit_accounting_reset_invoice_update_id_state = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_RESET_INVOICE_UPDATE_ID_STATE,
        });
    };
};

export const benit_accounting_reset_invoice_deletion_state = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_RESET_INVOICE_DELETION_STATE,
        });
    };
};

export const benit_accounting_get_invoice_document = (invoice: { [x: string]: any }, downloadPdf = false) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice-document`,
            method: 'post',
            body: { invoice },
            onSuccess: (data) => {
                const buff = Buffer.from(data, 'hex');
                const file = new Blob([buff], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(file);

                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_NEW_INVOICE_PDF_AVAILABLE,
                    payload: { fileURL },
                });

                if (downloadPdf) {
                    var element = document.createElement('a');
                    element.setAttribute('href', fileURL);
                    element.setAttribute('download', `${invoice.invoiceId}.pdf`);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();
                    document.body.removeChild(element);
                }
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest({ responseType: 'blob' });
    };
};

export const benit_accounting_get_customer_records = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ACCOUNTING_GET_CUSTOMER_RECORDS_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/accounting/invoice/customer-records`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ACCOUNTING_GET_CUSTOMER_RECORDS_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
