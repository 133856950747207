import styled, { ThemedStyledProps } from 'styled-components';
import { TdHTMLAttributes } from 'react';

const RiskMatrixContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #777;
`;

const RiskMatrixTable = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
`;

const RiskMatrixHeaderCell = styled.th`
  padding: 8px;
  text-align: center;
  background-color: white;
`;

const RiskMatrixFirstHeaderCell = styled(RiskMatrixHeaderCell)`
  text-align: left;
  background-color: white;
`;

const RiskMatrixDataCell = styled.td<{ numRisks: number; color: string }>`
  background-color: ${props => props.color};
  text-align: center;
  vertical-align: middle;
  padding: 0;
  border: none;
  width: ${props => 100 / props.numRisks}%;
  height: 50px;

  div {
    font-weight: bold;
    padding-left: 10px;
    margin-left: 10px;
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    margin: 0 auto;

    &:checked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background-color: #000;
      border-radius: 2px;
    }
  }
`;

const RiskMatrixFirstDataCell = styled(RiskMatrixDataCell)`
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
`;

interface RiskMatrixProps {
  data: any;
}

const RiskMatrix: React.FC<RiskMatrixProps> = ({ data }) => {
  if (!data || (Object.keys(data).length === 0 && data.constructor === Object)) {
    return null;
  }

  const risks = ['decubitus', 'fall', 'incontinence', 'pain', 'nutrition', 'cystitis_intertrigo'];
  const riskNames = ['Dekubitus', 'Sturz', 'Inkontinenz', 'Schmerz', 'Ernährung', 'Zystitis/Intertrigo'];
  var topics =  Object.keys(data);
  

  const topicTranslations = {
    topic1: 'Kognitive und kommunikative Fähigkeiten',
    topic2: 'Mobilität und Bewegung',
    topic3: 'Krankheitsbezogene Anforderungen und Belastungen',
    topic4: 'Selbstversorgung',
    topic5: 'Leben in sozialen Beziehungen'
  };

  const topicTranslationsKeys = Object.keys(topicTranslations);

  // remove topics not included in topicTranslationsKeys
  topics = topics.filter(topic => topicTranslationsKeys.includes(topic));

  const topicColors = {
    topic1: '#F7714C',
    topic2: '#FBC100',
    topic3: '#80D96F',
    topic4: '#9E54AD',
    topic5: '#79B7DE'
  };
  const numRisks = risks.length;

  return (
    <RiskMatrixContainer>
      <RiskMatrixTable>
      <thead>
        <tr>
          <RiskMatrixFirstHeaderCell>Themenfeld</RiskMatrixFirstHeaderCell>
          {riskNames.map(risk => (
            <RiskMatrixHeaderCell key={risk}>{risk}</RiskMatrixHeaderCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {topics.map(topicName => {
          const topicColor = topicColors[topicName as keyof typeof topicColors];
          return (
            <tr key={topicName}>              
              <RiskMatrixFirstDataCell numRisks={numRisks} color={topicColor}>
                  {topicTranslations[topicName as keyof typeof topicTranslations]}
              </RiskMatrixFirstDataCell>
              {risks.map((risk, index) => {
                const isChecked = data[topicName].risks[risk].riskExists;
                return (
                  <RiskMatrixDataCell
                    key={`${risk}-${topicName}`}
                    numRisks={numRisks}
                    color={topicColor}
                  >
                    <input type="checkbox" checked={isChecked} disabled />
                  </RiskMatrixDataCell>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </RiskMatrixTable>
    </RiskMatrixContainer>
  );
};

export default RiskMatrix;