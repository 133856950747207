import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';

interface RIRealEstateState {
    configurationDidInvalidate: boolean;
    configurationUpdated: boolean;
    configurations:
        | [
              {
                  configIdentifier: string;
                  properties: { [x: string]: string };
              }
          ]
        | null;
    objekte: any;
    objekt: any;
    objektDidInvalidate: boolean;
    objektUpdated: boolean;
    objektUpdating: boolean;
    objektCreated: boolean;
    objektDeleted: boolean;
    objektPictures: string[] | null;
    texts: any;
    stakeholderDidInvalidate: boolean;
    stakeholderCreated: boolean;
    stakeholder: any;
    stakeholderDocumentSuccess: boolean;
    stakeholderDeleteSuccess: boolean;
    stakeholderDeleteAllSuccess: boolean;
    textSupport: any;
}

const initialState = {
    configurationDidInvalidate: false,
    configurationUpdated: false,
    configurations: null,
    objekte: null,
    objekt: null,
    objektDidInvalidate: false,
    objektUpdated: false,
    objektUpdating: false,
    objektCreated: false,
    objektDeleted: false,
    objektPictures: null,
    texts: null,
    stakeholderDidInvalidate: false,
    stakeholderCreated: false,
    stakeholder: null,
    stakeholderDocumentSuccess: false,
    stakeholderDeleteSuccess: false,
    stakeholderDeleteAllSuccess: false,
    textSupport: null,
};

const reducer = (state: RIRealEstateState = initialState, action: Action): RIRealEstateState => {
    switch (action.type) {
        case ActionType.RI_REAL_ESTATE_CONFIGURATION_GET_SUCCESS:
            return {
                ...state,
                configurations: action.payload.configurations,
                configurationDidInvalidate: false,
                configurationUpdated: false,
            };
        case ActionType.RI_REAL_ESTATE_UPDATE_CONFIGURATION_PENDING:
            return {
                ...state,
                configurationUpdated: false,
            };
        case ActionType.RI_REAL_ESTATE_UPDATE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                configurationUpdated: true,
                configurationDidInvalidate: true,
            };
        case ActionType.RI_REAL_ESTATE_OBJEKTE_GET_SUCCESS:
            return {
                ...state,
                objekte: action.payload.objekte,
                objektUpdated: false,
                objektDeleted: false,
                objektUpdating: false,
                objektCreated: false,
            };
        case ActionType.RI_REAL_ESTATE_OBJEKT_GET_SUCCESS:
            let picturesTmp: any = [];
            if (action.payload.objekte.length > 0 && action.payload.objekte[0].data.pictures) {
                for (const picture of Object.entries(action.payload.objekte[0].data.pictures)) {
                    picturesTmp.push(picture[0]);
                }
            }
            if (picturesTmp.length === 0) {
                picturesTmp = null;
            }
            return {
                ...state,
                objekt: action.payload.objekte.length > 0 ? action.payload.objekte[0] : null,
                objektPictures: picturesTmp,
                objektUpdated: false,
            };
        case ActionType.RI_REAL_ESTATE_UPDATE_OBJEKT_PENDING:
            return {
                ...state,
                objektUpdated: false,
                objektUpdating: true,
            };
        case ActionType.RI_REAL_ESTATE_UPDATE_OBJEKT_SUCCESS:
            return {
                ...state,
                objektUpdated: true,
                objektDidInvalidate: true,
            };
        case ActionType.RI_REAL_ESTATE_CREATE_OBJEKT_PENDING:
            return {
                ...state,
                objektCreated: false,
            };
        case ActionType.RI_REAL_ESTATE_CREATE_OBJEKT_SUCCESS:
            return {
                ...state,
                objektCreated: true,
            };
        case ActionType.RI_REAL_ESTATE_DELETE_OBJEKT_SUCCESS:
            return {
                ...state,
                objektDeleted: true,
            };
        case ActionType.RI_REAL_ESTATE_UPLOAD_PICTURE_SUCCESS:
            return {
                ...state,
                objektPictures: state.objektPictures ? [...state.objektPictures, action.payload.image] : [action.payload.image],
            };
        case ActionType.RI_REAL_ESTATE_DELETE_PICTURE_SUCCESS:
            return {
                ...state,
            };
        case ActionType.RI_REAL_ESTATE_GET_TEXTS_SUCCESS:
            return {
                ...state,
                texts: action.payload,
            };
        case ActionType.RI_REAL_ESTATE_STAKEHOLDER_GET_SUCCESS:
            return {
                ...state,
                stakeholderDidInvalidate: false,
                stakeholder: action.payload.stakeholder,
                stakeholderCreated: false,
                stakeholderDocumentSuccess: false,
                stakeholderDeleteSuccess: false,
                stakeholderDeleteAllSuccess: false,
            };
        case ActionType.RI_REAL_ESTATE_STAKEHOLDER_CREATE_SUCCESS:
            return {
                ...state,
                stakeholderDidInvalidate: true,
                stakeholderCreated: true,
            };
        case ActionType.RI_REAL_ESTATE_STAKEHOLDER_UPDATE_SUCCESS:
            return {
                ...state,
                stakeholderDidInvalidate: true,
            };
        case ActionType.RI_REAL_ESTATE_STAKEHOLDER_DELETE_SUCCESS:
            return {
                ...state,
                stakeholderDidInvalidate: true,
                stakeholderDeleteSuccess: true,
            };
        case ActionType.RI_REAL_ESTATE_STAKEHOLDER_DELETE_ALL_SUCCESS:
            return {
                ...state,
                stakeholderDidInvalidate: true,
                stakeholderDeleteAllSuccess: true,
            };
        case ActionType.RI_REAL_ESTATE_STAKEHOLDER_DOCUMENT_SUCCESS:
            return {
                ...state,
                stakeholderDidInvalidate: true,
                stakeholderDocumentSuccess: true,
            };
        case ActionType.RI_REAL_ESTATE_TEXT_SUPPORT_PENDING:
            return {
                ...state,
                textSupport: null,
            };
        case ActionType.RI_REAL_ESTATE_TEXT_SUPPORT_SUCCESS:
            return {
                ...state,
                textSupport: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
