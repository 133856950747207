import { Dispatch } from 'redux';
import { ActionType } from '../action-types';
import { Action } from '../actions';

export const benit_central_set_help_activated = (helpActivated: boolean) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_SET_HELP_ACTIVATED,
            payload: helpActivated,
        });
    };
};
