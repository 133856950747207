import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Dna } from 'react-loader-spinner';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import styles from './AssistantApp.module.css';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';

import ChatInput from './components/ChatInput';
import ResultOutput from './components/ResultOutput';
import VitalsCard from './components/VitalsCard';
import JSONPretty from 'react-json-pretty';

interface IDocumentLoadSuccessPayload {
    numPages: any;
}

interface Message {
    id: string;
    text: string;
}

const initialMessages: Message[] = [
    {
        id: '1',
        text: 'Hi there! Welcome to the chat app.',
    },
];

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 80px;
`;

const ChatMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 18px;
    animation: fadein 0.5s ease;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const ChatMessageText = styled.div`
    margin: 0;
    color: black;
    align-items: center;
    display: inline-block;
    margin-left: 8px;
`;

const ChatIcon = styled.div`
    display: inline-block;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: black;
    background-color: white;
    border: none;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    margin-left: 8px;
    padding-top: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const PDFContainer = styled.div`
    display: block;
    background-color: white;
    border: none;
    border-radius: 4px;
    margin-left: 8px;
    padding-top: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const DownloadButtonContainer = styled.div`
    display: block;
    width: 100%;
`;

const DownloadButton = styled.button`
    display: block;
    display: inline-block;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: white;
    border: none;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    }
`;

const AssistantApp: React.FC = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + `/js/pdfworker/${pdfjs.version}/pdf.worker.min.js`;
    const [messages, setMessages] = useState<Message[]>(initialMessages);
    const [numPages, setNumPages] = useState(null);
    const [editModeOn, setEditModeOn] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const { benit_ask_assistant, benit_assistant_get_report_document, benit_assistant_update_answer } = useActions();

    const { assistantPending, assistantHistory, pdfPending, pdfInternalUrl } = useTypedSelector((state) => state.benitAssistantReducer);

    // useEffect(() => {
    //     if (messagesEndRef.current) {
    //         messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, [assistantHistory]);

    const documentViewer = (url: string | null, loadSuccessCallback: any, numPagesInput: any) => {
        return url ? (
            <SizeMe>
                {({ size }) => (
                    <PDFContainer className="bg-white">
                        <div className="row pt-4 pb-3">
                            <DownloadButtonContainer className="text-center">
                                <DownloadButton color="#0080ff" onClick={(e) => setEditModeOn(true)}>
                                    Bearbeiten
                                </DownloadButton>
                                <DownloadButton color="#0080ff" onClick={(e) => benit_assistant_get_report_document({ answer: assistantHistory[assistantHistory.length - 1].text }, true)}>
                                    Herunterladen
                                </DownloadButton>
                            </DownloadButtonContainer>
                        </div>
                        <Document
                            file={url}
                            onLoadSuccess={loadSuccessCallback}
                            loading={
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                            }
                        >
                            {Array.from(new Array(numPagesInput), (el, index) => (
                                <>
                                    <Page width={size.width ? size.width - 10 : 0} key={`page_${index + 1}`} pageNumber={index + 1} />
                                    {numPagesInput && index < numPagesInput - 1 ? <hr /> : null}
                                </>
                            ))}
                            {/* <Page width={size.width} pageNumber={pageNumber} /> */}
                            {/* <p>
                                Page {pageNumber} of {numPagesInput}
                            </p> */}
                        </Document>
                    </PDFContainer>
                )}
            </SizeMe>
        ) : null;
    };

    const onDocumentLoadSuccess = ({ numPages }: IDocumentLoadSuccessPayload) => {
        setNumPages(numPages);
    };

    const updateVitalValues = (value: string, property: string) => {
        let updatedAnswer = { ...assistantHistory[assistantHistory.length - 1] };
        if (!updatedAnswer.data) {
            updatedAnswer.data = {};
        }
        if (!updatedAnswer.data[property]) {
            updatedAnswer.data[property] = '';
        }
        updatedAnswer.data[property] = value;
        benit_assistant_update_answer(updatedAnswer);
    };

    return (
        <>
            <div className="row mb-3 mt-4">
                <div className="col-lg-10 offset-lg-1 col-md-12 pl-4 pr-4 mt-4">
                    <ChatInput onSendMessage={benit_ask_assistant} />
                </div>
            </div>
            {assistantHistory && assistantHistory.length > 0 && assistantHistory[0].role === 'assistant' && (assistantHistory[0].type === 'report' || assistantHistory[0].type === 'sis') ? (
                editModeOn ? (
                    <div className="row mb-3">
                        <div className="col-lg-10 offset-lg-1 col-md-12 pl-4 pr-4 mt-4">
                            <ResultOutput
                                resultText={assistantHistory[assistantHistory.length - 1].text}
                                onSendMessage={(message: any) => {
                                    let updatedAnswer = { ...assistantHistory[assistantHistory.length - 1] };
                                    if (!updatedAnswer.data) {
                                        updatedAnswer.data = {};
                                    }
                                    if (!updatedAnswer.data.text) {
                                        updatedAnswer.data.text = '';
                                    }
                                    updatedAnswer.data.text = message;
                                    benit_assistant_update_answer(updatedAnswer);
                                    benit_assistant_get_report_document({ answer: message }, false);
                                    setEditModeOn(false);
                                }}
                            />
                        </div>
                    </div>
                ) : pdfInternalUrl ? (
                    <div className="row mb-3">
                        <div className="col-lg-10 offset-lg-1 col-md-12 pl-3 pr-4">
                            {/* <div className="text-center">{documentViewer('https://benit.dev/dummy.pdf', onDocumentLoadSuccess, numPages)}</div> */}
                            <div className="text-center">{documentViewer(pdfInternalUrl, onDocumentLoadSuccess, numPages)}</div>
                        </div>
                    </div>
                ) : null
            ) : null}
            {assistantHistory && assistantHistory.length > 0 && assistantHistory[0].role === 'assistant' && assistantHistory[0].type === 'sis' ? (
                <JSONPretty id="json-pretty" data={assistantHistory[0].data}></JSONPretty>
            ) : null}
            {assistantPending ? (
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="text-center">
                            <Dna visible={true} height="170" width="300" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
                        </div>
                    </div>
                </div>
            ) : null}
            {assistantHistory && assistantHistory.length > 0 && assistantHistory[0].role === 'assistant' && assistantHistory[0].type === 'vitals' && assistantHistory[0].data ? (
                <VitalsCard
                    patientName={assistantHistory[0].data.patientName ?? ''}
                    bloodPressure={assistantHistory[0].data.bloodPressure ?? ''}
                    heartRate={assistantHistory[0].data.heartRate ?? ''}
                    bodyTemperature={assistantHistory[0].data.bodyTemperature ?? ''}
                    updateVitalValues={updateVitalValues}
                />
            ) : null}
            {/* {assistantHistory.map((entry, index) => (
                <ChatMessageContainer key={index} style={{ backgroundColor: `#${entry.role && entry.role === 'assistant' ? 'F4F6F9' : 'E9ECEF'}` }}>
                    <div className="row">
                        <div className="col-xl-4 col-sm-12" style={{ justifyContent: 'left' }}>
                            <ChatIcon className="text-center align-items-center justify-content-center">
                                <i className={`fa ${entry.role && entry.role === 'assistant' ? 'fa-laptop' : 'fa-user'}`} />
                            </ChatIcon>
                            <ChatMessageText>
                                {entry.role && entry.role === 'assistant'
                                    ? entry.text.split(' ').map((word: string, index: number) => (
                                          <span key={index} className={styles.fadeIn} style={{ animationDelay: `${index * 0.09}s` }}>
                                              {word}{' '}
                                              {word.includes('\n') ? (
                                                  <>
                                                      <br />
                                                  </>
                                              ) : null}
                                          </span>
                                      ))
                                    : entry.text}{' '}
                            </ChatMessageText>
                        </div>
                    </div>
                </ChatMessageContainer>
            ))}

            <div className="text-center">{documentViewer('https://benit.dev/dummy.pdf', onDocumentLoadSuccess, numPages)}</div>
            {assistantPending ? (
                <ChatMessageContainer key={assistantHistory.length} style={{ backgroundColor: `#F4F6F9` }}>
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-10 col-md-12" style={{ justifyContent: 'left' }}>
                            <ChatIcon className="text-center align-items-center justify-content-center">
                                <i className={`fa fa-laptop`} />
                            </ChatIcon>
                            <ChatMessageText>
                                <Dna visible={true} height="70" width="100" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
                            </ChatMessageText>
                        </div>
                    </div>
                </ChatMessageContainer>
            ) : null} */}
            <div ref={messagesEndRef} />
        </>
    );
};

export default AssistantApp;
