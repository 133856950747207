import styled, { ThemedStyledProps } from 'styled-components';
import { TdHTMLAttributes, useEffect, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const SingleTopicRiskMatrixContainer = styled.div`
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #777;
`;

const SingleTopicRiskMatrixTable = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
`;

const SingleTopicRiskMatrixHeaderCell = styled.th`
  padding: 8px;
  text-align: center;
  background-color: white;
`;

const SingleTopicRiskMatrixFirstHeaderCell = styled(SingleTopicRiskMatrixHeaderCell)`
  text-align: left;
  background-color: white;
`;

const SingleTopicRiskMatrixDataCell = styled.td<{ numRisks: number; color: string }>`
  background-color: ${props => props.color};
  text-align: center;
  vertical-align: middle;
  padding: 0;
  border: none;
  height: 50px;

  div {
    font-size: 12px;
    padding-left: 10px;
    margin-left: 10px;
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    margin: 0 auto;

    &:checked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background-color: #000;
      border-radius: 2px;
    }
  }
`;

const SingleTopicRiskMatrixFirstDataCell = styled(SingleTopicRiskMatrixDataCell)`
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
`;

interface SingleTopicRiskMatrixProps {
  data: any;
  topic: string;
}

const SingleTopicRiskMatrix: React.FC<SingleTopicRiskMatrixProps> = ({ data, topic }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // check if data is empty json object or null - if so return null
  if (!data || (Object.keys(data).length === 0 && data.constructor === Object)) {
    return null;
  }

  const risks = ['decubitus', 'fall', 'incontinence', 'pain', 'nutrition', 'cystitis_intertrigo'];
  const riskNames = ['Dekubitus', 'Sturz', 'Inkontinenz', 'Schmerz', 'Ernährung', 'Zystitis/Intertrigo'];
  const topicColors = {
    topic1: '#F7714C',
    topic2: '#FBC100',
    topic3: '#80D96F',
    topic4: '#9E54AD',
    topic5: '#79B7DE'
  };
  const numRisks = risks.length;
  var topicColor = topicColors[topic as keyof typeof topicColors];  

  return (
    
    <SingleTopicRiskMatrixContainer>
      <MobileView>
        <SingleTopicRiskMatrixTable>
          <thead>
            <tr>
              <SingleTopicRiskMatrixFirstHeaderCell></SingleTopicRiskMatrixFirstHeaderCell>
              <SingleTopicRiskMatrixHeaderCell>Risikoberatung erforderlich?</SingleTopicRiskMatrixHeaderCell>
              <SingleTopicRiskMatrixHeaderCell>Erklärung</SingleTopicRiskMatrixHeaderCell>
            </tr>
          </thead>
          <tbody>
          {risks.map((risk, index) => {
                    const isChecked = data[risk].riskExists;
                    const explanation = data[risk].explanation;
                    return (
                      <tr key={`${topic}-risk-explanation`}>
                      <SingleTopicRiskMatrixFirstDataCell numRisks={numRisks} color={topicColor}>
                        {riskNames[index]}
                      </SingleTopicRiskMatrixFirstDataCell>
                      <SingleTopicRiskMatrixDataCell
                        key={`${risk}-${topic}`}
                        numRisks={numRisks}
                        color={topicColor}
                      >
                        <input type="checkbox" checked={isChecked} disabled />
                      </SingleTopicRiskMatrixDataCell>
                      <SingleTopicRiskMatrixDataCell
                      key={`${risk}-${topic}`}
                      numRisks={numRisks}
                      color={topicColor}
                    >
                      <div>{explanation}</div>
                    </SingleTopicRiskMatrixDataCell>
                      </tr>
                    );
                  })}

          </tbody>
        </SingleTopicRiskMatrixTable>
      </MobileView>
      <BrowserView>
        <SingleTopicRiskMatrixTable>
          <thead>
            <tr>
              <SingleTopicRiskMatrixFirstHeaderCell></SingleTopicRiskMatrixFirstHeaderCell>
              {riskNames.map(risk => (
                <SingleTopicRiskMatrixHeaderCell key={risk}>{risk}</SingleTopicRiskMatrixHeaderCell>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr key={topic}>              
                  <SingleTopicRiskMatrixFirstDataCell numRisks={numRisks} color={topicColor}>
                      Risikoberatung erforderlich?
                    </SingleTopicRiskMatrixFirstDataCell>
                  {risks.map((risk, index) => {
                    const isChecked = data[risk].riskExists;
                    return (
                      <SingleTopicRiskMatrixDataCell
                        key={`${risk}-${topic}`}
                        numRisks={numRisks}
                        color={topicColor}
                      >
                        <input type="checkbox" checked={isChecked} disabled />
                      </SingleTopicRiskMatrixDataCell>
                    );
                  })}
            </tr>
              <tr key={`${topic}-risk-explanation`}>
                <SingleTopicRiskMatrixFirstDataCell numRisks={numRisks} color={topicColor}>
                  Erklärung
                </SingleTopicRiskMatrixFirstDataCell>
                {risks.map((risk, index) => {
                  const explanation = data[risk].explanation;
                  return (
                    <SingleTopicRiskMatrixDataCell
                      key={`${risk}-${topic}`}
                      numRisks={numRisks}
                      color={topicColor}
                    >
                      <div>{explanation}</div>
                    </SingleTopicRiskMatrixDataCell>
                  );
                })}
              </tr>

          </tbody>
        </SingleTopicRiskMatrixTable>
      </BrowserView>      
    </SingleTopicRiskMatrixContainer>
  );
};

export default SingleTopicRiskMatrix;