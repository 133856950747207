import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const benit_organization_configuration_update_user_profile = (firstName: string, lastName: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_PROFILE_UPDATE_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/organization-configuration/user`,
            method: 'put',
            body: { firstName, lastName },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_ORGANIZATION_CONFIGURATION_USER_PROFILE_UPDATE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
                dispatch(actionCreators.benit_auth_getCurrentUser());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
