import { useActions } from '../../../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../../../hooks/useTypedSelector';
import { getI18n, useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import { useHistory, useLocation } from 'react-router-dom';

const OrganizationPicker = () => {
    const { organizations, chosenOrganization } = useTypedSelector(
        (state) => state.benitAuthReducer
    );
    const { benit_auth_chooseOrg } = useActions();
    const { t, i18n } = useTranslation(['general']);
    const history = useHistory();
    const location = useLocation();

    const otherLanguageButtons = organizations
        ? organizations.map((organization) => {
              return (
                  <button
                      onClick={() => {
                          benit_auth_chooseOrg(organization.id);
                      }}
                      className={`dropdown-item ${
                          organization.id == chosenOrganization!.id
                              ? 'active'
                              : ''
                      }`}
                  >
                      <Avatar
                          name={organization.name}
                          size="25"
                          textSizeRatio={2.3}
                      />{' '}
                      <span className="ml-1">{organization.name}</span>
                  </button>
              );
          })
        : null;
    if (!organizations) {
        return null;
    } else if (organizations.length === 1) {
        return null;
    } else {
        return (
            <li className="nav-item dropdown">
                <a
                    className="nav-link pt-1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{ cursor: 'pointer' }}
                >
                    <Avatar
                        name={chosenOrganization!.name}
                        size="25"
                        textSizeRatio={2.3}
                    />
                </a>
                <div
                    className="dropdown-menu dropdown-menu-right p-0"
                    style={{
                        left: 'inherit',
                        right: '0px',
                    }}
                >
                    {otherLanguageButtons}
                </div>
            </li>
        );
    }
};

export default OrganizationPicker;
