import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const ri_real_estate_get_objekte = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/objekt`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_OBJEKTE_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_get_objekt = (objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/objekt?objectId=${objectId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_OBJEKT_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_create_objekt = (objectId: string, data: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_REAL_ESTATE_CREATE_OBJEKT_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/ri/real-estate/objekt',
            method: 'post',
            body: { objectId, data },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_CREATE_OBJEKT_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_real_estate_change_objekt = (objectId: string, data: any, publishedHomepage: boolean, dataTwo?: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_REAL_ESTATE_UPDATE_OBJEKT_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/ri/real-estate/objekt',
            method: 'put',
            body: { objectId, data, publishedHomepage: publishedHomepage ? publishedHomepage : false, dataTwo },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_UPDATE_OBJEKT_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_real_estate_delete_objekt = (objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/objekt/${objectId}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_DELETE_OBJEKT_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_real_estate_upload_picture = (pictures: any, objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        pictures.map((pictureTmp: any) => {
            // console.log(picture);
            // picture.name = `${objectId}-${picture.name}`;
            const picture = new File([pictureTmp], `${objectId}-${pictureTmp.name}`, {
                type: pictureTmp.type,
                lastModified: pictureTmp.lastModified,
            });
            const { doRequest } = useRequest({
                url: `/api/ri/real-estate/objekt/file-upload`,
                method: 'post',
                body: {
                    objectId,
                },
                file: picture,
                onSuccess: (data) => {
                    dispatch({
                        type: ActionType.RI_REAL_ESTATE_UPLOAD_PICTURE_SUCCESS,
                        payload: data,
                    });
                    dispatch(actionCreators.benit_central_ui_delete_all_errors());
                },
                onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
            });

            doRequest();
            return null;
        });
    };
};

export const ri_real_estate_delete_picture = (pictureName: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/objekt/delete-file/${pictureName}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_DELETE_PICTURE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
        return null;
    };
};

export const ri_real_estate_get_texts = (data: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.RI_REAL_ESTATE_GET_TEXTS_PENDING,
        });

        const { doRequest } = useRequest({
            url: '/api/ri/real-estate/get-texts',
            method: 'get',
            body: { data },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_GET_TEXTS_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_real_estate_get_expose = (dataTmp: any, templateFileName?: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/expose/`,
            method: 'post',
            body: { data: dataTmp, templateFileName },
            onSuccess: (data) => {
                // const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                // const fileURL = URL.createObjectURL(file);

                var element = document.createElement('a');
                // element.setAttribute('href', fileURL);
                element.setAttribute('href', `/api/ri/real-estate/objekt/exposes/files/${data.fileName}`);
                element.setAttribute('download', `${data.fileName}`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_real_estate_get_pictures = (objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/expose/pictures/${objectId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                // const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                // const fileURL = URL.createObjectURL(file);

                console.log(data);

                var element = document.createElement('a');
                // element.setAttribute('href', fileURL);
                element.setAttribute('href', `/api/ri/real-estate/objekt/exposes/files/${data.fileName}`);
                element.setAttribute('download', `${data.fileName}`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_real_estate_get_stakeholder = (objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder?objectId=${objectId}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_STAKEHOLDER_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_create_stakeholder = (stakeholder: any, objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { requestReceivedAt, title, firstName, lastName, email, telephone, requestText, city, street } = stakeholder;
        const data = { requestReceivedAt, title, firstName, lastName, requestText, city, street, telephone };
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder`,
            method: 'post',
            body: { objectId, email, data },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_STAKEHOLDER_CREATE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_update_stakeholder = (id: string, status: string, data?: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder`,
            method: 'put',
            body: { id, status, data },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_STAKEHOLDER_UPDATE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_delete_stakeholder = (id: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder/${id}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_STAKEHOLDER_DELETE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_delete_all_stakeholder = (objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder/all/${objectId}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_STAKEHOLDER_DELETE_ALL_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_document_stakeholder = (stakeholder: any, objectId: string, type: string, dataTmp: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder/document`,
            method: 'post',
            body: { objectId, stakeholder, type, dataTmp },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_REAL_ESTATE_STAKEHOLDER_DOCUMENT_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_real_estate_get_stakeholder_list = (objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder-list/`,
            method: 'post',
            body: { objectId },
            onSuccess: (data) => {
                // const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                // const fileURL = URL.createObjectURL(file);

                var element = document.createElement('a');
                // element.setAttribute('href', fileURL);
                element.setAttribute('href', `/api/ri/real-estate/objekt/exposes/files/${data.fileName}`);
                element.setAttribute('download', `${data.fileName}`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};

export const ri_real_estate_get_stakeholder_confirmation_document = (stakeholderId: string, objectId: string, type: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/ri/real-estate/stakeholder/confirmation`,
            method: 'post',
            body: { stakeholderId, objectId, type },
            onSuccess: (data) => {
                // const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                // const fileURL = URL.createObjectURL(file);

                var element = document.createElement('a');
                // element.setAttribute('href', fileURL);
                element.setAttribute('href', `/api/ri/real-estate/objekt/exposes/files/${data.fileName}`);
                element.setAttribute('download', `${data.fileName}`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            },
            onError: (error) => {
                dispatch(actionCreators.benit_central_ui_add_errors(error));
            },
        });

        doRequest();
    };
};
