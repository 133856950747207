import { data } from 'jquery';
import { ActionType } from '../action-types';
import { Action } from '../actions';

interface BenitCareState {
    assistantPending: boolean;
    assistantHistory: any[];
    pdfPending: boolean;
    pdfInternalUrl: string | null;
    transcriptionPending: boolean;
    transcribedText: string | null;
}

const initialState = {
    assistantPending: false,
    assistantHistory: [{ role: 'assistant', text: 'Hallo, ich bin dein Assistent in der Pflege. Bitte mich um etwas, ich setze es um.' }],
    pdfPending: false,
    pdfInternalUrl: null,
    transcriptionPending: false,
    transcribedText: null,
};

const reducer = (state: BenitCareState = initialState, action: Action): BenitCareState => {
    switch (action.type) {
        case ActionType.BENIT_CARE_ASSISTANT_ASK_ASSISTANT_PENDING:
            return {
                ...state,
                assistantPending: true,
                assistantHistory: [{ role: 'user', text: action.payload.prompt ?? '' }],
            };
        case ActionType.BENIT_CARE_ASSISTANT_ASK_ASSISTANT_SUCCESS:
            return {
                ...state,
                assistantPending: false,
                assistantHistory: [
                    {
                        answer: action.payload?.answer ? action.payload?.answer : '',
                    },
                ],
            };
        case ActionType.BENIT_CARE_ASSISTANT_ASK_ASSISTANT_FAIL:
            return {
                ...state,
                assistantPending: false,
            };
        case ActionType.BENIT_CARE_ASSISTANT_NEW_REPORT_PDF_PENDING:
            return {
                ...state,
                pdfPending: true,
            };
        case ActionType.BENIT_CARE_ASSISTANT_NEW_REPORT_PDF_AVAILABLE:
            return {
                ...state,
                pdfPending: false,
                pdfInternalUrl: action.payload.fileURL ?? null,
            };
        case ActionType.BENIT_CARE_ASSISTANT_UPDATE_ANSWER:
            return {
                ...state,
                assistantHistory: [
                    {
                        role: 'assistant',
                        type: action.payload?.answer?.type ? action.payload.answer.type : '',
                        text: action.payload?.answer?.type === 'report' && action.payload?.answer?.data?.text ? action.payload.answer.data.text : '',
                        data: action.payload?.answer?.data ? action.payload.answer.data : null,
                    },
                ],
            };
        case ActionType.BENIT_CARE_ASSISTANT_TRANSCRIPTION_PENDING:
            return {
                ...state,
                transcriptionPending: true,
                transcribedText: null,
            };
        case ActionType.BENIT_CARE_ASSISTANT_TRANSCRIPTION_SUCCESS:
            return {
                ...state,
                transcriptionPending: false,
                transcribedText: action.payload.transcribedText ?? null,
            };
        default:
            return state;
    }
};

export default reducer;
