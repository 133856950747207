import { useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import Select from 'react-select';
import socketClient from 'socket.io-client';
import { useActions } from '../../../../../../hooks/useActions';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import ChatMessage from './ChatMessage';

interface IProps {
    eigentuemer: string;
    userIsAdmin: boolean;
}

type OptionType = {
    value: string;
    label: string;
};

const ChatWrapper = ({ eigentuemer, userIsAdmin }: IProps) => {
    const [messageText, setMessageText] = useState('');
    const [showSendEmail, setShowSendEmail] = useState(false);
    const [users, setUsers] = useState<OptionType[] | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedUsersOptions, setSelectedUsersOptions] = useState<OptionType[]>([]);

    const { email } = useTypedSelector((state) => state.benitAuthReducer);
    const { wegChatMessages, wegChatSocket, wegChatUserEigentuemer } = useTypedSelector((state) => state.riPropertyManagementReducer);

    const { ri_property_management_get_weg_chat, ri_property_management_weg_chat_send_message, ri_property_management_weg_chat_delete_messages, ri_property_management_weg_chat_get_user_eigentuemer } =
        useActions();

    const divChatBottom = useRef<null | HTMLDivElement>(null);
    const userSelect = useRef<null | any>(null);

    useEffect(() => {
        if (eigentuemer !== '0') {
            ri_property_management_get_weg_chat(eigentuemer);
            ri_property_management_weg_chat_get_user_eigentuemer(eigentuemer);
        }
    }, [eigentuemer]);

    useEffect(() => {
        if (wegChatSocket) {
            wegChatSocket.on('connect', () => {
                wegChatSocket.emit('eigentuemer_join', { eigentuemer });
            });

            wegChatSocket.on('connect_error', (err: any) => {
                console.log('connect failed' + err);
            });

            wegChatSocket.on('message', (message: any) => {
                ri_property_management_weg_chat_send_message(message);
            });
        }
    }, [wegChatSocket]);

    useEffect(() => {
        if (divChatBottom.current) {
            divChatBottom.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [wegChatMessages]);

    useEffect(() => {
        const usersTmp: OptionType[] = wegChatUserEigentuemer
            ? wegChatUserEigentuemer.map((userEigentuemerTmp) => {
                  return {
                      value: `${userEigentuemerTmp.user.id}`,
                      label: `${userEigentuemerTmp.user.firstName} ${userEigentuemerTmp.user.lastName} (${userEigentuemerTmp.user.email})`,
                  };
              })
            : [{ value: '', label: '' }];
        setUsers(usersTmp);
    }, [wegChatUserEigentuemer]);

    const sendMessage = (messageText: string) => {
        let tmpSelectedUsers = selectedUsersOptions.map((selectedUser: any) => selectedUser.value);
        if (!tmpSelectedUsers) {
            tmpSelectedUsers = [];
        }
        wegChatSocket.emit('send-message', { text: messageText, id: wegChatSocket.id, eigentuemer, emailUserIDs: tmpSelectedUsers });
        setMessageText('');
        setSelectedUsersOptions([]);
    };

    const inputChange = (input: string) => {
        setMessageText(input);

        if (userSelect.current && wegChatUserEigentuemer) {
            const inputSplit = input.split(' ');
            for (let i = 0; i < inputSplit.length; i++) {
                const inputWord = inputSplit[i];
                const lastInputWord = i > 0 ? inputSplit[i - 1] : '';
                let foundUsers = wegChatUserEigentuemer.filter(({ user }) => inputWord === user.firstName || inputWord === user.lastName);

                if (foundUsers.length > 2) {
                    foundUsers = wegChatUserEigentuemer.filter(({ user }) => `${inputWord} ${lastInputWord}` === `${user.firstName} ${user.lastName}`);
                }
                if (foundUsers.length === 1) {
                    setShowSendEmail(true);
                    const tmpUsers = selectedUsersOptions ? selectedUsersOptions.map((user: any) => user.value) : [];
                    if (users && tmpUsers && !tmpUsers.includes(foundUsers[0].user.id)) {
                        setSelectedUsersOptions([
                            ...selectedUsersOptions,
                            { value: foundUsers[0].user.id, label: `${foundUsers[0].user.firstName} ${foundUsers[0].user.lastName}  (${foundUsers[0].user.email})` },
                        ]);
                    }
                }
            }
        }
    };

    const selectedUserChange = (selectedUsersTmp: any) => {
        setSelectedUsersOptions(selectedUsersTmp);
    };

    return (
        <div className="card direct-chat direct-chat-primary" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <div className="card-header">
                <h3 className="card-title">Chat</h3>

                <div className="card-tools">
                    {userIsAdmin ? (
                        <button type="button" className="btn btn-tool" onClick={(e) => ri_property_management_weg_chat_delete_messages(eigentuemer)}>
                            <i className="fas fa-trash"></i>
                        </button>
                    ) : null}
                </div>
            </div>
            <div className="card-body" style={{ height: '300px' }}>
                <div className="direct-chat-messages" style={{ height: 'auto' }}>
                    {wegChatMessages
                        ? wegChatMessages.map((message) => {
                              return <ChatMessage author={`${message.user.firstName} ${message.user.lastName}`} date={message.datum} text={message.text} ownMessage={message.user.email === email} />;
                          })
                        : null}
                    <div ref={divChatBottom} />
                </div>
            </div>
            <div className="card-footer">
                <div className={`form-group mb-3 zindex-sticky ${!showSendEmail ? 'd-none' : ''}`}>
                    <label>E-Mail Benachrichtigung an</label>
                    <Select
                        placeholder="Noch keine Nutzer ausgewählt"
                        noOptionsMessage={({ inputValue }) => 'Alle Benutzer ausgewählt / Benutzer nicht gefunden'}
                        isMulti
                        name="permissions"
                        options={users ? users : []}
                        value={selectedUsersOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={selectedUserChange}
                        ref={userSelect}
                    />
                </div>
                <div className="input-group">
                    <div className="input-group-prepend" style={{ zIndex: 0 }}>
                        <button type="button" className={`btn ${showSendEmail ? 'btn-primary' : 'btn-default'}`} onClick={(e) => setShowSendEmail(!showSendEmail)}>
                            <i className="fas fa-bullhorn"></i>
                        </button>
                    </div>
                    <input
                        type="text"
                        name="message"
                        autoComplete="off"
                        placeholder="Type Message ..."
                        value={messageText}
                        onChange={(e) => inputChange(e.target.value)}
                        onKeyUp={(e) => (e.key === 'Enter' ? sendMessage(messageText) : null)}
                        className="form-control"
                    />
                    <span className="input-group-append" style={{ zIndex: 0 }}>
                        <button type="button" className="btn btn-primary" onClick={(e) => sendMessage(messageText)}>
                            Send
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ChatWrapper;
