import Avatar from 'react-avatar';
import styles from './LoginStyle.module.css';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import LanguagePicker from '../../../central-ui/components/Container/MainWindow/LanguagePicker/LanguagePicker';
import fireToast from '../../../central-ui/methods/fireToast';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';

const Login = () => {
    const emailInputField = useRef<any>(null);
    const [emailInput, setEmail] = useState('');
    const [passwordInput, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
    const { benit_auth_loginUser, benit_auth_logoutUser, benit_auth_chooseOrg, benit_auth_resetPassword } = useActions();
    const { languageKey } = useTypedSelector((state) => state.benitCentralConfigurationReducer);
    const { email, errors, loading, organizations, chosenOrganization, passwordResetSuccessfully } = useTypedSelector((state) => state.benitAuthReducer);

    const { t } = useTranslation(['benit-auth', 'general']);
    const { mlfv } = useMultiLanguageFieldValue();

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!forgotPasswordActive) {
            benit_auth_loginUser(emailInput, passwordInput);
        }
    };

    useEffect(() => {
        if (emailInputField && emailInputField.current) {
            emailInputField.current.focus();
        }
    }, []);

    useEffect(() => {
        if (passwordResetSuccessfully) {
            fireToast('success', t('benit-auth:password_reset_email_sent'));
            setForgotPasswordActive(false);
        }
    }, [passwordResetSuccessfully]);

    const host = window.location.host;

    return (
        <div className={styles.centerPosition}>
            <div className="login-box">
                <div className="card">
                    {!organizations || organizations.length === 1 ? (
                        <div>
                            <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ borderBottom: 'none' }}>
                                <ul className="navbar-nav ml-auto">
                                    <LanguagePicker />
                                </ul>
                            </nav>
                            <div className="card-body login-card-body">
                                <div>
                                    <img
                                        src={`${
                                            host == 'app.benit-solutions.com' || host == 'benit.dev' || host == 'benit.cloud'
                                                ? process.env.PUBLIC_URL + '/img/benit.png'
                                                : process.env.PUBLIC_URL + '/img/RI-Original.jpg'
                                        }`}
                                        className="mb-4"
                                        alt="benit"
                                        width="40%"
                                    />
                                    {errors
                                        ? errors.map((error: any) => {
                                              if (error.description) {
                                                  return (
                                                      <div className="alert alert-danger " key={error.message}>
                                                          {mlfv(error.description)}
                                                      </div>
                                                  );
                                              } else {
                                                  return (
                                                      <div className="alert alert-danger " key={error.message}>
                                                          {error.message}
                                                      </div>
                                                  );
                                              }
                                          })
                                        : null}
                                    <form onSubmit={onSubmit}>
                                        <div className="input-group mb-3">
                                            <input
                                                ref={emailInputField}
                                                type="email"
                                                className="form-control"
                                                placeholder={t('benit-auth:email')}
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={emailInput}
                                                autoFocus={false}
                                            />
                                            <div className="input-group-append">
                                                <div className="input-group-text"></div>
                                            </div>
                                        </div>
                                        {!forgotPasswordActive ? (
                                            <div>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type={`${showPassword ? 'text' : 'password'}`}
                                                        className="form-control"
                                                        placeholder={t('benit-auth:password')}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        value={passwordInput}
                                                    />
                                                    <div onClick={(e) => setShowPassword(!showPassword)} className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button type="submit" className="btn btn-primary btn-block">
                                                            {t('benit-auth:login')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <button onClick={(e) => benit_auth_resetPassword(emailInput, document.documentElement.lang)} className="btn btn-primary btn-block">
                                                        {t('benit-auth:send_password_reset_email')}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </form>

                                    {!forgotPasswordActive ? (
                                        <p className="mb-1 mt-3">
                                            <a onClick={(e) => setForgotPasswordActive(true)} style={{ cursor: 'pointer' }}>
                                                {t('benit-auth:forgotpassword')}
                                            </a>
                                        </p>
                                    ) : (
                                        <p className="mb-1 mt-3">
                                            <a onClick={(e) => setForgotPasswordActive(false)} style={{ cursor: 'pointer' }}>
                                                {t('general:back')}
                                            </a>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ borderBottom: 'none' }}>
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a onClick={benit_auth_logoutUser} className="nav-link" style={{ cursor: 'pointer' }}>
                                            <i className="nav-icon fas fa-sign-out-alt"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="card-body login-card-body">
                                {organizations.map((organization) => {
                                    return (
                                        <button className="btn btn-block btn-default" onClick={(e) => benit_auth_chooseOrg(organization.id)}>
                                            <div className="row">
                                                <div className="col-1">
                                                    <Avatar name={organization.name} size="30" round=".3rem" color="green" />
                                                </div>
                                                <div className="col-10 offset-1 align-middle mt-1 font-weight-bold text-left">{organization.name}</div>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Login;
