import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import fireToast from '../../../../benit/central-ui/methods/fireToast';

const DataRefreshApp = () => {
    const { t } = useTranslation([
        'general',
        'benit-central-configuration',
        'ri-property-management',
    ]);

    const { ri_property_management_data_refresh } = useActions();

    const { refreshDataSuccess } = useTypedSelector(
        (state) => state.riPropertyManagementReducer
    );

    useEffect(() => {
        if (refreshDataSuccess) {
            fireToast(
                'success',
                `${t('ri-property-management:data_refresh_trigger_success')}`
            );
        }
    }, [refreshDataSuccess]);

    return (
        <div>
            <div className="row col-md-6 offset-md-3 mt-2">
                <button
                    className="btn btn-primary btn-block"
                    onClick={(e) => ri_property_management_data_refresh()}
                >
                    <b>{t('ri-property-management:data_refresh')}</b>
                </button>
            </div>
        </div>
    );
};

export default DataRefreshApp;
