import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Dna } from 'react-loader-spinner';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import styles from './AssistantApp.module.css';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';

import ChatInput from './components/ChatInput';
import ResultOutput from './components/ResultOutput';
import VitalsCard from './components/VitalsCard';
import JSONPretty from 'react-json-pretty';
import SISCategory from './components/SISCategory';
import RiskMatrix from './components/RiskMatrix';

interface IDocumentLoadSuccessPayload {
    numPages: any;
}

interface Message {
    id: string;
    text: string;
}

const initialMessages: Message[] = [
    {
        id: '1',
        text: 'Hi there! Welcome to the chat app.',
    },
];

const ChatInputContainer = styled.div`
    width: 100%;
    padding: 16px;
    margin: 0 20px 0 0;
    background-color: #f4f6f9
    align-items: center;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
    align-items: center;
    justify-content: center;
`;

const FormContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`;

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 92vh;
    background-color: #FFF;
`;

const ChatMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 18px;
    animation: fadein 0.5s ease;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const ChatMessageText = styled.div`
    margin: 0;
    color: black;
    align-items: center;
    display: inline-block;
    margin-left: 8px;
`;

const ChatIcon = styled.div`
    display: inline-block;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: black;
    background-color: white;
    border: none;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    margin-left: 8px;
    padding-top: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const PDFContainer = styled.div`
    display: block;
    background-color: white;
    border: none;
    border-radius: 4px;
    margin-left: 8px;
    padding-top: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const DownloadButtonContainer = styled.div`
    display: block;
    width: 100%;
`;

const DownloadButton = styled.button`
    display: block;
    display: inline-block;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: white;
    border: none;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    }
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
`;

interface ToggleButtonProps {
    isActive: boolean;
    onClick: Function;
}

const ToggleButton = styled.button<ToggleButtonProps>`
    border: none;
    background: transparent;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    color: ${(props) => (props.isActive ? '#000' : '#888')};
    font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
    box-shadow: ${(props) => (props.isActive ? '0 0 5px rgba(0, 0, 0, 0.3)' : 'none')};
    padding: 10px 15px;
    border-radius: 4px;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
`;

const SISAssistantApp: React.FC = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + `/js/pdfworker/${pdfjs.version}/pdf.worker.min.js`;
    const [messages, setMessages] = useState<Message[]>(initialMessages);
    const [numPages, setNumPages] = useState(null);
    const [editModeOn, setEditModeOn] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [model, setModel] = useState('gpt-4');

    const { benit_care_ask_assistant, benit_care_assistant_get_report_document, benit_care_assistant_update_answer } = useActions();

    const { assistantPending, assistantHistory, pdfPending, pdfInternalUrl } = useTypedSelector((state) => state.benitCareReducer);

    // useEffect(() => {
    //     if (messagesEndRef.current) {
    //         messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, [assistantHistory]);

    const documentViewer = (url: string | null, loadSuccessCallback: any, numPagesInput: any) => {
        return url ? (
            <SizeMe>
                {({ size }) => (
                    <PDFContainer className="bg-white">
                        <div className="row pt-4 pb-3">
                            <DownloadButtonContainer className="text-center">
                                <DownloadButton color="#0080ff" onClick={(e) => setEditModeOn(true)}>
                                    Bearbeiten
                                </DownloadButton>
                                <DownloadButton color="#0080ff" onClick={(e) => benit_care_assistant_get_report_document({ answer: assistantHistory[assistantHistory.length - 1].text }, true)}>
                                    Herunterladen
                                </DownloadButton>
                            </DownloadButtonContainer>
                        </div>
                        <Document
                            file={url}
                            onLoadSuccess={loadSuccessCallback}
                            loading={
                                <div>
                                    <div className={styles.loader}></div>
                                </div>
                            }
                        >
                            {Array.from(new Array(numPagesInput), (el, index) => (
                                <>
                                    <Page width={size.width ? size.width - 10 : 0} key={`page_${index + 1}`} pageNumber={index + 1} />
                                    {numPagesInput && index < numPagesInput - 1 ? <hr /> : null}
                                </>
                            ))}
                            {/* <Page width={size.width} pageNumber={pageNumber} /> */}
                            {/* <p>
                                Page {pageNumber} of {numPagesInput}
                            </p> */}
                        </Document>
                    </PDFContainer>
                )}
            </SizeMe>
        ) : null;
    };

    const onDocumentLoadSuccess = ({ numPages }: IDocumentLoadSuccessPayload) => {
        setNumPages(numPages);
    };

    const updateVitalValues = (value: string, property: string) => {
        let updatedAnswer = { ...assistantHistory[assistantHistory.length - 1] };
        if (!updatedAnswer.data) {
            updatedAnswer.data = {};
        }
        if (!updatedAnswer.data[property]) {
            updatedAnswer.data[property] = '';
        }
        updatedAnswer.data[property] = value;
        benit_care_assistant_update_answer(updatedAnswer);
    };

    var riskMatrix = assistantHistory.length >= 1 && assistantHistory[0].answer ? assistantHistory[0].answer : {};

    return (
        <div className="ml-n3 mr-n3 mt-n3">
            <ChatContainer>
                <FormContainer>
                    {/* <JSONPretty id="json-pretty" data={assistantHistory.length >= 1 ? assistantHistory[0].answer ?? '' : ''}></JSONPretty> */}
                    {/* <div className="mt-3 d-flex justify-content-center">
                        <ToggleContainer>
                            <ToggleButton isActive={model === 'gpt-3.5-turbo'} onClick={() => setModel('gpt-3.5-turbo')}>
                                Gut/Schnell
                            </ToggleButton>
                            <ToggleButton isActive={model === 'gpt-4'} onClick={() => setModel('gpt-4')}>
                                Besser/Langsam
                            </ToggleButton> 
                        </ToggleContainer>
                    </div> */}
                    <div className="mt-3 ml-3 mt-2 mr-3">
                        <SISCategory
                            topic="topic0"
                            text={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic0 ? assistantHistory[0].answer.topic0.text : ''}
                            riskMatrixData={{}}
                        />
                    </div>
                    <div className="ml-3 mt-4 mr-3">
                        <SISCategory
                            topic="topic1"
                            text={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic1 ? assistantHistory[0].answer.topic1.text : ''}
                            riskMatrixData={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic1 ? assistantHistory[0].answer.topic1.risks : {}}
                        />
                    </div>
                    <div className="ml-3 mt-4 mr-3">
                        <SISCategory
                            topic="topic2"
                            text={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic2 ? assistantHistory[0].answer.topic2.text : ''}
                            riskMatrixData={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic2 ? assistantHistory[0].answer.topic2.risks : {}}
                        />
                    </div>
                    <div className="ml-3 mt-4 mr-3">
                        <SISCategory
                            topic="topic3"
                            text={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic3 ? assistantHistory[0].answer.topic3.text : ''}
                            riskMatrixData={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic3 ? assistantHistory[0].answer.topic3.risks : {}}
                        />
                    </div>
                    <div className="ml-3 mt-4 mr-3">
                        <SISCategory
                            topic="topic4"
                            text={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic4 ? assistantHistory[0].answer.topic4.text : ''}
                            riskMatrixData={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic4 ? assistantHistory[0].answer.topic4.risks : {}}
                        />
                    </div>
                    <div className="ml-3 mt-4 mr-3">
                        <SISCategory
                            topic="topic5"
                            text={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic5 ? assistantHistory[0].answer.topic5.text : ''}
                            riskMatrixData={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic5 ? assistantHistory[0].answer.topic5.risks : {}}
                        />
                    </div>
                    <div className="ml-3 mt-4 mr-3 mb-4">
                        <SISCategory
                            topic="topic6"
                            text={assistantHistory.length >= 1 && assistantHistory[0].answer && assistantHistory[0].answer.topic6 ? assistantHistory[0].answer.topic6.text : ''}
                            riskMatrixData={{}}
                        />
                    </div>
                    <div className="ml-3 mt-4 mr-3 mb-4">
                        <RiskMatrix data={riskMatrix} />
                    </div>
                </FormContainer>
                <ChatInputContainer>
                    <div className="row mb-2 mt-2">
                        <div className="col-lg-10 offset-lg-1 col-md-12 pl-4 pr-4">
                            {assistantPending ? (
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <Dna visible={true} height="170" width="300" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <ChatInput onSendMessage={(message) => benit_care_ask_assistant(message, model)} />
                            )}
                        </div>
                    </div>
                </ChatInputContainer>
            </ChatContainer>
            {assistantHistory && assistantHistory.length > 0 && assistantHistory[0].role === 'assistant' && (assistantHistory[0].type === 'report' || assistantHistory[0].type === 'sis') ? (
                editModeOn ? (
                    <div className="row mb-3">
                        <div className="col-lg-10 offset-lg-1 col-md-12 pl-4 pr-4 mt-4">
                            <ResultOutput
                                resultText={assistantHistory[assistantHistory.length - 1].text}
                                onSendMessage={(message: any) => {
                                    let updatedAnswer = { ...assistantHistory[assistantHistory.length - 1] };
                                    if (!updatedAnswer.data) {
                                        updatedAnswer.data = {};
                                    }
                                    if (!updatedAnswer.data.text) {
                                        updatedAnswer.data.text = '';
                                    }
                                    updatedAnswer.data.text = message;
                                    benit_care_assistant_update_answer(updatedAnswer);
                                    benit_care_assistant_get_report_document({ answer: message }, false);
                                    setEditModeOn(false);
                                }}
                            />
                        </div>
                    </div>
                ) : pdfInternalUrl ? (
                    <div className="row mb-3">
                        <div className="col-lg-10 offset-lg-1 col-md-12 pl-3 pr-4">
                            {/* <div className="text-center">{documentViewer('https://benit.dev/dummy.pdf', onDocumentLoadSuccess, numPages)}</div> */}
                            <div className="text-center">{documentViewer(pdfInternalUrl, onDocumentLoadSuccess, numPages)}</div>
                        </div>
                    </div>
                ) : null
            ) : null}
            <div ref={messagesEndRef} />
        </div>
    );
};

export default SISAssistantApp;
