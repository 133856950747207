import axios from 'axios';
import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';
import { useActions } from '../../../../../hooks/useActions';
import { actionCreators } from '../../../../../state';

export const benit_central_configuration_get_services = (searchTerm = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_CONFIGURATION_SERVICES_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `/api/benit/central-configuration/service?searchTerm=${searchTerm}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_SERVICES_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) =>
                dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_central_configuration_delete_service = (id: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `/api/benit/central-configuration/service/${id}`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.BENIT_CENTRAL_CONFIGURATION_SERVICES_DELETE_SUCCESS,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) =>
                dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
