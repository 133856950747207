import { getI18n, useTranslation } from 'react-i18next';

const useMultiLanguageFieldValue = () => {
    const { t } = useTranslation(['general']);
    const mlfv = (field: { [x: string]: string }) => {
        if (field) {
            let fieldValue: string | null = '';
            if (field[getI18n().language]) {
                fieldValue = field[getI18n().language];
            } else {
                fieldValue = t('general:field_not_maintained');
            }
            return fieldValue;
        } else {
            return '';
        }
    };

    return { mlfv };
};

export default useMultiLanguageFieldValue;
